html {
  font-size: 16px;
}

@media (max-width: 576px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 576px) and (max-width: 1199px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: Proxima Nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  //font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-family: Proxima Nova, sans-serif;
}


//.ant-btn-primary {
//  &:not(:disabled):hover {
//    background-color: #582859 !important;
//    color: #dd4472 !important;
//    border-color: #dd4472 !important;
//  }
//
//  &:focus {
//    background-color: #582859 !important;
//    color: #dd4472 !important;
//    border-color: #dd4472 !important;
//  }
//}
//
//.ant-btn-default {
//  &:not(:disabled):hover {
//    /*background-color: #582859 !important;*/
//    /*color: #dd4472 !important;*/
//    background: #A06174 !important;
//    color: #FFF !important;
//    border-color: #dd4472 !important;
//  }
//
//  &:focus {
//    background: #A06174 !important;
//    color: #FFF !important;
//    border-color: #dd4472 !important;
//  }
//}


@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 400;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Regular Italic.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 400;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Regular.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 250;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Thin Italic.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 250;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Thin.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 300;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Light.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: italic;
  font-weight: 300;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Light Italic.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 600;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Semibold Italic.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 600;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Semibold.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 700;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Bold Italic.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 700;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Bold.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 800;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Extrabold Italic.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 800;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Extrabold.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 900;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Black Italic.woff') format('woff')
}

@font-face {
  font-family: proxima nova;
  font-style: normal;
  font-weight: 900;
  src: local('Proxima Nova'), url('https://fonts.cdnfonts.com/s/27118/Mark Simonson  Proxima Nova Black.woff') format('woff')
}