.h-gp-btn {
  width: 8vw;
  height: 5vh;
  flex-shrink: 0;
  border-radius: 6px;
  background: #0E487A;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gp-ml-new-album-modal {
  .ant-modal-content {
    width: 30vw;
    height: 40vh;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Yellow, #FFF);
    margin: 25% 0 0 0%
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ml-new-album-modal-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px;
  }

  .ml-new-album-modal-txt {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 653px;
    flex-shrink: 0;
    text-align: center;
    margin: auto;
  }

  .ml-new-album-modal-sub-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-shrink: 0;
    margin: 2rem 0 0 0;
  }

  .ml-new-album-modal-input {
    width: 604px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 9px;
    background: #E2E2E2;
    margin: 1rem 0 0 12%;
  }

  .ml-new-album-modal-btn {
    border-radius: 6px;
    background: #0E487A;
    width: 260px;
    height: 56px;
    flex-shrink: 0;
    margin: 2rem 0 0 35%;
    color: var(--Yellow, #FFF);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 16px; // Adjust spacing as needed
    }
  }
}