.withdraw-btn{
  .ant-btn {
    border: 1px solid #105B63;
    width: 60%;
    border-radius: 8.69px;
    margin-top: 1rem;
  }

  .ant-btn-primary {
    background-color: #105B63;
  }
}