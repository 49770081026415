.ProChangePassword {
  margin-top: 2rem;
  height: 51rem;
  margin-left: 5rem;
}
.ProChangePassword-title {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PortalchangePassword {
  width: 1476px;
  height: 705px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid var(--Yellow, #FFF);
  background: var(--Yellow, #FFF);
}
.ProportalPasswordTitle {
  width: 25vw;
  margin-top: 100px;
  margin-left: 30rem;
}
.ChangePasswordSubmit {
  margin-left: 8vw;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
}
.custom-card .ant-card-head {
  background: var(--Gray-1, #F5F5F5);
}

// If you want to change the text color as well, you can target the title specifically
.custom-card .ant-card-head-title {
  color: var(--Black, #000);
}
