.hard-img-div {
  //width: 341px;
  //height: 538px;
  width: 16.76vw;
  height: 49.81vh;
  flex-shrink: 0;
  border: 0px solid #D0D0D0;
  margin: 0 0 0 6rem;
}

@media only screen and (max-width: 1200px) {
  .hard-img-div {
    display: none !important;
  }
}

.hard-cur-sch {
  width: 162px;
  height: 22px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1rem 0 0 -1.6rem;
}

.hard-st-time-txt {
  width: 113px;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

.hard-end-time-txt {
  width: 113px;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  margin-left: 6rem;
}

.hard-week-btn {
  width: 7vw;
  min-width: 5vw;
  height: 51px;
  flex-shrink: 0;
  border-radius: 14px;
  background: #D9D9D9;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hard-btn {
  //width: 121px;
  width: 6vw;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.schedule-hard-btn {
  //width: 121px;
  width: 6.8vw;
  height: 34px;
  border-radius: 5px;
  border: 1px #0E487A solid;
  background: #FFF;
  color: #0E487A;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:1vh;
}

.hard-img {
  width: 14.76vw;
  //height: 49.81vh;
  margin: 0 0 0 -1vw;
}

.hard-title {
  display: flex;
  width: 554px;
  height: 94px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 0 6rem;
}

.hard-week-above-row {
  margin: 0rem 0 0rem 6rem;
}

.hard-week-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 2rem 0 4rem 4rem;
  width: 90%;

  .ant-picker {
    width: 182px;
    height: 51px;
    flex-shrink: 0;
    border-radius: 14px;
    border: 2px solid #727272;
    background: #FFF;
  }
}

.hard-week-final-btn-mul-s {
  width: 200px;
  height: 46.524px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #0E487A;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -20px;
}

.hard-final-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 0 0 40rem 0;
}

.hard-week-final-btn {
  width: 121px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.hard-row-p-txt {
  text-align: left;
  color: var(--Grey-01, #AAA);
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.68vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 0 0;
}


.hard-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin: 1rem 0 1rem -30rem;

  .hard-group-btn {
    display: flex;
    width: 16.8vw;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    flex-direction: column;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #BBB;
    background: var(--White, #0E487A);
    color: white;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .hard-input-with-save {
    width: auto;
  }

  .ant-input-group {
    width: 16.8vw;
  }

  .ant-select .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 2px 16px;
    border-radius: 6px;
    border: 2px solid #BBB;
    background: var(- -White, #FFF);
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .inside-input-btn {
    width: 4vw;
    height: 3.4vh;
    flex-shrink: 0;
    border-radius: 2px;
    background: var(--V-Pro-Blue, #0E487A);
    color: white;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:disabled {
      background-color: #d9d9d9 !important; /* Grey background for disabled state */
      color: rgba(0, 0, 0, 0.25) !important;
      cursor: not-allowed;
      pointer-events: none; /* Disable hover effect */
    }
  }

  .ant-input-affix-wrapper {
    display: flex;
    width: 16.8vw;
    height: 40px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #BBB;
    background: var(- -White, #FFF);

    .ant-input {
      width: 10.8vw;
      height: 3.4vh;
      border: none;
      background: var(- -White, #FFF);
      color: #000;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: auto;
    }
  }

  .ant-input {
    display: flex;
    //width: 322.384px;
    width: 16.8vw;
    min-width: 4vw;
    height: 40px;
    padding: 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #BBB;
    background: var(- -White, #FFF);
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (max-width: 1550px) {
    .ant-select .ant-select-selector {
      font-size: 1vw;
    }
    .hard-group-btn, .ant-input-group, .ant-input {
      width: 11.8vw;
      font-size: 1vw;
    }
  }
  @media only screen and (max-width: 1350px) {
    .hard-group-btn, .ant-input-group, .ant-input {
      width: 10.8vw;
    }
  }
  @media only screen and (max-width: 1250px) {
    .hard-group-btn, .ant-input-group, .ant-input {
      width: 7.8vw;
      font-size: 0.8vw;
    }
    .ant-select .ant-select-selector {
      font-size: 0.8vw;
    }
  }
}

.hd-settings-txt {
  width: 142px;
  height: 19px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hd-settings-ip {
  .ant-input-number {
    display: flex;
    width: 18vw;
    height: 40px;
    margin-top: 10px;
    justify-content: center;
    padding: 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #BBB;
    background: var(--White, #FFF);
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.hd-control-btn {
  width: 3.2vw;
  height: 3.2vw;
  flex-shrink: 0;
  fill: #0E487A;
  background: #0E487A;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.zh-ml-schedule-modal-h {
  width: 16vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: auto;
}

.zh-ml-schedule-modal-p {
  width: 16vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: auto;
}