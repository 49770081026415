.thumbnails {
    flex-direction: column;
    width: 10em;
    row-gap: 2em;
}

.thumbnail {
    width: 4rem !important;
    height: 4rem;
    border-radius: 0.625em;
}

.carousel-container {
    background-color: #f4f4f4 !important;
}

.active-thumbnail {
    border: black solid 2px !important
}

.carousel-image {
    width: 40em;
}

.box {
    display: flex;
    flex-direction: row-reverse;
}

.prev {
    display: initial;
    color: black;
}

.next {
    display: initial;
    color: black;
}

@media only screen and (max-width: 5920px){
    .thumbnails{
        flex-direction: row;
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 0rem;
    }
    .box{
        display: block;
    }
    .carousel-image{
        width: 100%;
        height: 110%;
        object-fit: contain!important;
    }

    .thumbnail{
        width: 4rem !important;
        height: 4rem;
        border-radius: 0.625em;
    }
}