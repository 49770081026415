.my-orders-pro {
  margin-left: 7rem;
  margin-top: 1rem;
}

//.my-orders-pro-Title {
//  color: #000;
//  font-family: Proxima Nova, sans-serif;
//  font-size: 24px;
//  font-style: normal;
//  font-weight: 600;
//  line-height: normal;
//  opacity: 0.75;
//  margin-top: 2rem;
//  margin-left: -2rem;
//}
.header1 {
  display: flex;
  align-items: center; /* 垂直对齐 */
}

.BackButtonIcon2 {
  margin-left: -7rem;
  cursor: pointer;
}

.my-orders-pro-Title {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.75;
  margin-top: 0; /* 取消顶部外边距 */
  margin-left: 0; /* 取消左侧外边距 */
}


.my-orders-pro-SubTitle {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.75;
  margin-top: 1.0rem;
  margin-left: 0.1rem;
}

.refunds-link {
  text-decoration: underline;
  color: var(--Vieunite-Pro-Blue, #0E477A);
}

.my-orders-pro-title {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.5;
  margin-top: 5rem;
  margin-left: -2rem;
}

.my-orders-table {
  max-width: 100%;
  height: 800px;
  margin-top: 1.5rem;
  margin-left: -1rem;
  //overflow-x: auto; // 允许水平滚动
}

.ant-table-wrapper {
  margin: 4rem auto; // 上下边距4rem，左右居中
  border: 2px solid #FFF; // 白色边框
  border-radius: 8px; // 圆角
  overflow: hidden; // 防止圆角外漏

  .ant-table {
    border-radius: 8px;
  }

  .ant-table-thead > tr > th {
    background-color: #F0F0F0; // 表头背景颜色
    color: #000; // 表头字体颜色
    font-weight: bold; // 字体加粗
    padding: 16px; // 表头内边距
  }

  .ant-table-tbody > tr > td {
    padding: 16px; // 单元格内边距
  }

  .ant-table-container {
    border-radius: 8px; // 圆角
  }
}

// 调整搜索和控制按钮区域的布局和样式
.bp-mo-ctrl-div {
  display: flex;
  justify-content: space-between; // 项目之间的间隔平均分布
  align-items: center;
  padding: 1rem; // 适当的内边距
  width: 100%; // 占满整个父容器的宽度
  margin-bottom: 2rem; // 与表格之间的空间
}

.bp-mo-search-btn, .bp-mo-ctrl-btn {
  margin: 0 0.5rem; // 按钮之间的间隔
  // 其他必要的样式...
}
.BackButtonIcon1 {
 margin-left: -1rem;
}


