.privacy-policy-container {
  margin: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-top: 120px;
}

h1, h2, h3 {
  color: #2c3e50;
}

ul, ol {
  margin-left: 20px;
}

ul li, ol li {
  margin-bottom: 10px;
}
.final-style {
  text-align: center;
  margin-top: 30px;
}