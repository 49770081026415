.m-sft-content-head{
  width: 60vw;
  height: 38px;
  flex-shrink: 0;
  color: var(--Vieunite-Pro-White, #FFF);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 7vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 4vh 0 0 0;
}
.m-sft-content-txt{
  width: 60vw;
  height: 126px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: -4vh 0 0 0;
}

.m-sfw-small-title{
  display: flex;
  width: 80vw;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Black, #000);
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 2vh auto;
}
.m-sfw-small-txt{
  width: 80vw;
  color: var(--Black, #000);
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 21px */
}
.m-sfw-img{

}

.m-sfw-head{
  width: 80vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 8vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.m-sfw-subhead{
  width: 50vw;
  text-align: center;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: -2vh auto;
}
.m-sfw-div-1{
  width: 80vw;
  height: 69px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #00C6DF;
  color: var(--Vieunite-Pro-White, #FFF);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-sfw-div-2{
  width: 80vw;
  height: 69px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #03A0E7;
  color: var(--Vieunite-Pro-White, #FFF);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-sfw-div-3{
  width: 80vw;
  height: 69px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #064D78;
  color: var(--Vieunite-Pro-White, #FFF);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-sfw-div-4{
  width: 80vw;
  height: 52px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #00C6DF;
  color: var(--Vieunite-Pro-White, #FFF);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;

}
.m-sfw-div-5{
  width: 80vw;
  height: 52px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #03A0E7;
  color: var(--Vieunite-Pro-White, #FFF);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-sfw-div-6{
  width: 80vw;
  height: 69px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #064D78;
  color: var(--Vieunite-Pro-White, #FFF);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sfw-head {
  width: 67.87156rem;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova bold, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sfw-subhead {
  color: #000;
  margin: -3rem 0 0 0;
  font-family: Proxima Nova, sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sft-btn-portal {
  width: 35.28181rem;
  height: 4.61919rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px solid #A06174;
  background: #A06174;
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova bold, sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sfw-small-title {
  width: 26vw;
  flex-shrink: 0;
  color: #000;
  text-align: left;
  font-family: Proxima Nova bold, sans-serif;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sfw-img-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
}

.sfw-small-txt {
  width: 26vw;
  color: var(--Black, #000);
  text-align: left;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sfw-circle-col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin: 0 0 18rem 0;
}

.backgroundImage {
  width: 80vw;
  height: 100vh;
  margin: 4rem 0 2rem 0;
  position: absolute;
  top: 0;
  left: 0;
  //z-index: 1;
}

.circle {
  position: absolute;
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  z-index: 1;
  background-color: #2f1717;
  color: white;
  font-family: Proxima Nova bold, sans-serif;
  font-weight: 900;
  white-space: normal; /* Allow text wrapping */
  line-height: 1.2; /* Adjust line height for better readability if text wraps */
  padding: 10px; /* Adjust padding as necessary */
  text-align: center; /* Ensure text is centered if it wraps */
  overflow-wrap: break-word; /* Break long words that can't be wrapped */
}

.circle:hover {
  //background-color: #888; /* Change to desired hover color */
  transform: scale(1.1);
  //color:black;
}

/* Position your circles around the image */
.art-library {
  top: 35%;
  left: 25%;
  background-color: #00CDE8;
}

.curated-collections {
  top: 60%;
  left: 22%;
  background-color: #03A9F5;
}

.image-video-upload {
  top: 90%;
  left: 26%;
  background-color: #065381;
}

.playlists {
  top: 35%;
  right: 30%;
  background-color: #00CDE8;
}

.manage-devices {
  top: 60%;
  right: 25%;
  background-color: #03A9F5;
}

.art-market {
  top: 90%;
  right: 32%;
  background-color: #065381;
}

.center-text {
  position: absolute;
  top: 73%;
  left: 48.5%;
  transform: translate(-50%, -50%);
  font-size: 0.7vw;
  //text-wrap: ;
  color: black;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: #D9D9D9;
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
  white-space: normal; /* Allow text wrapping */
  line-height: 142.523%; /* Adjust line height for better readability if text wraps */
  padding: 2.5rem; /* Adjust padding as necessary */
  display: flex; /* Use flexbox to align text */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  text-align: center; /* Ensure text is centered if it wraps */
  overflow-wrap: break-word; /* Break long words that can't be wrapped */
  font-family: Proxima Nova bold, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.circle:hover ~ .center-text {
  opacity: 1;
}
.art-library:hover,.playlists:hover{
  background: #44A3C1;
}
.curated-collections:hover,.manage-devices:hover{
  background: #0073A8;
}
.image-video-upload:hover,.art-market:hover{
  background: #04334E;
}


.image-container {
  position: relative;
  width: 100%; /* Use a percentage to be responsive */
}

.background-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any extra space below the image */
}
.m-sfw-content {
  position: absolute;
  top: 2.6%;
  left: 0;
  width: 100%;
  padding: 20px; /* Or any other value to position your text correctly */
  box-sizing: border-box; /* Include padding in width calculation */
  color: white; /* Assuming you want white text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sfw-content {
  position: absolute;
  top: 2.6%;
  left: 9%;
  width: 100%;
  padding: 20px; /* Or any other value to position your text correctly */
  box-sizing: border-box; /* Include padding in width calculation */
  color: white; /* Assuming you want white text */
}
.content-head{
  flex-shrink: 0;
  color: var(--Yellow, #FFF);
  font-family: Proxima Nova bold, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.content-txt{
  width: 20vw;
  height: 20vh;
  flex-shrink: 0;
  color: var(--Yellow, #FFF);
  font-family: Proxima Nova bold, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Add media queries to adjust the layout on smaller screens */
@media (max-width: 768px) {
  .sfw-content {
    padding: 10px; /* Less padding on smaller screens */
    font-size: smaller; /* Adjust font size if necessary */
  }
  .content-btn {
    padding: 5px 10px; /* Smaller padding */
    font-size: smaller; /* Adjust font size if necessary */
  }
}

/* Style your button as needed */
.content-btn {
  width: 24rem;
  height: 57.445px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0px solid #0E487A;
  background: #A06174;
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova bold, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}