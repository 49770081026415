.btn {
    position: absolute;
    /*top: 50%;*/
    /*left: 50%;*/
    transform: translate(-100%, -50%);
    width:43%;
    font-size: 1vw;
    font-weight: bold;
    color: white;
    /*background-color: #614ab1;*/
    background-color: #000000;
    border: 2px solid black;
    border-radius:5px;
    cursor: pointer;
    text-align: center;
    margin: .5rem 0 0 0;
    padding: 12px 36px;
    /*box-shadow: inset 0 0 0 0 #614ab1;*/
    box-shadow: inset 0 0 0 0 #000000;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
}
.btn > .in-btn-link{
    color: white;
}

.btn:hover {
    box-shadow: inset 400px 0 0 0 #ffffff;
    color: black;
}
.btn:hover .in-btn-link{
    color: black;
}

.title-over-video{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: end;
    transform: translate(160%, -180%);
    background-color: transparent;
    color: white;
    font-size: 120vw;
    padding: 12px 0px 12px 24px;
    border: none;
    border-radius: 15px;
    width: auto;
    text-align: right;
}
.a-over-v{
    font-family: Proxima Nova semibold, sans-serif;
    letter-spacing: 0.5px;
    font-size: 1vw;
}
.txt-over-v{
    font-family: Proxima Nova, sans-serif;
    font-size: 1.6vw;
    line-height: 2.5vw;
    width: 70%;
}
.frame-card{
 margin: 0 1rem;
}
@media only screen and (max-width: 900px) {
    .title-over-video{
        align-items: center;
        transform: translate(0%, -180%);
        font-size: 120vw;
        width: auto;
        text-align: center;
        padding: 12px;
    }
    .a-over-v{
        font-size: 4vw;
    }
    .txt-over-v{
        font-size: 6vw;
        line-height: 8vw;
        width: 80%;
    }
    .btn {
        transform: translate(-50%, -50%);
        width:50%;
    }
    .frame-card{
        margin: 0 1rem;
        padding: 3rem;
    }
    .btn{
        font-size: 18px;
        padding: 8px 24px;
    }
}

@media screen and (min-width: 600px){
    .img-text-zone{
        background-color: #e4e4e4;
        height: 100%;
        padding: 3rem;
    }
}

/*.ant-card-meta-title{*/
/*  font-size: 20px;*/
/*}*/
.first-row{
    margin: 5rem 0 4rem;
}