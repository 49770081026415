.ProLogo {
  width: 10vw;
  height: 8vh;
  flex-shrink: 0;
  margin-left: 22vw;
  margin-top: 10vh;
}
.Title {
  color: var(--DarkPurple, #11142D);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}
.input-Email-form {
  //width: 599px;
  //height: 59px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #D9D9D9;
  color: #A7A7A7;
  font-family: Proxima Nova, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 35vw;
}
.email-label {
  font-family: Proxima Nova, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}
.center-button {
  width: 50vw;
  height: 10vh;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.custom-Reset-button {
  flex-shrink: 0;
  border-radius: 9px;
  border: 0px solid #FFF;
  background: var(--Vieunite-Pro-Mauve, #A06174);
  margin-left: 50vw;
  margin-top: -5vh;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ProPassword-text-check {
  color: #000;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 1.0vw;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 28.6px */
}



