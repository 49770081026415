.input-form {
  .ant-form-item-control-input {
    border: 1px solid gray;
    border-radius: 8.69242px;
  }

  .ant-input-group-addon {
    background-color: #f1ecec;
    border: none;
    border-radius: 8.69242px 0 0 8.69242px;
  }
}

.whole-form .ant-form-item-label {
  font-family: Proxima Nova bold, sans-serif;
  font-weight: 700;
}

.art-editor {
  .ant-modal-content{
    padding: 4rem;
    border-radius: 2rem;
  }
  .ant-modal-header {
    border: none;
  }

  .ant-modal-title {
    font-family: Proxima Nova bold, sans-serif;
    font-weight: 700;
    font-size: 3em;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    gap: 2rem;
    //padding-bottom: 4rem;

    .ant-btn {
      border: 1px solid #105B63;
      width: 10rem;
      border-radius: 8.69px;
    }

    .ant-btn-primary {
      background-color: #105B63;
    }
  }
}

@media only screen and (max-width: 600px) {
  .art-editor{
    .ant-modal-header{
      padding: 0;
    }
    .ant-modal-body{
      padding: 0;
    }
    .ant-modal-footer{
      padding: 0;
    }
    .ant-modal-content{
      padding: 2rem;
      width: 100%;
      border-radius: 2rem;
    }
  }
}