.shopping-cart-order-div{
  width: 519px;
  height: 388px;
  flex-shrink: 0;
  border-radius: 13px;
  background: var(--Yellow, #FFF);
  padding-top: 2rem;
}
.shopping-cart-order-title{
  width: 503px;
  flex-shrink: 0;
  color: var(--Black, #000);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.shopping-cart-order-btn{
  width: 407px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 2rem 0 2rem;
}
.shopping-cart-order-my-bag{
  width: 171px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 248%;
  margin: 0 0 0 2rem;
}
.shopping-cart-order-total{
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 248%;
}

.shopping-cart-title-p{
  width: 671px;
  height: 43px;
  flex-shrink: 0;
  color: var(--Vieunite-Pro-Blue, #0E477A);
  font-family: Proxima Nova, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.shopping-cart-title-p-item{
  width: 113px;
  height: 41px;
  flex-shrink: 0;
  color: #000;
  text-align: right;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 248%; /* 44.64px */
}
.shopping-cart-divider{
  width: 40vw;
  height: 1px;
  margin-top: -2%;
  background: #D0D0D0;
  color: #D0D0D0;
}
.shopping-cart-sku-img{
  width: 12vw;
  height: auto;
  flex-shrink: 0;
  border: 0px solid #D0D0D0;
  background: lightgray 50% / contain no-repeat;
  .shopping-cart-sku-img-itself{
    width: 12vw;
    height: auto;
  }
}
.shopping-cart-sku-txt{
  width: 234px;
  flex-shrink: 0;
  color: var(--Black, #000);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.shopping-cart-sku-txt-price{
  color: var(--Black, #000);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700!important;
  line-height: 24px;
}