.featured-artist-p {
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 300;
  line-height: 0%; /* 0px */
  margin: 0 0 0 0;
}

.featured-artist-name {
  width: 303px;
  height: 37px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2.8vh;
}

.featured-artist-quote {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.featured-artist-quote-name {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin: 0vh 0 4vh 0;
}

.featured-artist-corner-div {
  width: 88px;
  height: 88px;
  flex-shrink: 0;
  border-radius: 0px 30px;
  background: rgba(47, 47, 47, 0.90);
  margin-top: -88px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-normal-btn {
  position: relative;
  font-size: 0.8vw;
  padding: 0.6rem 4rem;
  font-weight: bold;
  color: white;
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin: 1rem 0 0 0;
  box-shadow: inset 0 0 0 0 black;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.new-normal-btn > .new-in-normal-btn-link {
  color: black;
}

//
.new-normal-btn:hover {
  box-shadow: inset 1000px 0 0 0 #000000;
  color: white;
}

.new-normal-btn:hover .new-in-normal-btn-link {
  color: white;
}

.artist-image-container {
  display: inline-block;
  position: relative;
  overflow: hidden; /* Ensure the enlarged image is clipped to container size */
  width: 25vw;
  height: 25vw;
  cursor: pointer;
}

.artist-img-hover {
  transition: transform 0.3s ease; /* Smooth transition for the enlargement */
  position: absolute; /* Position the image absolutely to the container */
  top: 0;
  left: 0;
  //width: 100%; /* Ensure the image covers the container initially */
  //height: 100%;
  object-fit: cover; /* Adjust to fit container size */
}

.artist-image-container:hover .artist-img-hover {
  transform: scale(1.1); /* Adjust the scale value as needed */
}
.m-f-artist-desc-txt{
  display: none;
}
@media only screen and (max-width: 920px) {
  .m-f-artist-desc-txt{
    display: flex;
    flex-direction: column;
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .m-f-artist-quote {
    width: 80vw !important;
    margin: auto !important;
    justify-content: center !important;
  }
  .m-spec-quote-row{
    width: 100vw!important;
  }
  .m-f-artist-desc-row{
    padding-top: 40px!important;
  }
  .featured-artist-p {
    color: #FFF;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 300;
    line-height: 0%; /* 0px */
    margin: auto;
    margin-bottom: 1rem;
  }
}
