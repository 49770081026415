.publish-success-btn{
  width: 8vw;
  height: 40px;
  border-radius: 6px;
  background: #0E487A;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.anticon-check-circle{
  //display: none;
}
.publish-success-p{
  width: 18vw;
  height: 103.688px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: auto;
  margin-left: -1.4vw;
}
.publish-success-span{
  color: #0E487A;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}