.ma-tabs-container {
  display: flex;
  width: 29vw;
  height: 5vh;
  flex-shrink: 0;
  border-radius: 61px;
  background: #D9D9D9;
  margin-top: 2vh;
  margin-left: 30vw;
  //align-items: center;
  justify-content: center;
  padding: 0.5vh 0.5vh;
}

.ma-active-tab {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2px;
  width: 14vw;
  height: 4vh;
  flex-shrink: 0;
  border-radius: 48px;
  background: var(--Vieunite-Pro-Blue, #0E477A);
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.2vw 20px 20px;

  &:focus {
    background: var(--Vieunite-Pro-Blue, #0E477A) !important;
    color: #FFF !important;
    border-color: var(--Vieunite-Pro-Blue, #0E477A) !important;
  }

  &:hover {
    background: var(--Vieunite-Pro-Blue, #0E477A) !important;
    color: #FFF !important;
    border-color: var(--Vieunite-Pro-Blue, #0E477A) !important;
  }
}

.ma-inactive-tab {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2px;
  width: 14vw;
  height: 4vh;
  flex-shrink: 0;
  border-radius: 48px;
  background: transparent; /* 使非活跃按钮透明，以显示容器的灰色背景 */
  color: var(--Vieunite-Pro-Blue, #0E477A);
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.2vw 20px 20px;

  &:focus {
    background: var(--Vieunite-Pro-Blue, #0E477A) !important;
    color: #FFF !important;
    border-color: var(--Vieunite-Pro-Blue, #0E477A) !important;
  }

  &:hover {
    background: var(--Vieunite-Pro-Blue, #0E477A) !important;
    color: #FFF !important;
    border-color: var(--Vieunite-Pro-Blue, #0E477A) !important;
  }
}
.ma-active-tab, .ma-inactive-tab {
  margin-right: 10px; /* 给右边的按钮添加间距 */
  /* 其他样式保持不变 */
}
.ma-tabs-container button:last-child {
  margin-right: 0; /* 移除最后一个按钮的右边距 */
}
.ma-tab.active {
  border-bottom: none;
}


.plan-titles-container {
  margin-bottom: -4vh;
  margin-top: 2vh;
  display: flex;
}
.SubTable-title {
  color: #000;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Sub-Title-above1 {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 41.5vw;
}
.Sub-Title-above2 {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 11.5vw;
}
.Sub-Title-above3 {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 58vw;
}
.Sub-Title-above4 {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 11vw;
}
.Sub-Title-above5{
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 75vw;
}
//Card的样式
.Subportal-Card {
  width: auto;
  height: auto;
  flex-shrink: 0;
  border: 0px solid #C6C6C6;
  background: #FFF;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 40px;
  margin-bottom: 1vw;
}

//Table的样式
.custom-portal-table .ant-table-thead > tr > th {
  background-color: transparent !important; /* Set to 'transparent' or your desired background color */
}
.custom-portal-table .ant-table-tbody > tr:last-child > td {
  border-bottom: none !important;
}
.custom-portal-table .ant-table-thead > tr > th:first-child {
  border-right: 1px solid #B9B9B9;
}
.custom-portal-table .ant-table-tbody > tr > td:first-child {
  border-right: 1px solid #B9B9B9;
}
.custom-portal-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #B9B9B9 !important;
}
.custom-portal-table .ant-table-thead > tr > th {
  border-bottom: none !important;
}
.custom-portal-table {
  margin-top: -10px !important;
}
.custom-portal-table .ant-table-tbody > tr > td {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 0.3vh;
}

// 两个按钮的样式
.PortalButton-container {
  display: flex;
  //justify-content: space-between; /* Adjust as needed, can be 'flex-start', 'flex-end', 'center', 'space-between', 'space-around' */
  justify-content: flex-end;
  align-items: center; /* This will vertically align items on the same line */
  padding: 0 0.3vw 10px; /* This adds padding inside the container; adjust as needed */
}
.PortalSubUpgrade-Button {
  width: 10vw;
  height: 8vh;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--Vieunite-Pro-Blue, #0E477A);
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 2.7vw; /* 调整这个值来增加间距 */
  margin-top: -7vh !important; /* 减小值以减少距离，甚至可以尝试更小的值 */
  margin-left: 3.9vw;
}
.PortalSubCall-Button-text {
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

//弹窗的所有样式
.custom-subscription-modal .ant-modal-header {
  border-bottom: none;
}
.custom-subscription-modal .ant-modal-footer {
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-subscription-modal .ant-modal-close {
  display: none;
}
.custom-subscription-modal .ant-modal {
  width: 540px !important;
  height: 343px !important;
  flex-shrink: 0;
  border-radius: 20px !important;
  background: #FFF;
}
.custom-subscription-modal .ant-modal-content {
  height: 100%;
}
.custom-subscription-modal .ant-modal-body {
  padding: 24px; /* Or adjust to your preference for internal padding */
}
.custom-subscription-modal .ant-modal-header .ant-modal-title {
  color: #000;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 5vh;
}
.custom-subscription-modal .ant-modal-body .modal-text-guidance {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  margin-top: -2vh;
}
.custom-subscription-modal .ant-modal-body .modal-text-call {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.custom-subscription-modal .ant-modal-body .modal-text-email {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: -3vh;
}
.custom-subscription-modal .ant-modal-body p {
  text-align: center;
}
.custom-modal-ok-button {
  //width: 126px;
  //height: 56px;
  //flex-shrink: 0;
  //border-radius: 6px;
  //background: #0E487A;
  //color: #FFF;
  //text-align: center;
  //font-family: Proxima Nova,sans-serif;
  //font-size: 24px;
  //font-style: normal;
  //font-weight: 500;
  //line-height: normal;
  //margin-bottom: 3vh;
  //margin-top: -30px;
  width: 126px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #0E487A;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -50px;
  margin-bottom: 30px;
}
.custom-subscription-modal .ant-modal,
.custom-subscription-modal .ant-modal-content {
  border-radius: 12px !important; /* 调整为你想要的圆角大小 */
}
/* 针对Modal顶部的圆角 */
.custom-subscription-modal .ant-modal-content .ant-modal-header {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
/* 如果Modal底部也需要圆角的话 */
.custom-subscription-modal .ant-modal-content .ant-modal-footer {
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

//显示文字内容区域
.custom-portalContent-background {
  background: #F4F4F4; /* 或任何你希望的颜色 */
}
.ma-additional-services {
  width: 70vw;
  height: auto;
  flex-shrink: 0;
  border-radius: 13px;
  background: var(--Yellow, #FFF);
  margin-left: 8.5vw;
  margin-top: 4vh;
}
.ma-title {
  color: #000;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
  padding-top: 5px;
}
.ma-sub-title {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 1.0vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 27vw;
}
.ma-service {
  border-top: none;
  padding-top: -2.0vh;
  padding-bottom: 20px;
  margin-left: 50px;
}
.ma-service-header {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 1.0vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 50px;
}
.ma-service-content {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ma-service hr {
  margin: 20px 0; /* 添加上下边距 */
  border: 0; /* 移除默认边框 */
  height: 1px; /* 设置线条高度 */
  background-color: #ccc; /* 设置颜色 */
  width: 100%; /* 设置宽度 */
  width: 1369px;
  height: 3px;
  background: #F4F4F4;
}
.ma-contact-section {
  margin-top: 50px;
  margin-left: 40vw;
}
.ma-EmailButton {
  color: #fff;
  font-family: Proxima Nova,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  width: 200px;
  height: 40px;
  margin-bottom: 80px;
}
.ma-CallText {
  font-family: Proxima Nova,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 0.4vw;
}
.modal-text-guidance {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
}
.modal-text-call {

}