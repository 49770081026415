.m-new-hd-p{
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 4vh 6vw;
  cursor: pointer;
}
.m-new-hd-login{
  text-decoration: none;
  color: black;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
  padding: 20px;
  margin-left: -4vw;
}
body {
  h1 {
    position: fixed;
    top: 0;
    left: 36vw;
    z-index: 6;

    > a {
      text-transform: none;
      font-weight: 300;
      font-size: 12px;
    }
  }

  input + label {
    position: fixed;
    top: 1.9rem;
    right: auto;
    left: 80vw;
    height: 20px;
    width: 32px;
    z-index: 5;

    span {
      position: absolute;
      width: 100%;
      height: 4px;
      top: 50%;
      //margin-top: -1px;
      right: 0;
      display: block;
      background: black;
      transition: .5s;
    }

    span:first-child {
      top: 2px;
    }

    span:last-child {
      top: 18px;
    }
  }

  label:hover {
    cursor: pointer;
  }

  input:checked + label {
    span {
      opacity: 0;
      top: 50%;
    }

    span:first-child {
      opacity: 1;
      transform: rotate(405deg);
    }

    span:last-child {
      opacity: 1;
      transform: rotate(-405deg);
    }
  }

  input ~ nav {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 10vh;
    z-index: 3;
    transition: .5s;
    transition-delay: .5s;
    overflow: hidden;

    > ul {
      position: absolute;
      list-style-type: none;
      top: 10%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 85%;
      height: 90vh;

      > li {
        opacity: 0;
        transition: .5s;
        transition-delay: 0s;
        width: 100%;

        > div > a.new-m-hd-a {
          text-decoration: none;
          color: black !important;
          font-weight: 600;
          font-family: Proxima Nova Regular, sans-serif;
          display: block;
          padding: 20px;
          margin-left: -4vw;
        }

        > a {
          text-decoration: none;
          color: black;
          font-family: Proxima Nova Regular, sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          display: block;
          padding: 20px;
          margin-left: -4vw;
        }

        > .m-menu-divider {
          width: 100%;
          margin: -0.5rem 0;
          color: black;
        }
      }
    }
  }

  input:checked ~ nav {
    height: 100%;
    transition-delay: 0s;

    > ul {
      > li {
        opacity: 1;
        transition-delay: .5s;
      }
    }
  }
}