.collection-see-all-button {
    color: var(--DarkPurple, #11142D);
    text-align: right;
    font-family: Proxima Nova;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.collection-category-title {
    color: #000;
    font-family: Proxima Nova, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* margin-left: 45px; */
    margin-top: 25px;
}

.all-artworks-title {
    color: #000;
    font-family: Proxima Nova, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 25px;
}

.search-input-frame {
    width: 322px;
    height: 40px;
    align-items: center;
    flex-shrink: 0;
    margin: 10px;
}

.search-button-frame {
    width: 60px;
    height: 40px;
    align-items: center;
    flex-shrink: 0;
    margin: 5px;
}

.gallery-card-container {
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
    padding: 20px;
    min-width: 800px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    background-color: white;
    border-radius: 6px;
}

.gallery-card-title {
    color: #000;
    font-family: Proxima Nova;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin-left: 0px;
    margin-top: 35px;
    /* 81.25% */
}

.gallery-card-body {
    color: #000;
    font-family: Proxima Nova;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-left: 63px;
}

.discover-see-all-font {
    color: #000;
    text-align: right;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.discover-main-filter-title {
    color: #000;
    /* font-family: "Proxima Nova"; */
    font-family: Proxima Nova Regular, sans-serif;
    font-size: calc(1.3rem + 0.6vw);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 50px;
    margin-top: 40px;
}

.discover-category-button {
    height: 40px;
    flex-shrink: 0;
    border-radius: 6px;
    /* background: var(--Vieunite-Pro-Blue, #0E477A); */
    /* color: #FFF; */
    text-align: center;
    font-family: "Proxima Nova";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
}

.slick-list>div {
    margin-left: 0;
}