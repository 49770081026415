/* 顶部背景部分样式 */
.help-center-top-section {
  position: relative;
  height: 55vh; /* 设置显示区域的固定高度 */
  background-image: url('./img/b645f104b6b0be88232515097d3bb242.jpeg'); /* 背景图像路径 */
  background-size: cover; /* 确保图像覆盖整个区域而不拉伸 */
  background-position: center; /* 图像居中显示 */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  overflow: hidden; /* 确保伪元素不溢出 */
  /*box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);*/
  border: 0.063rem #000;
}

.help-center-top-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* 黑色背景，60% 透明度 */
  z-index: 1; /* 确保滤镜位于背景图上方 */
}

/* 确保文本内容在滤镜上方 */
.help-center-text-content {
  /*background: red;*/
  position: relative;
  z-index: 2; /* 确保文字内容在滤镜上方 */
}

.help-center-text-content h3,
.help-center-text-content h2 {
  width: 100%;
  text-align: center;
  margin: 0;
  color: white;
}

/* 选项卡部分样式 */
.help-center-options-section {
  display: flex;
  justify-content: center;
  gap: 1.5vw;
  margin: 3rem 0;
  /*max-width: 60rem;*/
}

.help-center-option-card-box {
  overflow: hidden;
  border-radius: 0.938rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

/*.help-center-option-card-box:hover {*/
/*    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.3); !* 添加阴影效果 *!*/
/*}*/

.help-center-option-card {
  width: 30vw;
  height: auto;
  max-width: 16rem;
  color: white;
  border-radius: 0.938rem;
  transition: transform 0.3s ease
}
.help-center-option-card:hover {
  /*box-shadow: 0 4px 25px rgba(0, 0, 0, 0.3); !* 添加阴影效果 *!*/
  transform: scale(1.05);
}

@media screen and (max-width: 600px) {
  .help-center-option-card {
    width: 80vw;
    max-width: 80vw;
  }
  .help-center-options-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .help-center-option-card-box {
    width: 80vw;
  }
  .help-center-support-bottom-section {
    padding: 5rem 1vw;
  }
}

/* 请求支持部分样式 */
.help-center-support-bottom-section {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  overflow: clip;
  border-radius: 1.125rem;
  margin: 0 9vw 4rem 9vw;
  padding: 4rem 1vw;
  transition: box-shadow 0.3s ease;
}

.help-answer-portrait-image-limit {
  max-width: 80vw;
  width: 15.625rem;
}
.help-answer-bold-list {
  font-family: Proxima Nova Regular, sans-serif;
  font-weight: bold;
}

/*.help-center-support-bottom-section:hover {*/
/*    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.3);*/
/*}*/

.help-center-support-section {
  background-color: white;
  padding: 1.25rem;
  border-radius: 0.625rem;
  width: 80%;
  max-width: 37.5rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
}

.help-center-support-section h3 {
  margin-top: 0;
}

.help-center-support-button {
  padding: 0.625rem 1.25rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0.313rem;
  cursor: pointer;
}

.help-center-support-button:hover {
  background-color: #555;
}

#footer > .thankyou-footer-wrapper {
  display: none !important;
  align-items: center;
  font-family: 'Circular Std', sans-serif;
  border-top: 0.063rem solid #C8CEED;
  box-shadow: 0 0.25rem 0.25rem -0.063rem rgba(0,0,0,0.1);
  background-color: #ffffff;
  padding: 0.75rem 0.938rem;
  overflow: hidden;
}

.help-center-title {
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 2.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.help-center-subtitle {
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.help-center-collapse-copy {
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}