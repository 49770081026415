.homeVideoContainer {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  //min-width: 950px;
}

.homeVideoContainer video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.homeVideoContainer::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  /*background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 1%, rgba(0,0,0,0.61) 93%, rgba(0,0,0,0.65) 99%); !* FF3.6-15 *!*/
  /*background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.61) 93%,rgba(0,0,0,0.65) 99%); !* Chrome10-25,Safari5.1-6 *!*/
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.61) 93%, rgba(0, 0, 0, 0.65) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.homeVideoTextContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  //top: 0;
  width: 100vw;
  height: 100vh;
  //overflow: hidden;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  color: white;
  //min-width: 950px;
}

.homeVideoTextContainer p {
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.homeMainButton {
  //position: absolute;
  /*top: 50%;*/
  /*left: 50%;*/
  font-size: 1rem;
  font-weight: bold;
  color: white;
  ///*background-color: #614ab1;*/
  background-color: #FFFFFF;
  border: 0.125rem solid black;
  border-radius: 0.313rem;
  cursor: pointer;
  text-align: center;
  margin: .5rem 0 0 0;
  padding: 0.75rem 2.25rem;
  box-shadow: inset 0 0 0 0 #000000;
  //box-shadow: inset 0 0 0 0 #000000;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
.homeMainButton > .homeMainButtonLnk{
  color: black;
}

.homeMainButton:hover {
  box-shadow: inset 62.5rem 0 0 0 #000000;
  color: white;
}
.homeMainButton:hover .homeMainButtonLnk{
  color: white;
}

.homeDescriptionText {
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  //line-height: normal;
  padding: 4.5vw 15vw;
  text-align: center;
}

.homeSection1 {
  overflow: clip;
  background: #C2D9D6;
  border-radius: 1.125rem;
  margin: 0 9vw;
  //height: 40vw;
}

.section1TextContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  //top: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 100%;
  //overflow: hidden;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  //color: white;
  //min-width: 950px;
}

.homeSection1Button {
  //position: absolute;
  /*top: 50%;*/
  /*left: 50%;*/
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  ///*background-color: #614ab1;*/
  background-color: #000000;
  border: 0.125rem solid black;
  border-radius:0.313rem;
  cursor: pointer;
  text-align: center;
  margin: .5rem 0 0 0;
  padding: 0.75rem 2.25rem;
  box-shadow: inset 0 0 0 0 #FFFFFF;
  //box-shadow: inset 0 0 0 0 #000000;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
.homeSection1Button > .homeSection1ButtonLnk{
  color: white;
}

.homeSection1Button:hover {
  box-shadow: inset 50rem 0 0 0 #FFFFFF;
  color: black;
}
.homeSection1Button:hover .homeSection1ButtonLnk{
  color: black;
}

.homeOverV{
  font-family: Proxima Nova semibold, sans-serif;
  letter-spacing: 0.031rem;
  font-size: 1rem;
}

.homeSection1TitleText {
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.9rem;
  font-style: normal;
  font-weight: 700;
  //line-height: normal;
  width: 100%;
  margin: 0 0;
  //padding-bottom: -3rem;
}

.homeSection1BodyText {
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  //line-height: normal;
  width: 100%;
  //padding-bottom: 10%;
}

.homeSection2 {
  overflow: clip;
  margin: 0 5vw;
  //height: 40vw;
}

.homeSection2Background {
  background-image: url('./img/desktop-album-evergreen-1x 1.png');
  background-position: top right;
  background-repeat: no-repeat;
  width: 100%;  /* 根据需要调整宽度 */
  height: fit-content; /* 根据需要调整高度 */
}

.homeSection3Row {
  margin: 2rem 9vw 1rem 9vw;
  overflow: clip;
  background: #343434;
  border-radius: 1.125rem;
}

.homeSection3Height {
  height: 11.25rem;
}
.homeSection3TextWidth {
  width: 60%;
}

.homeSection3 {
  overflow: clip;
  background: #343434;
  border-radius: 1.125rem;
  margin: 0 9vw;
}

.homeSection3Background {
  width: 100%;
  //background-color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
}

.section3TextContainer {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
}

.homeSupportTextContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  //top: 0;
  width: 100vw;
  //overflow: hidden;
  background-color: white;
  justify-content: center;
  align-items: center;
  //min-width: 950px;
  overflow: clip;
  border-radius: 1.125rem;
  margin: 1rem 5vw 4rem 9vw;
  padding: 6vw 1vw;
}

.homeTexturaExampleContainer {
  position: relative;
  //top: 0;
  width: 100vw;
  //overflow: hidden;
  background-color: black;
  //min-width: 950px;
  overflow: clip;
  border-radius: 1.125rem;
  margin: 3vw 9vw;
  padding: 3vw 2vw 3vw 3vw;

}

//.homeTexturaExampleTitleRow {
//  display: flex;
//  align-items: center;
//  width: 100%;
//}

.homeSpacer {
  flex-grow: 1;
}

.homeSupportBody {
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.05rem;
  font-style: normal;
  font-weight: 600;
  //line-height: normal;
  padding: 0.5vw 10vw;
  text-align: center;
}

.homeSupportButton {
  //position: absolute;
  /*top: 50%;*/
  /*left: 50%;*/
  width: 40vw;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  ///*background-color: #614ab1;*/
  background-color: #000000;
  border: 0.125rem solid black;
  border-radius:0.313rem;
  cursor: pointer;
  text-align: center;
  margin: .5rem 0 0 0;
  padding: 0.75rem 2.25rem;
  box-shadow: inset 0 0 0 0 #FFFFFF;
  //box-shadow: inset 0 0 0 0 #000000;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
.homeSupportButton > .homeSupportButtonLnk{
  color: white;
}

.homeSupportButton:hover {
  box-shadow: inset 50rem 0 0 0 #FFFFFF;
  color: black;
}
.homeSupportButton:hover .homeSupportButtonLnk{
  color: black;
}


.example-section-image-container:hover {
  transform: scale(1.08);
}

.example-section-image-container {
  cursor: pointer;
  position: relative;
  width: 33%;
  display: inline-block;
  height: auto;
  padding-bottom: 1.25rem;
  padding-right: 1.1vw;
  transition: transform 0.3s ease;
  transform: scale(1);
}

.example-section-image {
  display: block;
  width: 100%;
  height: 100%;
}

.example-section-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0));
  color: white;
  text-align: center;
  margin: 0.625rem 0;
}

.example-section-text {
  font-size: 1.25rem;
  font-weight: 500;
  padding: 1.25rem;
  text-align: left;
}

@media (max-width: 62.063rem) {
  .homeSection3 {
    height: 53.125rem;
  }

  .homeSection3TextCover {
    height: 53.125rem;
    width: 70%;
  }

  .homeSection3Height {
    height: 17.5rem;
  }
  .homeSection3TextWidth {
    width: 80%;
  }

}

@media (max-width: 49.5rem) {
  .example-section-image-container {
    width: 100%;
  }

  .homeSection3Height {
    height: 25.625rem;
  }
  .homeSection3TextWidth {
    width: 100%;
  }
}

@media (min-width: 62rem) {
  .homeSection3 {
    height: 46.875rem;
  }

  .homeSection3TextCover {
    height: 46.875rem;
    width: 60%;
  }

  .homeSection3Height {
    height: 17.5rem;
  }
  .homeSection3TextWidth {
    width: 80%;
  }
}

@media (min-width: 93.75rem) {
  .homeSection3 {
    height: 40.625rem;
  }

  .homeSection3TextCover {
    height: 40.625rem;
    width: 40%;
  }

  .homeSection3Height {
    height: 11.25rem;
  }
  .homeSection3TextWidth {
    width: 60%;
  }
}