.landingVideoContainer {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: url("./img/landing_video_placeholder.webp") no-repeat center center fixed;
    background-size: cover;
}

.landingVideoImg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingBackgroundDim {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(./img/landing_welcome.webp) lightgray 50% / cover no-repeat;
}

.landingTitle {
    color: #FFF;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 3rem;
}

.landingSubtitle {
    color: #FFF;
    font-size: 2.375rem;
    font-style: italic;
    font-weight: 700;
    /*line-height: normal;*/
    background-color: transparent;
    text-align: left;
    /*margin-left: 3rem;*/
    /*height: 100px;*/
    margin: 10px 3rem;
    padding: 0;
    line-height: 50px;
}

.landingSection {
    position: relative;
    width: 100vw;
    background-color: #F0F0F0;
    margin: 0;
    padding: 0;
}

.landingDescription {
    color: #FFF;
    font-size: 2.3rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    width: 36%;
    min-width: 600px;
    margin: 10rem;
}

.landingDescriptionMobile {
    color: #FFF;
    font-size: 2.3rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin: 2rem;
}

.landingBlock {
    width: 100vw;
    height: 100vh;
    position: relative;
}
.landingBlockSticky {
    width: 100vw;
    height: 100vh;
    position: sticky;
}

.landingBackground1 {
    background: url(./img/landing_background1.webp) no-repeat center center;
    background-size: cover;
}

.landingBackground2 {
    background: url(./img/landing_background2.webp) no-repeat center center;
    background-size: cover;
}

.landingBlockDescription {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
}

.landingMarqueeContainer {
    width: 100vw;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    background-color: #F0F0F0;
}

.landingMarqueeContent1 {
    display: inline-block;
    white-space: nowrap;
    animation: marquee1 60s linear infinite;
    background-color: #F0F0F0;
}

.landingMarqueeContent2 {
    display: inline-block;
    white-space: nowrap;
    animation: marquee2 60s linear infinite;
    background-color: #F0F0F0;
}

@keyframes marquee2 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes marquee1 {
    0% {
        transform: translateX(-5%);
    }
    100% {
        transform: translateX(-55%);
    }
}

.paused1 .landingMarqueeContent1 {
    animation-play-state: paused;
}

.paused2 .landingMarqueeContent2 {
    animation-play-state: paused;
}

.landingMarqueeItem {
    display: inline-block;
    padding: 0 10px;
}

.landingSectionTitle {
    color: black;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 3rem 9vw;
    margin: 0;
}

.landingInnovatingTitle {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.landingInnovatingBody {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
}

.landingPartnershipTitle {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 14.7px;
    width: 100%;
}

.landingPartnershipContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.landingLogo {
    width: 210px;
    height: 140px;
    margin: 20px;
}

.landingGetStartedTitle {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    width: 100%;
}

.landingGetStartedBody {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
    text-align: center;
    width: 100%;
    padding: 0 20vw;
}

.landingActionCard {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1.5rem 2.5rem;
    opacity: 0;
    transition: opacity 0.3s;
    /*flex-wrap: wrap;*/

    .cardTitle {
        color: #000;
        font-size: 30px;
        /*font-style: normal;*/
        font-weight: 700;
        line-height: normal;
        width: 100%;
        word-wrap: break-word;
        text-align: left;
        white-space: normal;
        margin-bottom: 5px;
    }
    .cardBody {
        /*background-color: red;*/
        color: #000;
        font-size: 16px;
        /*font-style: normal;*/
        font-weight: 400;
        /*line-height: 25px;*/
        width: 100%;
        flex: 1;
        word-wrap: break-word;
        white-space: normal;
        /*overflow: hidden;*/
    }
}

.landingActionCard:hover {
    opacity: 1;
}

.landingCreationsTitle {
    color: #000;
    text-align: center;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.landingCreationsBody {
    padding: 0 8vw;
    color: #000;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.56rem; /* 156.25% */
}

.landingCreationsImg {
    width: 11.7vw;
    min-width: 100px;
    height: 11.7vw;
    min-height: 100px;
    border-radius: 15px;
    margin: 1vw;
}

.landingDemoCard {
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 13px;
    padding: 2rem;

    .artistTitle {
        color: #000;
        text-align: center;
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 5px;
    }
    .artistBody {
        color: #000;
        font-style: italic;
        text-align: center;
        font-size: 1vw;
        /*font-style: normal;*/
        font-weight: 400;
        line-height: normal;
    }
}

.landingDemoCard:hover {
    opacity: 0.85;
}

.landingMobileText {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
}

.landingMobileTitle {
    color: #000;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.landingCentralText {
    text-align: center;
}

.landingCreationsMobileImg {
    width: 29vw;
    height: 29vw;
    border-radius: 15px;
    margin: 1vw;
    padding: 0;
}

