.portal-Order-Details {
  margin-top: 1rem;
  height: 60rem;
  margin-left: 8rem;
}

.portal-Order-Details-Title {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1rem;
}

.portal-Order-Details-Button {
  color: #FFF;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  width: 208px;
  height: 34px;
  flex-shrink: 0;
  margin-right: 10vw;
}

.DownloadPDFIcon {
  position: relative;
  top: -2px; /* Adjust as needed */
  left: -2px; /* Adjust as needed */
}

.portalOrderFirstRow {
  margin-top: 50px;
}

.portal-OrderDetailsTitle {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.portal-OrderDetailsTable {
  width: 30vw;
  height: auto;
  margin-top: -50px;
}

.portal-DeliveryDetailsTitle {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.portal-DeliveryDetailsTable {
  width: 30vw;
  height: auto;
  margin-top: -50px;
}

.portal-PaymentDetailsTitle {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.portal-paymentDetailsTable {
  width: 72vw;
  height: auto;
  margin-top: -40px;
}

.portal-itemsTable {
  width: 72vw;
  height: auto;
}

.portal-subTotal {
  margin-right: 10vw;
  margin-top: -30px;
}

.portal-subTotal-Text {
  color: var(--Black, #000);
  font-family: Proxima Nova, sans-serif;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  display: block; /* 设置为块级元素，每一项自然会显示在新的一行 */
  position: relative; /* 相对定位，用于定位横线 */
  padding-bottom: 4px; /* 文本与横线之间的间距 */
  margin-bottom: 12px; /* 每一项之间的间距 */
}

.portal-subTotal-Text::after {
  content: ''; /* 伪元素的内容为空，用于显示横线 */
  display: block; /* 使伪元素表现为块级元素 */
  border-bottom: 1px solid #000; /* 横线的样式 */
  position: absolute; /* 绝对定位 */
  width: 15%; /* 横线的宽度，根据实际需要调整 */
  bottom: 0; /* 在文本的底部显示横线 */
  right: 0; /* 确保横线从文本的右侧开始 */
}

/* 排除最后一个元素的横线 */
.portal-subTotal-Text:last-child::after {
  display: none;
}

.portal-subTotal-price1 {
  display: inline-block; /* 使价格成为内联块级元素，以便可以应用间距 */
  margin-left: 20px; /* 在价格和前面的文本之间添加间距 */
}

.portal-subTotal-price2 {
  display: inline-block; /* 使价格成为内联块级元素，以便可以应用间距 */
  margin-left: 50px; /* 在价格和前面的文本之间添加间距 */
}

.portal-subTotal-price3 {
  display: inline-block; /* 使价格成为内联块级元素，以便可以应用间距 */
  margin-left: 50px; /* 在价格和前面的文本之间添加间距 */
}

.portal-subTotal-price4 {
  display: inline-block; /* 使价格成为内联块级元素，以便可以应用间距 */
  margin-left: 30px; /* 在价格和前面的文本之间添加间距 */
}

.portal-subTotal-price5 {
  display: inline-block; /* 使价格成为内联块级元素，以便可以应用间距 */
  margin-left: 20px; /* 在价格和前面的文本之间添加间距 */
}







