.title-over-artist-video {
    position: absolute;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -90%);
    -ms-transform: translate(-50%, -90%);
    background-color: transparent;
    color: white;
    font-size: 8rem;
    font-weight: 700;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.938rem;
    text-align: center;
    width: 100%;
}

.ant-image-mask {
    display: none;
}

.protected-img {
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

/*.ant-modal {*/
/*    width: 100vw !important;*/
/*    max-width: 100vw !important;*/
/*    top: 0;*/
/*    padding-bottom: 0;*/
/*}*/

/*.ant-modal-body {*/
/*    height: calc(100vh);*/
/*    overflow-y: auto;*/
/*}*/
.ant-modal-close-x {
    color: black;
    font-size: 1.875rem;
}

.normal-btn {
    position: relative;
    font-size: 0.8vw;
    padding: 0.6rem 4rem;
    font-weight: bold;
    color: white;
    background-color: black;
    border: 0.125rem solid black;
    border-radius: 0.313rem;
    cursor: pointer;
    text-align: center;
    margin: 1rem 0 0 0;
    box-shadow: inset 0 0 0 0 black;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;

}

.normal-btn > .in-normal-btn-link {
    color: white;
}

.normal-btn:hover {
    box-shadow: inset 62.5rem 0 0 0 #ffffff;
    color: black;
}

.normal-btn:hover .in-normal-btn-link {
    color: black;
}

@media only screen and (min-width: 57.5rem) {
    .d-arts-img {
        width: 25vw;
        height: 25vw;
        object-fit: contain;
        box-sizing: border-box;
        display: block;
        /*text-align: center;*/
        /*margin: auto;*/
    }

    .heighwidth {
        color: #fff;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        padding: 1rem 0;
    }

    .heightwidthbox {
        position: relative;
        font-family: Proxima Nova bold, sans-serif;
        color: #fff;
        box-sizing: border-box;
        width: 25vw;
        height: 25vw;
        object-fit: contain;
        text-align: center;
        margin: 0 0 0 -12.5vw;
    }

    .artwork-img {
        font-family: Proxima Nova bold, sans-serif;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        border: 0;
        vertical-align: middle;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        border-radius: .625rem;
        object-fit: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .m-imgs {
        display: none;
    }

    .dt-left {
        margin-left: -4rem;
    }
}

@media only screen and (max-width: 600px) {
    .img-viewer-modal {
        overflow-y: hidden;
        position: relative;
    }
}

@media only screen and (max-width: 920px) {
    .m-img-viewer {
        padding-top: 50% !important;
    }

    .m-img-fullscreen {
        width: 100% !important;
    }

    .artwork-img {
        /*display: inline-flex;*/
        margin-left: 0 !important;
    }

    .m-imgs {
        display: initial;
    }

    .website-row {
        margin-top: -2rem;
    }

    .m-a4 {
        flex-direction: column;
    }

    .title-over-artist-video {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -80%);
        -ms-transform: translate(-50%, -80%);
        background-color: transparent;
        color: white;
        font-size: 3rem;
        font-weight: 700;
        border: none;
        text-align: center;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 3rem;
    }

    .m-left-text {
        text-align: left !important;
    }

    .normal-btn {
        font-size: 1.1rem;
        margin-bottom: 2rem;
    }

    .m-bio {
        font-size: 1.25rem !important;
        margin-top: 2rem !important;
        font-weight: 300 !important;
    }

    .m-img-art {
        align-content: flex-start;
    }

    .m-date {
        /*margin-top: -5% !important;*/
    }

    .m-artwork-row {
        padding-top: 1rem !important;
    }

    .m-quote {
        font-size: 1.5rem !important;
    }
}

