.research-demo-p1 {
  width: 42.063rem;
  height: 3.938rem;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: auto;
}

.research-demo-p2 {
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 2vh auto 0 auto;
}

.research-demo-p3 {
  //width: 1535px;
  width: 80vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 4vh auto 4vh auto;
}

.research-demo-img {
  margin: 4vh 0 4vh 24vw;
  border-radius: 0.625rem;
  width: 50vw;
}

.research-demo-final-div {
  width: 80vw;
  height: 40vh;
  flex-shrink: 0;
  border-radius: 1.438rem;
  background: #FFF;
  margin: 8vh auto 0 auto;
  padding: 3rem;
}

.research-demo-final-div-p1 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.research-demo-final-div-p2 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
}

.research-demo-paper-p1 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 3.75rem;
}

.research-demo-paper-p2 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.research-demo-paper-p3 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  margin: auto;
}

.research-demo-paper-p4 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
}

.research-demo-btn {
  display: flex;
  width: 10vw;
  height: 5vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 0.625rem;
  margin: 0 0 1.875rem 0;
  border-color: black!important;
}

@media only screen and (max-width: 600px) {
  .research-demo-p1 {
    width: 80vw;
    height: 3.938rem;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 2vh auto;
  }

  .research-demo-p2 {
    width: 80vw;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 2vh auto 0 auto;
  }

  .research-demo-p3 {
    //width: 1535px;
    width: 80vw;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 4vh auto 4vh auto;
  }

  .research-demo-img {
    margin: 4vh auto 4vh 10vw;
    border-radius: 0.625rem;
    width: 80vw;
  }

  .research-demo-final-div {
    width: 80vw;
    height: auto;
    flex-shrink: 0;
    border-radius: 1.438rem;
    background: #FFF;
    margin: 8vh auto 0 auto;
    padding: 3.75rem;
  }

  .research-demo-final-div-p1 {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .research-demo-final-div-p2 {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
  }

  .research-demo-paper-p1 {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 3.75rem;
  }
  .research-demo-paper-p2 {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .research-demo-paper-p3 {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    margin: auto;
  }
  .research-demo-paper-p4 {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
  }
  .research-demo-btn {
    display: flex;
    width: 80vw;
    height: 5vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 0.625rem;
    margin: 0 0 1.875rem 0;
  }
}


