.art-title-subtitle {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 0;
    margin-bottom: 1.563rem;
}

.art-title-main {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 97%;
    margin-bottom: 1.563rem;
}

.black-border-hover-white {
    border: 0.125rem solid black;
}

.art-learn-more-button-length {
    width: 90rem
}

/*.black-border-hover-white:hover {*/
/*    border: 2px solid white;*/
/*}*/

.art-tab-container {
    .ant-tabs-tab-btn {
        color: #999;
        text-align: center;
        font-family: Proxima Nova Regular, sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
    }

    .ant-tabs-tab {
        width: 33.3%;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000;
        font-weight: 500;
    }

    .ant-tabs-nav-list {
        width: 100%;
        justify-content: center;
    }

    .ant-tabs-ink-bar {
        background-color: #000; /* 你想要的颜色 */
    }

    .ant-tabs-nav::before {
        border-color: #d9d9d9; /* 自定义分隔线颜色 */
    }

    .ant-tabs-tab+.ant-tabs-tab {
        margin: 0
    }
}

.art-card-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin: 0.938rem;
    /*background-color: red;*/
}

.art-card-title-bold {
    font-weight: 700;
}

.art-card-body {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 0.313rem;
    max-width: 61.5rem;
}

.art-filter-alphabet {
    color: #A9A9A9;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.4vw;
    cursor: pointer;
}

.art-filter-alphabet-disable {
    color: #DFDFDF;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.4vw;
}

.art-filter-alphabet:hover {
    color: #000;
}

.art-filter-alphabet-selected {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.4vw;
    cursor: pointer;
    text-decoration-line: underline;
}

.art-artist-avatar {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease
}

.art-artist-avatar:hover {
    transform: scale(1.05);
}

.art-artist-name-label {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0.5rem 0;
}

.art-curation-title-label {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0.5rem 0;
}

.art-curation-body-label {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.563rem; /* 156.25% */
}

.art-page-fade {
    transition: opacity 0.25s ease-in-out;
}

.art-page-fade-in {
    opacity: 1;
}

.art-page-fade-out {
    opacity: 0;
}

.art-page-curation-people-title {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 1rem 0;
}

.art-page-curation-people-body {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.art-mobile-recommend-title {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    /*line-height: 0%; !* 0px *!*/
}

.art-mobile-recommend-body {
    color: #000;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 97%;
}

.text-central {
    text-align: center;
}

.art-tab-container-mobile {
    .ant-tabs-tab-btn {
        color: #999;
        text-align: center;
        font-family: Proxima Nova Regular, sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        word-wrap: break-word;
        white-space: normal;
        width: 100%;
    }

    .ant-tabs-tab {
        width: 33.3%;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000;
        font-weight: 500;
    }

    .ant-tabs-nav-list {
        width: 100%;
        justify-content: center;
    }

    .ant-tabs-ink-bar {
        background-color: #000; /* 你想要的颜色 */
    }

    .ant-tabs-nav::before {
        border-color: #d9d9d9; /* 自定义分隔线颜色 */
    }

    .ant-tabs-tab+.ant-tabs-tab {
        margin: 0
    }
}

.art-section-title {
    padding: 0 1rem;
    margin: 0;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}