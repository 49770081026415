.Portalsupport-container {
     margin-top: 50px;
     height: 60rem;
}
.support-section {
  //margin-left: 6rem;
  //margin-top: -1rem;
  margin-left: 50px;
}
.support-section-title {
  color: var(--Vieunite-Pro-Blue, #0E477A);
  font-family: Proxima Nova, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  //margin-top: 0.8rem;
}
.support-section-paragragh {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 20px;
}
.Prosupport-Card {
  width: 327px;
  height: 327px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 3px solid #D9D9D9;
  background: var(--Yellow, #FFF);
  padding-top: 10px;
  margin-top: 100px;
}

.support-section-downloads {
  color: var(--Vieunite-Pro-Blue, #0E477A);
  font-family: Proxima Nova, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 100px;
}
.support-section-manual {
  color: var(--Vieunite-Pro-Blue, #0E477A);
  font-family: Proxima Nova, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Tutorials-icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-top: 80px;
  margin-left: 110px;
}
.Tutorials-Card-Name {
  color: #727272;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}
.FAQ-icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-top: 80px;
  margin-left: 110px;
}
.FAQ-Card-Name {
  color: #727272;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 2px;
}
.Support-icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-top: 80px;
  margin-left: 110px;
}
.Support-Card-Name {
  color: #727272;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}
//.header4 {
//  display: flex;
//  align-items: center; /* 垂直对齐 */
//  //margin-top: -3rem;
//}
//
//.BackButtonIcon5 {
//  margin-left: -6rem;
//  margin-top: 0.8rem;
//  cursor: pointer;
//}
