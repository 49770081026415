.ProLogo {
  width: 10vw;
  height: 8vh;
  flex-shrink: 0;
  margin-left: 22vw;
  margin-top: 10vh;
}
.ProVerification-Content {
  margin-left: 30vw;
  margin-right: 30vw;
}
.ProVerification-text {
  color: #000;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 13vh;
}
.OK-Button {
  width: 30vw;
  height: 6vh;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0px solid #0E487A;
  background: #A06174;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.custom-OK-Button {
  margin-left: 5vw;
  margin-top: 5vh;
}
.Email-button {
  width: 30vw;
  height: 6vh;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0px solid #0E487A;
  background: #A06174;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.custom-Email-Button {
  margin-left: 5vw;
  margin-top: 5vh;
  margin-bottom: 20vh;
}