.tou {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: grid;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    z-index: 4;
    background-color: white;
    padding-top: 8px;
    border-bottom: 1px solid #a1a1a1;
}

@media only screen and (max-width: 600px){
    .tou{
        padding-bottom: 8px;
        border-bottom: 1px solid #a1a1a1;
    }
}

.menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: baseline;
    list-style: none;
    padding-inline-end: 30px;
}

.menu li {
    position: relative;
}

.header-svg-shape {
    transition: fill 0.3s ease;
}

.header-svg-shape:hover {
    fill: white;
}

.header-svg-shape-transparent {
    transition: fill 0.3s ease;
}

.header-svg-shape-transparent:hover {
    fill: gray;
}

/*.menu-mobile {*/
/*    display: none;*/
/*}*/
.mobile-header{
    display: none;
}
.desktop-header{
    display: grid;
    grid-template-columns: 3fr 48fr 4fr;
    justify-items: center;
    align-items: center;
    margin-left: 1rem;
}

@media only screen and (max-width: 920px) {
    .desktop-header {
        display: none;
    }

    .mobile-header {
        display: flex;
        justify-items: center;
        align-items: center;
    }
}


.menu a:hover {
    color: #dd4472;
}

.mobile-menu {
    position: relative;
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
    color: rgb(28, 36, 148) !important;
    text-decoration: none;
}

.menu a {
    position: relative;
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
    color: #EBEBEB;
    text-decoration: none;
}
.menu a:checked{
    text-decoration: underline;
}

.social-icon-btn {
    background-color: inherit;
    border: none;
    cursor: pointer;
}

.menu-mobile {
    height: 100%;
    width: 100%;
    /*position: absolute;*/
}

.ant-dropdown-menu-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #3F3F3F!important;
    font-family: Proxima Nova Regular, sans-serif;
}

.hover-underline-no-animation {
    display: inline-block;
    position: relative;
    color: #3F3F3F!important;
    font-family: Proxima Nova Regular, sans-serif;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.trans-upside-down-animation {
    transition: transform 0.3s ease;
    transform: scaleY(-1);
}

.trans-upside-down-animation-reversed {
    transition: transform 0.3s ease;
    transform: scaleY(1);
}

.tans-hover-underline-no-animation {
    display: inline-block;
    position: relative;
    color: #EBEBEB!important;
    font-family: Proxima Nova Regular, sans-serif;
}

.tans-hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #EBEBEB!important;
    font-family: Proxima Nova Regular, sans-serif;
}

.tans-hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.tans-hover-underline-animation:hover{
    color: white!important;
}

.tans-hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    /*color: green!important;*/
}

.select-underline-animation {
    display: inline-block;
    position: relative;
    color: #6F6F6F!important;
    font-family: Proxima Nova Regular, sans-serif;
}

.select-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom left;
    transition: transform 0.7s ease-out;
}
.select-underline-animation-selected{
    color: black!important;
}

.select-underline-animation-selected::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    color: black!important;
}

.hover-underline-animation-slow {
    display: inline-block;
    position: relative;
    color: #6F6F6F!important;
    font-family: Proxima Nova Regular, sans-serif;
}

.hover-underline-animation-slow::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.7s ease-out;
}
.hover-underline-animation-slow:hover{
    color: black!important;
}

.hover-underline-animation-slow:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    color: black!important;
}