.ml-plus-icon {
  margin: 2px 0 0 1vw;

  //:hover {
  //  font-weight: 800;
  //}
}

.ml-add-album-btn {
  width: 14vw;
  height: 4vh;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0px solid #B8B8B8;
  background: #0E477A;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: -.5vh auto auto 2vw;
  opacity: 1 !important;
}

.mb-album-ops {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.ml-page-num {
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  background: var(--V-Pro-Blue, #0E487A);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FDFDFD;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.mb-album-card {
  width: 275px;
  height: 341px;
  flex-shrink: 0;
  border-radius: 14px;
  border: 0px solid #DFDFDF;
  background: rgba(255, 255, 255, 0.59);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0vh 8vh 6vh 8vh
}

.mb-album-name {
  width: 229px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1rem;
}

.ml-card-divs {
  width: 16rem;
  height: 12rem;
  flex-shrink: 0;
  border-radius: 14px;
  border: 0px solid #B8B8B8;
  background: var(--Yellow, #FFF);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ml-upload-txt {
  display: flex;
  width: 252px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #727272;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.7vw;
  font-style: normal;
  margin-bottom: 0px;
  font-weight: 600;
  line-height: normal;
}

.ml-up-div {
  width: 211px;
  height: 86px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px dashed rgba(90, 90, 90, 0.30);
  background: var(--Yellow, #FFF);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  margin: 10px 0 -14px 0;
}

.ml-up-btn {
  width: 211px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Vieunite-Pro-Blue, #0E477A);
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 1.6rem 0 0 0;
}

.ml-format-txt {
  width: 186.41px;
  flex-shrink: 0;
  color: #676767;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 200% */
}

.ml-albums-p {
  flex-shrink: 0;
  color: #000;
  margin: 6rem 0 2rem 7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 28vw;
  height: 49px;
  font-family: Proxima Nova Regular, sans-serif;
  //font-size: 24px;
  font-size: 1.2vw;
  opacity: 1;
}

.ml-card-divs-title {
  color: #727272;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  //line-height: normal;
  margin-top: -1px;
}

.ml-card-divs-des {
  margin-top: -1.2rem;
  width: 8vw;
  color: #727272;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.ml-card-divs-plus {
  width: 112px;
  height: 124px;
  flex-shrink: 0;
  color: #727272;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ml-p-small-icon {
  width: 3.0625rem;
  flex-shrink: 0;
  color: #585858;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.125rem; /* 340% */
}

.ml-new-album-modal-rename {
  .ant-modal-content {
    width: 40vw;
    height: 300px !important;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Yellow, #FFF);
    margin: 15% 0 0 -30%
  }
}

.ml-new-album-modal {
  .ant-modal-content {
    width: 40vw;
    height: 460px;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Yellow, #FFF);
    margin: 15% 0 0 -30%
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ml-new-album-modal-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px;
  }

  .ml-new-album-modal-txt {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 653px;
    flex-shrink: 0;
    text-align: center;
    margin: auto;
  }

  .ml-new-album-modal-sub-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-shrink: 0;
    margin: 2rem 0 0 0;
  }

  .ml-new-album-modal-input {
    width: 604px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 9px;
    background: #E2E2E2;
    margin: 1rem 0 0 12%;
  }

  .ml-new-album-modal-btn {
    border-radius: 6px;
    background: #0E487A;
    width: 260px;
    height: 56px;
    flex-shrink: 0;
    margin: 2rem 0 0 35%;
    color: var(--Yellow, #FFF);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 16px; // Adjust spacing as needed
    }
  }
}


.custom-table .ant-table-thead {
  display: none;
}

.ant-tag-checkable {
  border-color: lightgray;
  color: gray;
  width: auto;
  margin: 0.5vh 0 0.5vh 0.5vw;

  :hover {
  }
}

.yang-color-span-selected {
  border: 2px black solid;
}

.ant-tag-checkable-checked {
  border-color: black;
  color: black;
  background: white;
}

.ml-new-album-modal-2 {
  .ant-segmented-item-selected {
    background-color: #A06174 !important; /* Custom background color for active tab */
    color: white !important; /* Custom text color for active tab */
  }


  .ant-table {
    width: 40vw;
    height: 50vh;
  }

  .ant-table-tbody {
    width: 40vw;
    height: 20vh;
    flex-shrink: 0;
  }

  .ant-modal-content {
    width: 60vw;
    height: 75vh;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Yellow, #FFF);
    margin: 0 0 0 -50%;

    .ant-modal-body {
      width: 60vw;
    }
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }


  .ml-new-album-modal-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px;
  }

  .ml-new-album-modal-txt {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 653px;
    flex-shrink: 0;
    text-align: center;
    margin: auto;
  }

  .ml-new-album-modal-sub-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-shrink: 0;
    margin: 2rem 0 0 0;
  }

  .ml-new-album-modal-input {
    width: 604px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 9px;
    background: #E2E2E2;
    margin: 1rem 0 0 12%;
  }

  .ml-new-album-modal-btn {
    border-radius: 6px;
    background: #0E487A;
    width: 260px;
    height: 56px;
    flex-shrink: 0;
    margin: 2rem 0 0 35%;
    color: var(--Yellow, #FFF);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 16px; // Adjust spacing as needed
    }
  }
}