.ProAccountDetails1 {
  margin-top: 10rem;
  height: 60rem;
  margin-left: 5rem;
}
.ProAccountDetails-title1 {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: -0.5rem;
}
.DetailsCard {
  width: 475px;
  height: 226px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid var(--Yellow, #FFF);
  background: var(--Yellow, #FFF);
  margin-top: 30px;
  margin-left: 1.5rem;
  margin-right: 2rem;
}
.PasswordCard {
  width: 475px;
  height: 226px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid var(--Yellow, #FFF);
  background: var(--Yellow, #FFF);
  margin-top: 30px;
  margin-left: -5rem;
  margin-right: 2rem;
}
.DetailsCard-sub {
  width: 475px;
  height: 64px;
  flex-shrink: 0;
  background: #C6C6C6;
  box-shadow: 0px -1px 0px 0px #F5F7FA inset;
  color: var(--Black, #000);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 171.429% */
  margin-left: -26px;
  margin-top: -30px ;
  border-radius: 8px;
}
.DetailsCard-content {
  color: var(--Black, #000);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  margin-top: 10px;
  margin-left: -1px;
}
.PasswordCard-content{
  color: var(--Black, #000);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  margin-top: 40px;
  padding-bottom: 20px;
}
.DetailsCard-button {
  width: 428px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: -5px;
}
.Password-button {
  width: 428px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}
.change-profile-settings {
  width: 54.207px;
  height: 54.207px;
  flex-shrink: 0;
  border-radius: 50%;
}
.change-profile-settings-avatar {
  width: 54.207px;
  height: 54.207px;
  flex-shrink: 0;
  border-radius: 50%;
}
.account-pt-profile-div{
  width: 47.082px;
  height: 47.082px;
  flex-shrink: 0;
  border-radius: 50%;
}
.account-pt-profile-div-img{
  width: 47.082px;
  height: 47.082px;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: pointer;
}
.account-pt-profile-div-avatar{
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0E477A;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Proxima Nova bold, sans-serif;
  color: white;
  font-size: 1.2vw;
  cursor: pointer;
  margin: auto;
  margin-top: 2.0px;
}
.account-pt-profile-div-avatar-out{
  width: 47.082px;
  height: 47.082px;
  border-radius: 50%;
  background: white;
  border: #0E487A 4px solid;
}
.header2 {
  display: flex;
  align-items: center; /* 垂直对齐 */
  margin-top: -6rem;
}

.BackButtonIcon3 {
  margin-left: -5rem;
  margin-top: -2rem;
  cursor: pointer;
}
