.site-layout-content {
    height: 500px;
    padding: 24px;
    background: #bf2c2c;
}
/*html, body {*/
/*    overflow-x: hidden;*/
/*}*/
body {
    position: relative
}
/*.logo {*/
/*    float: left;*/
/*    margin: 5px 24px 5px 10px;*/
/*}*/
/*.ant-row-rtl .logo {*/
/*    float: right;*/
/*    margin: 16px 0 16px 24px;*/
/*}*/
[data-theme="dark"] .site-layout-content {
    background: #8dea81;
}

.ant-layout-header{
    background-color: white;
}

.ant-layout-content {
    background-color: white;
}

.ant-layout-footer{
    background-color: white;
}