.sp-btn{
  .ant-btn {
    border: 1px solid #105B63;
    width: 100%;
    border-radius: 8.69px;
    :hover{
      color: #105B63;
    }
  }

  .ant-btn-primary {
    background-color: #105B63;
    padding: 1rem 4rem 1rem 4rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    :hover{
    }
  }
}
.img-crop-modal{
  .ant-modal-content{
    padding: 1.2rem;
    border-radius: 2rem;
  }
  .ant-modal-header {
    border: none;
  }

  .ant-modal-title {
    font-family: Proxima Nova bold, sans-serif;
    font-weight: 700;
    font-size: 3em;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    //padding-bottom: 4rem;

    .ant-btn {
      //border: 1px solid #105B63;
      width: 80%;
      border-radius: 8.69px;
      border: none;
    }

    .ant-btn-primary {
      background-color: #105B63;
    }
  }
}