.pt-profile-div{
  width: 104.207px;
  height: 104.207px;
  flex-shrink: 0;
  border-radius: 50%;
}
.pt-profile-div-img{
  width: 104.207px;
  height: 104.207px;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: pointer;
}
.pt-profile-div-avatar{
  width: 90.207px;
  height: 90.207px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0E477A;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Proxima Nova bold, sans-serif;
  color: white;
  font-size: 1.2vw;
  cursor: pointer;
  margin: auto;
  margin-top: 3px;
}
.pt-profile-div-avatar-out{
  width: 104.207px;
  height: 104.207px;
  border-radius: 50%;
  background: white;
  border: #0E487A 4px solid;
}