.container {
    display: flex;
    width: 100%;
    /* 或者其他指定宽度 */
    height: 100%;
    /* 使容器充满整个可视区域的高度 */
}

.container > div {
    flex: 1;
    /* 让左侧和右侧容器平分容器宽度 */
}

.left img{
 height: 100%;
 width: 100%;
 object-fit: cover;
}
.right img {
    width: 100%;
    /* 让图片充满其容器的宽度 */
    height: 100%;
    /* 让图片充满其容器的高度 */
    object-fit: cover;
    /* 裁切图片以保持纵横比并充满元素 */
}

.right {
    display: flex;
    flex-direction: column;
    margin-left: 2px; /* 添加左侧间距 */
    margin-right: 2px; /* 添加右侧间距 */
  }

.right img {
    flex: 1;
    margin-bottom: 2px; /* 添加下方间距 */
    height: 50%;
  }