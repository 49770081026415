.grey-bk {
    background-color: #D9D9D9;
}

.small-card {
    background-color: white;
    color: white;
    padding: 2rem;
    border-radius: 20px;
    height: 34vh;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 200px);
    /*align-items: center;*/
    /*justify-content: center;*/
    /*text-align: center;*/
}

@media only screen and (max-width: 600px) {
    .small-card {
    }
}

.img-small-card {
    background-color: white;
    color: white;
    padding: 2rem;
    border-radius: 20px;
}

.card-title {
    color: black;
    /*font-size: 2rem;*/
    font-size: 230%;
    font-family: Proxima Nova bold, sans-serif;
    text-align: left;
    margin-bottom: 9.5%;
}