.textura-pro-remind-modal {

  .ant-modal-content {
    width: 80vw;
    height: 30vh;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Yellow, #FFF);
    margin: auto;
    margin-top: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textura-pro-remind-modal-txt{
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 6vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: auto;
  }
  .ant-modal-close-x{
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 16px; // Adjust spacing as needed
    }
  }
}
.textura-pro-title {
  width: 38vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 700;
  //line-height: 140%; /* 2.1rem */
}

.j-h1-add {
  width: 20vw;
  flex-shrink: 0;
  color: var(--Yellow, #FFF);
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: -2vh;
}

.j-txt-add-h {
  width: 26vw;
  flex-shrink: 0;
  color: var(--Yellow, #FFF);
  font-family: Proxima Nova, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
}

.j-txt-add {
  width: 20vw;
  flex-shrink: 0;
  color: var(--Yellow, #FFF);
  font-family: Proxima Nova, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 5px;
}

.textura-pro-txt {
  width: 38vw;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  margin-top: -3%;
}

.textura-pro-top-title {
  width: 24.8125rem;
  height: 3.3125rem;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.m-textura-pro-top-title {
  width: 80vw;
  height: 8vh;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 6vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 2vh 0 0vh 0;
  text-align: center;
}

.textura-pro-top-txt {
  width: 23.1875rem;
  height: 12.0625rem;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin: -4vh 0 6vh 0;
}
.m-textura-pro-top-txt {
  width: 78vw;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin: -2vh 0 4vh 0;
  text-align: center;
}

.pro-buy-now-btn {
  width: 22.8125rem;
  height: 2.95rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #A06174;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.m-pro-buy-now-btn{
  width: 86vw;
  height: 2.95rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #A06174;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 6vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: auto;
}

.textura-pro-contact-div {
  width: 22.75rem;
  height: 11.375rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #0E487A;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 1rem 0;
}
.m-textura-pro-contact-div{
  width: 90vw;
  height: 11.375rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #0E487A;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 3vh;
}

.textura-pro-contact-div-1 {
  color: var(--White, var(--Yellow, #FFF));
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110.435%; /* 1.38044rem */
  width: 14vw;
}

.textura-pro-contact-div-2 {
  color: var(--White, var(--Yellow, #FFF));
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 80.935%;
  text-align: center;
}

.textura-pro-contact-div-3 {
  color: var(--White, var(--Yellow, #FFF));
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3%;
  text-align: center;
}
.m-textura-pro-contact-div-1 {
  color: var(--White, var(--Yellow, #FFF));
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 110.435%; /* 1.38044rem */
  width: 80vw;
}

.m-textura-pro-contact-div-2 {
  color: var(--White, var(--Yellow, #FFF));
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 80.935%;
  text-align: center;
}

.m-textura-pro-contact-div-3 {
  color: var(--White, var(--Yellow, #FFF));
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3%;
  text-align: center;
}
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1; /* Ensure the arrows are above the images */

}

.carousel-prev-arrow {
  left: 10px;
}

.carousel-next-arrow {
  right: 10px;
}

.ant-carousel .slick-dots {
  display: none !important;
}

.slider-img {
  width: 100%;
  border-radius: 0.9375rem;
}

.textura-pro-small-icon-txt {
  width: 10vw;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.textura-pro-icon-and-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15vw;
}

.m-textura-pro-icon-and-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
}
.m-j-img{
  width: 80vw;
}
.m-textura-pro-small-icon-txt{
  width: 40vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: space-around;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4vw;
}

.textura-pro-reviews {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pro-buy-bottom-text {
  width: 22.4885rem;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: auto;
  margin-top: 1rem;
}
.pro-buy-bottom-text-1{
  width: 359px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.pro-buy-bottom-text-2{
  width: 359px;
  margin-top: -1vh;
  flex-shrink: 0;
  text-align: center;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.textura-pro-rv {
  width: 7.75rem;
  height: 1.6875rem;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  //margin: auto;
  margin: 0 0 0 -19px;
  cursor: pointer;
}