.rq-table td {
  border: 1px solid black;
}

@media only screen and (max-width: 600px) {
  .charge-modal {
    .ant-modal-header {
      padding: 0;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      padding: 0;
    }
    .ant-modal-content{
      padding: 2rem;
      width: 100%;
      border-radius: 2rem;
    }
  }
}