.carousel-img {
    height: 800px;
}

.primary-font {
    font-family: "Proxima Nova", sans-serif;
}

@media only screen and (max-width: 768px) {
    .klarna-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.pay-button {
    padding: 6px 32px;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    border: 2px solid black;
    border-radius: 12px;
    font-family: 'Proxima Nova', sans-serif;
    box-shadow: inset 0 0 0 0 #2f1717;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
}

.pay-button > .in-btn-link {
    color: white;
}

.pay-button:hover {
    box-shadow: inset 1080px 0 0 0 #ffffff;
    color: black;
}

.pay-button:hover .in-btn-link {
    color: black;
}

/*.pay-button:hover {*/
/*    background-color: #dd4472;*/
/*    color: #ffffff;*/
/*}*/

.carousel-dt {
    margin-left: -5rem;
}

@media only screen and (max-width: 900px) {
    .carousel-dt {
        margin-left: 0;
    }
}

.color-btn {
    border: #f4f4f4 solid 2px;
    outline: #f4f4f4 solid 2px;
    padding: 20px;
    border-radius: .7em;
    -webkit-border-radius: .7rem !important;
    display: inline-block;
    cursor: pointer;
}

/*.color-btn:focus{*/
/*    outline: black solid 2px;*/
/*    border: white solid 2px;*/
/*    -webkit-appearance:none;*/
/*}*/
.color-btn-active {
    /*-webkit-appearance:none;*/
    outline: black solid 2px;
    border: white solid 2px;
    border-radius: .7em !important;
    -webkit-border-radius: .7rem !important;
}

.black-btn {
    background: url("../../img/Textura/6 - Frame Photos/textura-black.webp");
}

.birch-btn {
    background: url("../../img/Textura/6 - Frame Photos/textura-birch.webp");
}

.pine-btn {
    background: url("../../img/Textura/6 - Frame Photos/textura-pine.webp");
}

.walnut-btn {
    background: url("../../img/Textura/6 - Frame Photos/textura-walnut.webp");
}

.product-title {
    font-size: 24px;
    font-weight: bold;
}

.product-card {
    margin: 30px auto;
}

@media only screen and (max-width: 600px) {
    .product-card {
        margin: -15px auto;
    }
}

/*.active-thumbnail {*/
/*    border: #2f40a5 solid 3px !important;*/
/*}*/

.ant-collapse-content {
    color: #000;
    font-size: 16px;
    font-weight: normal;
}

.ant-collapse {
    font-size: 18px;
    margin: 0 0 4rem;
    font-weight: bold;
    font-family: "Proxima Nova", sans-serif;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    padding: 18px 40px 18px 16px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: white;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: white;
}

.vertical-center-text {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

@media only screen and (min-width: 1460px) {
    .colour-txt {
        margin-left: -10vw;
    }
}

@media only screen and (max-width: 600px) {
    .m-margin-top {
        margin-top: -6rem !important;
    }

    .m-title-small {
        font-size: 34px !important;
    }

    .sales-code {
        padding: 1rem 0 !important;
        margin: 0 0 0 0 !important;
    }

}

@media only screen and (min-width: 600px) {
    .m-only-sale {
        display: none !important;
    }
}

@media only screen and (max-width: 900px) {

    .vertical-center-text {
        margin: 0;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
    }


    .m-padding-tb {
        padding: 2rem 0 !important;
    }

    .m-text {
        text-align: left !important;
        padding: 0 1rem !important;
        justify-content: left !important;
    }

    .right-review-card {
        padding-top: 1.5rem;
    }
}