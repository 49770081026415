.shop-pd-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.shop-pd-name {
  display: flex;
  width: 10.2085rem;
  height: 2.3575rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Proxima Nova semibold, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.shop-order-summary {
  display: flex;
  width: 20.43113rem;
  height: 2.42063rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #8A8A8A;
  font-family: Proxima Proxima Nova semibold, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 0 10rem;
}

.shop-exp-image {
  width: 16vw;
}

.shop-txt-step-2-top-txt {
  color: #000;
  font-family: Proxima Proxima Nova semibold, sans-serif;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  margin: auto;
}

.shop-txt-new-ds-st {
  width: 12vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto auto auto 1vw;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.7vw;
  font-weight: 600;
}

.shop-tc-col {
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.shop-txt-new-ds-btm {
  width: 14vw;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Proxima Nova semibold, sans-serif;
  font-size: 0.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.t-col {
  border-radius: 0.625rem;
  border: 3px solid var(--Vieunite-Pro-Grey-01, #EFEFEF);
  background: var(--Yellow, #FFF);
  //width: 20.30825rem;
  height: 27.53075rem;
  flex-shrink: 0;
  margin: auto;
}

.shop-select-div {
  border-radius: 0.25rem;
  border: 2px solid #E8E8E8;
  background: var(--Yellow, #FFF);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 6.3645rem;
  height: 2.35756rem;
  flex-shrink: 0;
  margin: auto;
  margin-top: -1rem;
}

.shop-select-op {
  width: 1.65169rem;
  height: 1.65169rem;
  flex-shrink: 0;
  border-radius: 0.0625rem;
  border: 2px solid rgba(14, 71, 122, 0.00);
  background: var(--Vieunite-Pro-Blue, #0E477A);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.t-row {
  width: 84vw;
  height: 32.89456rem;
  flex-shrink: 0;
  background: var(--Yellow, #FFF);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-col {
  height: 26vh;
  flex-shrink: 0;
  background: var(--Yellow, #FFF);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //margin-top: 20px;
}

.order-col-right {
  flex-direction: column;
  justify-content: center;
  margin-left: 0.2rem;
}

.shop-txt-total {
  display: flex;
  //width: 9.16213rem;
  //height: 2.3575rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: right;
  font-family: Proxima Nova semibold, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.shop-txt-pay-num {
  display: flex;
  //width: 9.16213rem;
  //height: 2.3575rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova semibold, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: -1.5rem;
}

.shop-sub-txt {
  font-weight: 500;
}

.portal-payment-modal {
  .ant-modal-content {
    border-radius: 11px;
    //margin-top: 10px;
    padding: 15px;
  }

  .ant-modal-title {
    font-size: 24px;
  }

  .ant-modal-footer {
    border: none;
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ant-modal-close-x {
    //display: none;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 16px; // Adjust spacing as needed
    }

    .ant-input {
      border-radius: 4px; // Match the border-radius to the image
    }
  }
}

.shop-accept-tc-checkbox {
  .ant-checkbox {
    top: 0.9em;
  }
}

.shop-txt-step-1-txt {
  width: 15vw;
  height: 39.278px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Proxima Nova bold, sans-serif;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.shop-final-summary-div-1 {
  width: 32vw;
  height: 6vh;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #7F7F7F;
  background: #FFF;
  display: flex;
  flex-direction: column;
  margin: 0.4vh auto 0 auto;

  .shop-final-summary-div-1-span-1 {
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
  }
}

.shop-final-summary-div-2 {
  width: 32vw;
  height: 10vh;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #7F7F7F;
  background: #FFF;
  display: flex;
  flex-direction: column;
  margin: 1vh auto;
}

.shop-final-summary-div-3 {
  width: 32vw;
  height: 4vh;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #7F7F7F;
  background: #E7E7E7;
  display: flex;
  align-items: center;
}

.shop-step-btn {
  width: 7vw;
  height: 3.0625rem;
  flex-shrink: 0;
  border-radius: 0.1875rem;
  background: #A06174;
  color: var(--White, var(--Yellow, #FFF));
  text-align: center;
  font-family: Proxima Proxima Nova bold, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-btn-default {
    width: 7vw;
    height: 3.0625rem;
    flex-shrink: 0;
    border-radius: 0.1875rem;
    //background: #A06174;
    color: gray;
    text-align: center;
    font-family: Proxima Proxima Nova bold, sans-serif;
    font-size: 1vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//.shop-step-btn-span{
//  width: 7vw;
//  height: 3.0625rem;
//  flex-shrink: 0;
//  border-radius: 0.1875rem;
//  background: #A06174;
//  color: var(--White, var(--Yellow, #FFF));
//  text-align: center;
//  font-family: Proxima Proxima Nova bold, sans-serif;
//  font-size: 1vw;
//  font-style: normal;
//  font-weight: 700;
//  line-height: normal;
//}

.shop-step-row {
  display: flex;
  align-items: center;
  width: 85%;
  justify-content: space-between;
  margin: 2rem auto 2rem auto;
}

.shop-txt-j-f {
  font-family: Proxima Proxima Nova bold, sans-serif;
  font-size: 0.8vw;
  font-weight: 700;
  margin: -5vh 0 2vh 0.8vw;
}

.shop-sub-opt-btn {
  border-radius: 5px;
  background: #0E487A;
  width: 14vw;
  height: 5.6vh;
  flex-shrink: 0;
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Proxima Nova bold, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  margin: 2.8vh auto 0 auto;

  :active {
    border-radius: 0.9375rem;
    background: #A06174 !important;
    color: var(--Yellow, #FFF) !important;
  }

  :focus {
    border-radius: 0.9375rem;
    background: #A06174 !important;
    color: var(--Yellow, #FFF) !important;
  }
}

.cross-btn {
  display: flex;
  width: 2.2385rem;
  height: 2.42063rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #8A8A8A;
  background-color: lightgray;
  text-align: center;
  font-family: Proxima Nova bold, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 6rem;
  //width: 20px; /* Adjust the width as needed */
  //height: 20px; /* Adjust the height as needed */
  border-radius: 50%;
}

.sp-image-container {
  position: relative;
  display: inline-block;
}

.sp-image-container img {
  display: block;
  width: 100%;
}

.clickable-area-right, .clickable-area-left {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  cursor: pointer;
}

.clickable-area-right {
  right: 0;
}

.clickable-area-left {
  left: 0;
}
