.curated-txt{
  width: 65vw;
  flex-shrink: 0;
  color: black;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.2vw;
  font-weight: 400;
  //line-height: normal;
  line-height: 3.3vh;
}
.m-curated-txt{
  width: 70vw;
  flex-shrink: 0;
  color: black;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4vw;
  font-weight: 400;
  //line-height: normal;
  line-height: 3.3vh;
}

.dsa-btn{
  width: 332.3px;
  height: 73.907px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #A06174;
  background: #A06174;
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova bold, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.m-dsa-btn{
  width: 50vw;
  height: 4vh;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #A06174;
  background: #A06174;
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova bold, sans-serif;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.m-curated-h1 {
  width: 80vw;
  flex-shrink: 0;
  color: black;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 6vw;
  font-style: normal;
  font-weight: 700;
  line-height: 126.8%; /* 40.576px */
  margin-top: 4vh;
  text-align: center;
}

.m-curated-p {
  width: 80vw;
  flex-shrink: 0;
  color: black;
  font-family: Proxima Nova Regular,sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126.8%;
  margin-top: -2vh;
  text-align: center;
}

.curated-image-overlay-container {
  position: relative;
  text-align: left; // Align text to the left as per your screenshot
  width: 90%; // Ensure the container spans the full width
  display: flex;
  align-items: center;
  justify-content: center;

  .curated-overlay-image {
    width: 90%;
    display: block; // Ensure there's no space under the image
  }

  .curated-text-overlay {
    position: absolute;
    top: 15%; // Adjust as necessary for your layout
    right: 0; // Adjust as necessary for your layout
    color: #FFF; // Change the color based on your design needs
    width: 40%; // Change the width based on your design needs

    .curated-h1 {
      width: 30vw;
      flex-shrink: 0;
      color: #454545;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 1.6vw;
      font-style: normal;
      font-weight: 700;
      line-height: 126.8%; /* 40.576px */
    }

    .curated-p {
      width: 30vw;
      flex-shrink: 0;
      color: #454545;
      font-family: Proxima Nova Regular,sans-serif;
      font-size: 1.1vw;
      font-style: normal;
      font-weight: 400;
      line-height: 126.8%;
      margin-top: -2vh;
    }


    // Style other elements like buttons here if necessary
  }

  .curated-text-overlay2 {
    position: absolute;
    top: 13%; // Adjust as necessary for your layout
    right: 0; // Adjust as necessary for your layout
    color: #FFF; // Change the color based on your design needs
    width: 40%; // Change the width based on your design needs

    .curated-h2 {
      width: 30vw;
      flex-shrink: 0;
      color: #454545;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 1.6vw;
      font-style: normal;
      font-weight: 700;
      line-height: 126.8%; /* 40.576px */
    }

    .curated-p2 {
      width: 30vw;
      flex-shrink: 0;
      color: #454545;
      font-family: Proxima Nova Regular,sans-serif;
      font-size: 0.9vw;
      font-style: normal;
      font-weight: 400;
      line-height: 126.8%;
      margin-top: -2vh;
    }


    // Style other elements like buttons here if necessary
  }
  .curated-text-overlay4 {
    position: absolute;
    top: 13%; // Adjust as necessary for your layout
    right: 0; // Adjust as necessary for your layout
    color: #FFF; // Change the color based on your design needs
    width: 40%; // Change the width based on your design needs

    .curated-h4 {
      width: 30vw;
      flex-shrink: 0;
      color: #454545;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 1.6vw;
      font-style: normal;
      font-weight: 700;
      line-height: 126.8%; /* 40.576px */
    }

    .curated-p4 {
      width: 30vw;
      flex-shrink: 0;
      color: #454545;
      font-family: Proxima Nova Regular,sans-serif;
      font-size: 1.1vw;
      font-style: normal;
      font-weight: 400;
      line-height: 126.8%;
      margin-top: -2vh;
    }


    // Style other elements like buttons here if necessary
  }
  .curated-text-overlay5 {
    position: absolute;
    top: 15%; // Adjust as necessary for your layout
    right: 0; // Adjust as necessary for your layout
    color: #FFF; // Change the color based on your design needs
    width: 40%; // Change the width based on your design needs

    .curated-h5 {
      width: 30vw;
      flex-shrink: 0;
      color: #454545;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 1.6vw;
      font-style: normal;
      font-weight: 700;
      line-height: 126.8%; /* 40.576px */
    }

    .curated-p5 {
      width: 30vw;
      flex-shrink: 0;
      color: #454545;
      font-family: Proxima Nova Regular,sans-serif;
      font-size: 1.0vw;
      font-style: normal;
      font-weight: 400;
      line-height: 126.8%;
      margin-top: -2vh;
    }


    // Style other elements like buttons here if necessary
  }
  .curated-overlay-left{
    left: 18%;

  }
}