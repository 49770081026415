.signup-container {
  margin-top: 500px;
  max-width: 600px; /* 调整为适合您设计的宽度 */
  padding: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 可选的阴影效果 */
}

.signup-container h1 {
  text-align: center;
  margin-bottom: 40px; /* 调整间距 */
}
.jack-custom-input{
  border-radius: 8px;
  background-color: #D9D9D9;
  .ant-input{
    background-color: #D9D9D9;
  }
}
.remember-text {
  color: #000;
  font-family: Proxima Nova;
  font-size: 12px;
  margin-left: 20.83%;
}

.required-field-text {
  color: #000;
  font-family: Proxima Nova;
  font-size: 12px;
  margin-left: 20.83%;
}

.center-button {
  text-align: center;
}
.custom-button {
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 9px;
  border: 0px solid var(--Yellow, #FFF);
  background: var(--Vieunite-Pro-Mauve, #A06174);
  width: 252.406px;
  height: 59px;
  flex-shrink: 0;
}
.custom-input {
  border-radius: 8px;
  background: #D9D9D9;
}
.custom-select {
  border-radius: 8px !important;
  background: #D9D9D9 !important;
  .ant-select-selector{
    background-color: #D9D9D9!important;
    border-radius: 8px !important;
  }
}
.custom-placeholder::placeholder {
  font-family: Proxima Nova;
  font-size: 12px;
}
