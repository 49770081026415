.PortalContact-container {
  margin-top: 100px;
  margin-left: 120px;
  height:800px;
}
.PortalPhone-Title {
  font-family: 'Proxima Nova bold', sans-serif !important;
  font-size: 32px;
  font-weight: 600;
  color: #333;
  line-height: 1.5;
}
.PortalPhone-Paragragh {
  max-width: 100% !important;
  color: #000;
  font-family: 'Proxima Nova', sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}
.PortalPhone-button {
  padding: 6px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  border: 2px solid black;
  border-radius: 12px;
  font-family: 'Proxima Nova', sans-serif;
  box-shadow: inset 0 0 0 0 #2f1717;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
.PortalEmail-Title {
  font-family: 'Proxima Nova bold', sans-serif !important;
  font-size: 32px;
  font-weight: 600;
  color: #333;
  line-height: 1.5;
}
.PortalEmail-Paragragh {
  max-width: 100% !important;
  color: #000;
  font-family: 'Proxima Nova', sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}
.PortalEmail-button {
  //padding: 6px 32px;
  //text-align: center;
  //text-decoration: none;
  //display: flex;
  //justify-content: center;
  //width: 100%;
  //font-size: 16px;
  //margin: 4px 2px;
  //transition-duration: 0.4s;
  //cursor: pointer;
  //background-color: #000000;
  //color: #ffffff;
  //border: 2px solid black;
  //border-radius: 12px;
  //font-family: 'Proxima Nova', sans-serif;
  //box-shadow: inset 0 0 0 0 #2f1717;
  //-webkit-transition: ease-out 0.4s;
  //-moz-transition: ease-out 0.4s;
  //transition: ease-out 0.4s;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);color: #FFF;
  text-align: center;
  font-family: 'Proxima Nova', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 127px;
}