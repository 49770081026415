.upload_cropper_modal {
  .ant-modal-content {
    padding: 2rem 0 2rem 0;
    border-radius: 2rem;
    //width: 150%;
  }

  .ant-modal-header {
    border: none;
    border-radius: 2rem;
  }

  .ant-modal-title {
    font-family: Proxima Nova bold, sans-serif;
    font-weight: 700;
    font-size: 2.6em;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    gap: 2rem;
    //padding-bottom: 4rem;

    .ant-btn {
      border: 1px solid #105B63;
      width: 10rem;
      border-radius: 8.69px;
    }

    .ant-btn-primary {
      background-color: #105B63;
    }
  }
}

.ratio-row {
  .ant-btn {
    border: 1px solid #105B63;
    width: 8rem;
    border-radius: 8.69px;
  }
}

.wall-up {
  .ant-upload-list-picture-card-container {
    width: 23rem;
    height: 23rem;
  }

  .ant-upload-select-picture-card {
    width: 23rem;
    height: 23rem;
  }

  .anticon anticon-delete {
    width: 10rem;
  }
  .anticon-eye{
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .wall-up {

  }
}