.example-section-image-jack {
  display: block;
  //width: 100%; /* Ensure the image fills its container */
  width: 25vw;
  height: auto;
  border-radius: 23px;
}

.homeTexturaExampleContainer-jack {
  position: relative;
  //top: 0;
  width: 86vw;
  overflow: hidden;
  background-color: black;
  //min-width: 950px;
  overflow: clip;
  border-radius: 1.125rem;
  margin: 3vw auto 3vw 8.8vw;
  padding: 3vw 2vw 3vw 3vw;
}

.example-section-container-jack {
  overflow: hidden; /* Ensure the slider doesn't show images outside the visible area */
  width: 80vw; /* Full width of the container */
  position: relative;
}

.example-section-slider-jack {
  display: flex;
  transition: transform 0.5s ease; /* Smooth transition */
  width: 80vw; /* Multiply by the total number of images */
}

//.example-section-image-container-jack {
//  flex: 0 0 33.33%; /* Each image takes up 1/3 of the viewport width */
//  box-sizing: border-box;
//  padding: 0.625rem;
//}
//.example-section-image-container-jack:hover {
//  transform: scale(1.08);
//}
.example-section-image-container-jack {
  flex: 0 0 33.33%; /* Each image takes up 1/3 of the viewport width */
  box-sizing: border-box;
  padding: 0.625rem;
  transition: transform .6s ease; /* 添加过渡效果 */
  transform: scale(1); /* 初始无缩放 */
}
.example-section-image-container-jack:hover {
  transform: scale(1.02);
}





//.custom-modal .ant-modal-content {
//  background-color: #f0f0f0; /* Light gray background as in your design */
//}
//
//.modal-directions {
//  padding-right: 10px; /* Adjust padding to ensure proper spacing */
//}
//
//.modal-map iframe {
//  border-radius: 8px; /* Rounded corners for the map if desired */
//}
//
///* Adjust further for responsiveness and alignment */

.custom-modal-Jack {
  width: 20vw;
  height: 7vh;
  flex-shrink: 0;
  border-radius: 50px;
}
.MapTexDic {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 3rem;
}
.MapTexDicContent {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.modal-map-Jack {
  width: 3vw;
  height: 5vh;
  border-radius: 23px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.MapTitle {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1rem;
}
.MapAddress {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.MapCity {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.MapPostcode {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 3rem;
}
.MapButton {
  color: #000;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 19vw;
  height: 4.5vh;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 10px;
  border-color: #000;
  background-color: white; /* 确保默认背景色为白色 */
  border: 1px solid black; /* 设置边框颜色 */
  padding-top: 0.1rem;
}
.MapButton:hover {
  background-color: black; /* 当 hover 时，背景变为黑色 */
  color: white; /* 文字变为白色，以确保对比度 */
  cursor: pointer; /* 鼠标变为 pointer，提升用户体验 */
}
.MapButtonText {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  padding-top: 0.3rem;
}
.custom-modal-Jack .ant-modal-content {
  border-radius: 22px; /* Adjust this value as needed */
}
@media only screen and (max-width: 920px){
  //.homeTexturaExampleContainer-jack {
  //  position: relative;
  //  //top: 0;
  //  width: 86vw;
  //  overflow: hidden;
  //  background-color: black;
  //  //min-width: 950px;
  //  overflow: clip;
  //  border-radius: 1.125rem;
  //  margin: 3vw 3rem;
  //  padding: 3vw 2vw 3vw 3vw;
  //}
  .example-section-image-container-jack {
    flex: 0 0 100%; /* Each image takes up 1/3 of the viewport width */
    box-sizing: border-box;
    padding: 0.625rem;
    transition: transform .6s ease; /* 添加过渡效果 */
    transform: scale(1); /* 初始无缩放 */
  }
  .example-section-image-container-jack:hover {
    transform: scale(1.02);
  }
  .example-section-image-jack {
    display: block;
    //width: 100%; /* Ensure the image fills its container */
    width: 100%;
    height: auto;
    border-radius: 23px;
  }
}