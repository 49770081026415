.ProSignIn-center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}




.custom-Login-button {
  border-color: #0E477A;
  text-align: center;
  font-size: 1rem;
  font-style: normal;

    width: 100%;
    background-color: #0E477A;
    color: white;
    border-radius: 8.69px;
    font-family: "Proxima Nova bold", sans-serif;
    font-weight: bold;
    height: 2.6rem;


  :hover{
    //color: white!important;
    //background-color: black!important;
    //border-color: #105B63;
  }
}
.forgot-password-link a {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  margin-right: calc(100% - 53vw - 252.406px);
  text-decoration: underline;
  color: #11142D;
  cursor: pointer;
  margin-bottom: 80px;
}

