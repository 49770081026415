.pro-box {
  display: flex;
  flex-direction: row-reverse;
  width: 70vw !important;
  background: white !important;
  justify-content: center;
  height: 60vh;

  .carousel-container {
    width: 60vw !important;
    height: 60vh !important;

    .carousel-item {
      height: 60vh !important;
      max-height: 60vh !important;
    }
  }

  .pro-carousel-arr {
    cursor: pointer;
  }

  .thumbnails {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    width: 10vw;
    height: 54vh !important;
    margin: 0 0 5px 0;
    padding: 10px 0;
    user-select: none;
  }

  .thumbnail {
    width: 5rem !important;
    height: 5rem;
    border-radius: 0.625em;
    margin: -1vh auto;
    padding: 0;
  }
}

@media only screen and (max-width: 900px) {
  .pro-box {
    display: flex;
    margin-top: 4rem;
    flex-direction: column;
    width: 100vw !important;
    max-width:100vw !important;
    background: white !important;
    justify-content: center;
    //align-items: center;
    .carousel-container {
      width: 100vw !important;
      max-width: 100vw !important;
      height: 40vh !important;
      max-height: 40vh !important;
      display: flex;
      align-items: center;
      justify-content: center;

      .carousel-item {
        height: 40vh !important;
        max-height: 40vh !important;
        max-width: 100vw!important;
      }
    }

    .pro-carousel-arr {
      cursor: pointer;
    }
    .pro-carousel-thumbnails-row{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100vw!important;
      max-width: 100vw!important;
    }

    .thumbnails {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: scroll;
      width: 80vw!important;
      max-width: 80vw!important;
      height: 10vh !important;
      margin: 0 0 5px 0;
      padding: 10px 0;
      user-select: none;
    }

    .thumbnail {
      width: 5rem !important;
      height: 5rem;
      border-radius: 0.625em;
      margin: auto 10px;
      padding: 0;
    }
  }
}

