.artist-image-modal {
  /* Custom CSS for Ant Design Modal Close Icon */
  .ant-modal-close {
    display: none;
    font-size: 16px; /* Adjust the size as needed */
    top: -1vh;
    right: -1.6vh;
  }

  .ant-modal-close-x {
    display: none;
    font-size: 16px; /* Adjust the size of the close icon */
  }
}

.m-artist-website {
  display: none;
}


.shine {
  position: relative;
  overflow: hidden;

  &::before {
    background: linear-gradient(
                    to right,
                    fade_out(#fff, 1) 0%,
                    fade_out(#fff, 0.5) 100%
    );
    content: "";
    display: block;
    height: 100%;
    left: -75%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 50%;
    z-index: 2;
  }

  &:hover,
  &:focus {
    &::before {
      animation: shine 0.85s;
    }
  }

  @keyframes shine {
    100% {
      left: 125%;
    }
  }
}


@media only screen and (max-width: 920px) {
  .m-artist-website {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 2rem;
  }
  .artist-image-container {
    width: 80vw;
    height: 80vw;
  }
  .artist-img-hover {
    width: 80vw !important;
    height: 80vw !important;
  }
  .artist-img-avatar {
    width: 30%;
  }
  .artist-bio-m {
    width: 80vw !important;
  }
  .new-normal-btn {
    font-size: 1rem;
    width: 86vw;
  }

}