.sales-modal {
  .ant-modal-header {
    padding: 0;
  }

  .ant-modal-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ant-modal-footer {
    padding: 0;
  }
  .ant-modal-content{
    padding: 0;
    width: auto;
    border-radius: 48px;
  }
}
.sales-img{
  max-width: 100%;
  max-height: 100%;
}
