.product-detail-container {
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
    padding: 20px;
    /* min-width: 610px; */
    width: 710px;
    /* width: 50%; */
    /* height: 480px; */
    flex-shrink: 0;
    border-radius: 20px;
    border: 0px solid #B6B6B6;
    background: var(--Yellow, #FFF);
}

.author-detail-container {
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
    padding: 20px;
    padding-left: 37px;
    padding-right: 37px;
    /* width: 700px; */
    /* width: 50%; */
    min-width: 600px;
    margin-top: 30px;
}

.artwork-info-description-text-section {
    /* min-height: 360px; */
    overflow: "hidden";
}

.product-image {
    /* 图片的样式 */
    display: flex;
    /* width: 35%; */
    width: 200px;
    justify-content: center;
    align-items: flex-start;
}

.author-details-author-avatar {
    /* 图片的样式 */
    display: flex;
    /* width: 2500px; */
    justify-content: center;
    align-items: flex-start;
}

.artwork-info-description-section {
    /* 文本和按钮的样式 */
    width: 350px;
    /* min-height: 540px; */
    margin-left: 20px;
}

.product-info {
    /* 文本和按钮的样式 */
    margin-left: 20px;
}

.author-details-product-title {
    color: var(--DarkPurple, #11142D);
    font-family: Proxima Nova Regular, sans-serif;
    font-size: calc(1.3rem + 0.6vw);
    /* font-size: 40px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
    margin-right: 5px;
    word-wrap: break-word;
    /* width: 100px; */
}

.author-details-description-title {
    color: var(--DarkPurple, #11142D);
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 0px;
    line-height: 1.5;
    /* 142.857% */
}

.author-details-description-body {
    color: var(--DarkPurple, #11142D);
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.author-details-price-tag {
    color: var(--DarkPurple, #11142D);
    font-family: Proxima Nova Regular, sans-serif;
    font-size: calc(1.275rem + 0.3vw);
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.membership-promo {
    color: var(--DarkPurple, #11142D);
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
}

.membership-link {
    text-decoration-line: underline;
}

.button-preview,
.button-add {
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    background: var(--V-Pro-Blue, #0E487A);
    width: 354px;
    height: 45px;
    color: var(--Yellow, #FFF);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: normal;

    flex-shrink: 0;
    /* background: var(--Vieunite-Pro-Blue, #0E477A); */
    /* color: #FFF; */
    text-align: center;
}

.button-tag {
    border-radius: 5px;
    /* background: var(--V-Pro-Blue, #0E487A); */
    /* color: var(--Yellow, #FFF); */
    color: var(--V-Pro-Blue, #0E487A);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* width: 115px; */
    height: 30px;
    flex-shrink: 0;
    margin-right: 10px;
    margin-top: 5px;
}

.artist-bio-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    /* font-size: 12px; */
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 1.5;
    cursor: pointer;
    /* 183.333% */
}

.artist-bio-body {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 22px;
}

.product-description-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.navigation-bar-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 30px;
    margin-left: 30px;
    margin-bottom: -5px;
    opacity: 1;

    display: flex;
    /* flex-direction: column; */
    /* justify-content: ; */
    flex-shrink: 0;
}