.rainbowContainer {
    position: relative;
    width: 100vw;
    /*height: 100vh;*/
    overflow: clip;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 30%, rgba(0, 0, 0, 0.13) 100%), #C2D9D6;
}

.rainbowContainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    /*height: 100vh;*/
    background-color: transparent;
    justify-content: flex-start;
    align-items: center;
    /*padding: 30px;*/
    overflow: hidden;
}

.spacer {
    flex: 1;
}

.rainbowTitleText {
    font-family: Proxima Nova Regular, sans-serif;
    font-weight: 700;
    font-size: 3rem;
    line-height: normal;
    text-align: center;
}

.rainbowBodyText {
    font-family: Proxima Nova Regular, sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: normal;
    text-align: center;
    margin: 0 8vw;
}

.rainbowBodyImg2 {
    /*flex: 1;*/
    /*max-width: 100%;*/
    /*max-height: 70%;*/
    width: 270px;
    height: auto;
    /*padding: 0 4rem 4rem 4rem;*/
    padding: 1rem 0;
    object-fit: contain;
}