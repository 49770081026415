.pro-homeVideoTextContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 10vh;
  background-color: transparent;
  justify-content: center;
  align-items: flex-start;
  color: white;
  margin: 60vh auto auto 10vw;
}

.pro-homeVideoTextContainer p {
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.2;
  text-align: left;
  align-content: flex-start;
}
.pro-case-p{
  color: black;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pro-case-p-gold{
  color: #936846;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media only screen and (max-width: 900px){
  .m-pro-case-study-row{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
