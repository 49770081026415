.Title {
  color: var(--DarkPurple, #11142D);
  text-align: center;
  font-family: Proxima Nova;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
  margin-top: 200px;
}

.ProNewPassword-input-Email-form {
  width: 640.52px;
  height: 43.871px;
  //background-color: #D9D9D9;
  background-color: transparent; /* 修改此处 */
  border-radius: 8px;
}
.ProNewPassword-email-label {
  font-family: Proxima Nova;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}
.ProNewPassword-center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.ProNewPassword-custom-Reset-button {
  background-color: #A06174;
  border-color: #A06174;
  text-align: center;
  border-radius: 9px;
  width: 252.406px;
  height: 59px;
  font-size: 24px;
  font-style: normal;
  margin-bottom: 80px;
}
.ProPassword-text {
  color: #000;
  text-align: center;
  font-family: Proxima Nova;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 28.6px */
}



