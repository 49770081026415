.upgrade-summary-order-div{
  width: 519px;
  height: 388px;
  flex-shrink: 0;
  border-radius: 13px;
  background: var(--Yellow, #FFF);
  padding-top: 2rem;
}

.upgrade-summary-order-title{
  width: 503px;
  flex-shrink: 0;
  color: var(--Black, #000);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.upgrade-summary-order-btn{
  width: 407px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 2rem 0 2rem;
}

.upgrade-summary-order-my-bag{
  width: 171px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 248%;
  margin: 0 0 0 2rem;
}

.upgrade-summary-order-total{
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 248%;
}

.upgrade-summary-title-p{
  //width: 671px;
  //height: 43px;
  flex-shrink: 0;
  color: #000;;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.75;
}

.upgrade-summary-title-p-item {
  width: 113px;
  height: 41px;
  flex-shrink: 0;
  color: #000;
  text-align: right;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 248%; /* 44.64px */
  margin-left: 33vw;
}

.upgrade-summary-divider{
  width: 40vw;
  height: 1px;
  margin-top: 10%;
  background: #D0D0D0;
  color: #D0D0D0;
  margin-left: -3vw;
}

.upgrade-summary-txt{
  width: 30vw;
  flex-shrink: 0;
  color: var(--Black, #000);
  font-family: Proxima Nova, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2vw; /* 171.429% */
  margin-left: -4vw;
}

.upgrade-summary-delete-btn{
   margin-left: 34vw;
   margin-top: -10vh;
}