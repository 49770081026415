.artwork-card-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    /* font-family: Proxima Nova; */
    /* font-size: 20px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* margin: -15px -10px 5px; */
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: -15px;
    margin-right: -15px;
    /* max-height: 1.5rem; */
    /* overflow: hidden; */

    /* font-size: 20px; */
    /* margin: -15px -10px 5px; */
    /* max-height: 25px; */
    /* overflow: hidden; */
    font-size: 1.25rem;
}

.artwork-card-author {
    color: var(--V-Pro-Blue, #0E487A);
    /* font-family: Proxima Nova; */
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 2px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 0.25em;
}

.artwork-card-price {
    color: var(--V-Pro-Blue, #0E487A);
    /* font-family: Proxima Nova; */
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin: 2px;
}

.artwork-card-price-description {
    color: var(--V-Pro-Blue, #0E487A);
    /* font-family: Proxima Nova; */
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.artwork-card-subscription-description {
    text-align: center;
    color: var(--V-Pro-Blue, #0E487A);
    /* font-family: Proxima Nova; */
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 1;
    margin-bottom: 20px;
    margin-top: -5px;
}

.artwork-card-subscription-type {
    color: var(--V-Pro-Blue, #0E487A);
    /* font-family: Proxima Nova; */
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    line-height: 1;
    margin: 2px;
}