.pro-bg-color {
  background-color: #F4F4F4;
}
.m-no{

}
.m-yes{
  display: none;
}

.m-overlay-content {
  width: 100vw;
  position: absolute;
  top: 68%;
  transform: translateY(-10%); // Centers the content vertically
  color: white; // Text color
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;

  .m-pro-h1 {
    width: 90vw;
    flex-shrink: 0;
    color: white;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 7vw;
    font-style: normal;
    font-weight: 700;
    line-height: 115.8%; /* 74.112px */
    margin: auto auto;
  }

  .m-overlay-subtext {
    width: 90vw;
    flex-shrink: 0;
    color: white;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 2vh auto;
  }

  .m-learn-more-button {
    width: 34vw;
    height: 4vh;
    flex-shrink: 0;
    border-radius: 8px;
    border: 0px solid #0E487A;
    background: #A06174;
    color: var(--Yellow, #FFF);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pro-home-txt {
  color: #5B5B5B;
  font-family: Proxima Nova, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 164.3%; /* 2.05375rem */
}
.pro-home-txt-new{
  width: 70vw;
  //height: 350px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  //line-height: 164.3%; /* 39.432px */
}

.learn-more-btn-home {
  width: 13.71975rem;
  height: 3.59031rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 0px solid #0E487A;
  background: #A06174;
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.image-overlay-container {
  position: relative;
  text-align: right; // Align text to the right

  .overlay-content {
    position: absolute;
    top: 48%;
    right: 5%; // Adjust the position as needed
    transform: translateY(-50%); // Centers the content vertically
    color: white; // Text color
    z-index: 3;

    .pro-h1 {
      width: 21vw;
      flex-shrink: 0;
      color: #000;
      text-align: right;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 2.8vw;
      font-style: normal;
      font-weight: 500;
      line-height: 115.8%; /* 74.112px */
      margin: 4vh 0.1vw 4vh auto;
    }

    .pro-h2 {
      width: 22vw;
      flex-shrink: 0;
      color: #000;
      font-family:  sans-serif;
      font-size: 2.8vw;
      font-weight: 200;
      opacity: 0.8;
      line-height: 115.8%;
      margin-right: 0.1vw;
      margin-left: auto;
      font-style: normal;
    }

    .overlay-subtext {
      width: 18vw;
      flex-shrink: 0;
      color: #000;
      text-align: right;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 1vw;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
      margin: -1.6vh 0 4rem 4.9rem;
    }

    .learn-more-button {
      width: 12vw;
      height: 6vh;
      flex-shrink: 0;
      border-radius: 8px;
      border: 0px solid #0E487A;
      background: #A06174;
      color: var(--Yellow, #FFF);
      text-align: center;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 1.4vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: -3.8vh;
    }
  }
}

.pro-btn-hover-to-white {
  &:not(:disabled):hover {
    /*background-color: #582859 !important;*/
    /*color: #dd4472 !important;*/
    background: white !important;
    color: black !important;
    //border-color: #dd4472 !important;
    //border:none;
  }

  &:focus {
    background: white !important;
    color: black !important;
  }
}

.j-section {
  position: relative;
  margin-bottom: 2rem; // Space between sections
  .j-img {
    width: 80vw;
    display: block; // Ensures there's no space under the image
    margin: 4vh auto;
  }

  .j-text-overlay {
    position: absolute;
    top: 13%; // Adjust based on your design
    left: 14%; // Adjust based on your design
    color: #FFF; // Adjust text color based on your design
    width: 30%; // Adjust width based on your design

    .j-white-line {
      width: 230%; // Adjust the width as per your design requirement
      height: 2px; // Adjust the thickness of the line as needed
      background-color: #FFF; // White color for the line
      margin-bottom: 1rem; // Space between the line and the title

    }

    .j-h1 {
      width: 38vw;
      height: 38px;
      flex-shrink: 0;
      color: var(--Yellow, #FFF);
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 1.5vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .j-txt {
      width: 24vw;
      height: 147.813px;
      flex-shrink: 0;
      color: var(--Yellow, #FFF);
      font-family: Proxima Nova, sans-serif;
      font-size: 1vw;
      font-style: normal;
      font-weight: 600;
      line-height: 136%; /* 27.2px */
    }

    .j-overlay-button {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 10vw;
      height: 5vh;
      flex-shrink: 0;
      border-radius: 8px;
      border: 0px solid #0E487A;
      background: #A06174;
      color: var(--Yellow, #FFF);
      text-align: center;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 1.2vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 8vh;
    }
  }
}

.home-pro-title-section {
  display: flex;
  flex-direction: row;
  //background-color: red;
  width: 100%;

  .text-section {
    flex: 1;
    margin: 0 4.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    //background-color: red;
  }
}

.home-pro-title1 {
  color: #936846;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.home-pro-title2 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  //max-width: 38rem;
}

.home-pro-title3 {
  max-width: 25rem;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.home-pro-tab-text {
  user-select: none;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.home-pro-tab-text-selected {
  user-select: none;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.home-pro-tab-container {
  flex: 1;
  cursor: pointer;
  height: 100%;
  margin: 0 6%;
}

.tab-underline-animation {
  display: inline-block;
  position: relative;
  color: #06467A!important;
  font-family: Proxima Nova Regular, sans-serif;
}

.tab-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 7px;
  bottom: 0;
  left: 0;
  background-color: #06467A;
  transform-origin: bottom left;
  transition: transform 0.2s ease-out;
}

.tab-underline-animation-selected{
  color: #06467A!important;
}

.tab-underline-animation-selected::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  color: #06467A!important;
}

.tab-vertical-line {
  border-left: 1px solid #A9A9A9;
  height: 5.25rem;
}

.grid-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home-pro-section-image {
  flex: 1;
  min-height: 40vw;
  //height: 100%;
  width: 40vw;
  object-fit: cover;
  border-radius: 15px;
  //overflow: hidden;
}

.home-pro-fade-out {
  opacity: 0;
  transition: opacity 0.1s ease-out;
}

.home-pro-fade-in {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

.home-pro-description {
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(180deg, #06467A 0%, #7A063B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 40rem;
}
.home-pro-description::selection {
  //color: black;
  -webkit-text-fill-color: black;
}


.home-pro-description-mobile {
  font-size: 2.5rem;
  margin: 0;
}

.home-pro-section-3-card-title {
  width: 100%;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  height: 6.5rem;
}

.home-pro-section-3-card-title-mobile {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-pro-section-3-card-body {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.home-pro-section-3-card-body-mobile {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home-pro-section-3-card-link {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  cursor:pointer;
}

.home-pro-section-3-card {
  width: 33%;
  padding: 1%;
  direction: ltr;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}

.home-pro-section-3-card-mobile {
  direction: ltr;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 18rem;
  padding: 2.5%;
  flex-shrink: 0;
}

.home-pro-section-4-mobile-title {
  color: #000;
  text-align: center;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0.5rem 3rem;

}

.home-pro-section-4-mobile-body {
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0.5rem 3rem;
}

.home-pro-section-4-text-container {
  margin-right: 4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Proxima Nova Regular, sans-serif;
  cursor: default;

  .collapsible {
    height: 90px; /* initial height */
    overflow: hidden;
    transition: height .4s ease, opacity .4s ease;
    opacity: 1;
    //margin-left: 1.4rem;
  }

  .collapsible.collapsed {
    height: 0;
    opacity: 0;
  }

  .title1 {
    color: #000;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: default;
  }
  .body {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .title2 {
    color: #AF8462;
    margin: 1vw 0;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .line {
    height: 1px;
    width: 100%;
    background: #A9A9A9;
    margin: 0;
  }
  .body2 {
    margin: 1vw 0;
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
}

.home-pro-mobile-title-selected {
  color: #AF8462;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-pro-mobile-body {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home_pro_section5_body {
  color: #FFF;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;

}

.home_pro_section5_body_desktop {
  width: 25vw;
}

.home_pro_section5_title {
  color: #FFF;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  //width: 25vw;
}

.home-pro-mobile-carousel-control-text {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.84px;
  margin: 0;
  padding: 0 10px
}

.home-pro-author-name {
  padding: 0 20px;
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 1033px) {
  .home-pro-section-3-card {
    width: 50%;
  }
  .home-pro-section-4-text-container {
    .collapsible {
      height: 95px; /* initial height */
    }
  }
}

@media only screen and (max-width: 920px) {
  .m-no{
    display: none;
  }
  .m-yes{
    display: flex;
  }
  .m-learn-more-button{
    display: flex;
  }
  .m-pro-home-btn{
    border-radius: 8px;
    border: 0px solid #0E487A;
    background: #A06174;
    width: 32vw;
    height: 4.6vh;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 4vh 0;
  }
  .m-pro-home-title{
    width: 60vw;
    height: 4vh;
    flex-shrink: 0;
    color: var(--Black, #000);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 6vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 4vh auto;
  }
  .m-pro-home-txt{
    width: 80vw;
    flex-shrink: 0;
    color: var(--Black, #000);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    margin: auto;
  }
}

@media only screen and (max-width: 720px) {
  .home-pro-section-3-card {
    width: 100%;
  }
}