.m-pro-login-btn{
  width: 30vw;
  height: 8vh;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0px solid #0E487A;
  background: var(--Vieunite-Pro-White, #FFF);
  color: var(--Black, #000);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: -18vh 0 0 26vw;
  white-space: normal; /* Allows text to wrap to the next line */
  word-wrap: break-word; /* Breaks long words to fit within the container */
  word-break: break-word; /* Ensures proper breaking of words */
}
.m_pro_login_img{
  width: 100vw;
  filter: brightness(0.5) opacity(0.8);
  margin: -1px auto;
}
.p-login-title {
  margin-top: 8rem;
  margin-bottom: 1rem;
  width: 822.516px;
  //height: 56.004px;
  flex-shrink: 0;
  color: var(--DarkPurple, #11142D);
  text-align: center;
  font-family: Proxima Nova bold, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.artist-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.pro-login-container {
  .image-row {
    .image-col {
      padding: 0; // Remove padding to avoid gaps between columns
      .image-overlay-container {
        position: relative;
        width: 100%;
        height: 866px; // Set a height for the container
        background-size: cover; // Ensure the background image covers the entire container
        background-position: center; // Center the background image
        display: flex;
        align-items: center; // Center content vertically
        justify-content: center; // Center content horizontally
        background-image: url('./imgs/Artist Login.webp');
      }
    }
  }
}

.image-overlay-container2 {
  position: relative;
  width: 100%;
  height: 855px; // Set a height for the container
  background-size: cover; // Ensure the background image covers the entire container
  background-position: center; // Center the background image
  display: flex;
  align-items: center; // Center content vertically
  justify-content: center; // Center content horizontally
  background-image: url('./imgs/Business Login.webp');
}

.image-overlay-container3 {
  position: relative;
  width: 100%;
  height: 855px; // Set a height for the container
  background-size: cover; // Ensure the background image covers the entire container
  background-position: center; // Center the background image
  display: flex;
  align-items: center; // Center content vertically
  justify-content: center; // Center content horizontally
  background-image: url('./imgs/Home User Login.webp');
}

.jk-overlay {
  position: absolute;
  bottom: 0; // Adjust as necessary
  left: 0;
  top: 5rem;
  width: 100%;
  padding: 0 10px; // Add padding inside the overlay
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  .overlay-title {
    color: #fff; // Adjust as necessary
    width: 357px;
    height: 53px;
    text-align: center;
    flex-shrink: 0;
    text-align: center;
    font-family: Proxima Nova bold, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .overlay-button {
    margin-top: 410px; // Space between buttons
    width: 12vw;
    height: 6vh;
    flex-shrink: 0;
    border-radius: 9px;
    border: 0px solid var(--Yellow, #FFF);
    background: var(--Yellow, #FFF);
    color: #000;
    text-align: center;
    font-family: Proxima Nova bold, sans-serif;
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.overlay-text {
  color: #fff; // Adjust as necessary
  width: 460.484px;
  height: 47.992px;
  flex-shrink: 0;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 310px;
}

.overlay-text-qr {
  width: 325.938px;
  height: 22.945px;
  flex-shrink: 0;
  color: var(--Yellow, #FFF);
  text-align: right;
  font-family: Proxima Nova bold, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 0%; /* 0px */
}