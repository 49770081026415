.pro-success-col {
  width: 30vw;
  margin: 0;
}

.pro-success-p1 {
  width: 30vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pro-success-p2 {
  width: 30vw;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 7vw;

}

.pro-success-btn {
  width: 8vw;
  margin-left: 10vw;
  flex-shrink: 0;
  background: #0E487A;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pro-success-img {
}