.card-main {
    border-radius: 14px;
    border: 2px solid #DFDFDF;
    background: var(--White, rgba(255, 255, 255, 0.00));
    width: 220px;
    height: 335px;
    margin: 10px;
}

.card-main2 {
    border-radius: 14px;
    width: 240px;
    height: 335px;
    margin: 10px;
}

.album-card-main {
    border-radius: 14px;
    width: 240px;
    height: 335px;
    margin: 10px;
}

.card-button {
    border-radius: 5px;
    background: var(--V-Pro-Blue, #0E487A);
}

.discovery-card-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    /* font-family: Proxima Nova; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.collection-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: calc(1.275rem + 0.3vw);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 15px;
    opacity: 1;
}

.collection-subtitle {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-left: 15px;
    margin-top: -15px;
}

.collection-subtitle-clickable {
    cursor: pointer;
    color: #0E487A;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
}