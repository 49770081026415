.pro-dy-table-title {
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 6vh;
}

.pro-dy-table-content-left {
  color: #000;
  text-align: left;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 5vh;
  display: flex;
  align-items: center;
  padding-left: 4vw;
}

.pro-dy-table-content {
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pro-dy-table-p1 {
  color: #936846;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4rem;
}

.pro-dy-table-p2 {
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: -2vh auto -.5vh auto;
}

.pro-dy-table-p3 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pro-dy-table-btn {
  display: flex;
  width: 12vw;
  height: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 2vh auto;
}