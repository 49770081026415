.artist-text {
 font-family: "Proxima Nova bold", sans-serif;
    color: black;
    margin-bottom: 2.5rem;
    text-align: left;
    width: 50%;
}

.artist-img{
    cursor: pointer;
}
.artist-img:hover{
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    filter: brightness(80%);
}
@media only screen and (max-width: 57.5rem){
    .artist-text{
        width: 100%;
    }
    .m-img-text-at{
        flex-direction: column;
        align-content: center;
    }
}