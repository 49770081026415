

.stripe-pay-btn{
  width: 7vw;
  height: 4vh;
  flex-shrink: 0;
  border-radius: 0.1875rem;
  background: #A06174;
  color: var(--White, var(--Yellow, #FFF));
  text-align: center;
  font-family: Proxima Proxima Nova bold, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin:auto;
  margin-top: 20px;
}
