.pro-support-demo-content {
  padding-top: 10vh;
  background-color: #ebf1f5;
  font-family: Proxima Nova, sans-serif;
}
.pro-support-demo-out {
  display:flex;
  //padding-left: 10vw;
  //padding-right: 10vw;
  width: 100vw;
}
.pro-support-demo-in {
  padding-left: 10vw;
  padding-right:10vw;
}
.ProSupport-demo-title {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
}
.ProSupport-demo-content {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.responsive-form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.form-wrapper {
    background-color: white;
    width: 80%;
    min-width: 25rem;
    border-radius: 15px;
    margin: 0.5rem 0 5rem 0;
    overflow: hidden;
  }
@media only screen and (max-width: 600px) {
  .pro-support-demo-content {
    padding-top: 12vh;
    background-color: #ebf1f5;
    font-family: Proxima Nova, sans-serif;
  }
  .responsive-form-container {
    width: 100%; // Full width on mobile
    background-color: white;
    justify-content: flex-start; // Align content to the start
    height: auto;
    margin-top: 2rem;
    border-radius: 1rem;
  }
  .form-wrapper {
    width: 100%; // Full width on mobile
    border-radius: 0; // Remove border radius on mobile
    margin: 0; // Remove margins on mobile
    height: auto; // Adjust height if necessary
  }
  .support-demo {
    background-color: #ebf1f5;

  }
  .pro-support-demo-out {
  }
  .pro-support-demo-in {
    padding-left: 0;
    padding-right: 0;
  }
}