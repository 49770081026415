
.login-form-forgot{
    text-decoration: underline;
    font-family: "Proxima Nova bold", sans-serif;
    font-weight: 600;
}

//.logo-area{
//    position: absolute;
//    bottom: 2rem;
//    left: 2rem;
//}

.author-name{
    color: black;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    font-family: Proxima Nova Regular, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    bottom: 2rem;
    left: 9rem;
    background-color: white;
}

.img-container{
    height: 100vh;
    display: flex;
    justify-content: center; /* horizontally center the image */
    align-items: center; /* vertically center the image */
    width: 100%;
    max-height: 100vh;
}

.img-left{
    max-width: 100%;
    //max-height: 100vh;
    object-fit: contain;
    height: auto
}

.login-form-button{
    width: 100%;
    background-color: #105B63;
    color: white;
    border-radius: 8.69px;
    font-family: "Proxima Nova bold", sans-serif;
    font-weight: bold;
    height: 2.6rem;
}

.login-form-button:hover{
    color: #105B63;
    border-color: #105B63;
}

.login-input-form{
    border-radius: 8.69px;
    height: 2.6rem;
}

@media only screen and (min-width: 600px){
    .login-form-col {
        margin-top: 6%;
    }
    .m-only-row{
        display: none!important;
    }
}
@media only screen and (max-width: 600px){
    .logo-area{
        position: absolute;
        bottom: 0.2rem;
        left: 0rem;
    }

    .author-name{
        color: var(--white, #FFF);
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 1.5rem;
        font-family: Proxima Nova Regular, sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position: absolute;
        bottom: 0.2rem;
        left: 6.8rem;
    }
    .m-only-row{
        margin: 1rem 0 4rem 0;
        .ant-btn {
            border: 1px solid #105B63;
            width: 10rem;
            border-radius: 8.69px;
        }

        .ant-btn-primary {
            height: 2.6rem;
            background-color: #105B63;
        }
    }
    .img-container{
        height: 25vh;
        width: 100%;
        max-height: 25vh;
    }

    .img-left{
        width: 100%;
        height: inherit;
        object-fit: cover;
    }
}