.IndustryQuotesTitle {
  color: #000;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5rem;
}
.SixteenNine-part {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 20px;
  border: 3px solid #7A063B;
  background: #FFF;
  align-content: center;
  padding: 5rem 4.1vw 5rem 4.1vw;
}
.sisteen-nine-content {
  color: #7A063B;
  font-family: "Proxima Nova";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.sisteen-nine-author {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.interactive-part {
  border-radius: 20px;
  border: 3px solid #2A9615;
  background: #FFF;
  align-content: center;
  padding: 4rem 3.3vw 4rem 3.3vw;
  height: 100%;
  width: 100%;
  margin: 0 0 5rem 0;
}
.interactive-content {
  color: #2A9615;
  font-family: "Proxima Nova";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1rem;
}
.interactive-author {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mediaDigital-part {
  border-radius: 20px;
  border: 3px solid #06467A;
  background: #FFF;
  align-content: center;
  padding: 4rem 3.3vw 4rem 3.3vw;
  height: 100%;
  width: 100%;
}
.mediaDigital-content {
  color: #06467A;
  font-family: "Proxima Nova";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3.0rem;
  padding-bottom: 1rem;
}
.mediaDigital-author {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.techRadar-part {
  border-radius: 23px;
  border: 2px solid #F0810E;
  background: #FFF;
  padding: 4rem 3.3vw 4rem 3.3vw;
  height: 100%;
}
.techRadar-content {
  color: #F0810E;
  font-family: "Proxima Nova";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.563rem; /* 104.167% */
  padding-top: 1rem;
}
.independent-part {
  border-radius: 23px;
  border: 2px solid #7A063B;
  background: #FFF;
  padding: 4rem 3.3vw 4rem 3.3vw;
  height: 100%;
}
.independent-content {
  color: #7A063B;
  font-family: "Proxima Nova";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.563rem; /* 104.167% */
  padding-top: 1rem;
}
.creativeBlog-part {
  border-radius: 23px;
  border: 2px solid #2A9615;
  background: #FFF;
  padding: 4rem 3.3vw 4rem 3.3vw;
  height: 100%;
}
.CreativeBloq-content {
  color: #2A9615;
  font-family: "Proxima Nova";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 104.167% */
  padding-top: 1rem;
}


.interactive {
  width: 15vw;
  height: auto;
  flex-shrink: 0;
}
.mediaDigital {
  width: 15vw;
  height: auto;
  flex-shrink: 0;
}

.techRadar {
  width: 15vw;
  height: auto;
  flex-shrink: 0;
}
.independent {
  width: 14vw;
  height: auto;
  flex-shrink: 0;
}
.creativeBlog {
  width: 14.3vw;
  height: auto;
  flex-shrink: 0;
}
.case-pic {
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .interactive {
    width: 40vw;
    height: auto;
    flex-shrink: 0;
  }
  .mediaDigital {
    width: 40vw;
    height: auto;
    flex-shrink: 0;
  }
  .techRadar {
    width: 40vw;
    height: auto;
    flex-shrink: 0;
  }
  .independent {
    width: 40vw;
    height: auto;
    flex-shrink: 0;
  }
  .creativeBlog {
    width: 40vw;
    height: auto;
    flex-shrink: 0;
  }
  .case-pic {
    justify-content: center;
  }
  .IndustryQuotesTitle {
    color: #000;
    text-align: center;
    font-family: "Proxima Nova";
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2rem;
  }
  .sixteenNine {
    width: 40vw;
    height: auto;
    flex-shrink: 0;
  }
}

/* 默认 gutter 样式 */
.custom-gutter-row .ant-col {
  margin-bottom: 10px; /* 保持桌面端的垂直间距 */
}

/* 在移动端覆盖垂直间距 */
@media (max-width: 768px) {
  .custom-gutter-row .ant-col {
    margin-bottom: -50px; /* 移动端的垂直间距 */
  }
}


