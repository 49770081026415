.pro-commodity-top-p1 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  //width: 18vw;
  width: 20vw;
  margin-bottom: 1vh;
  user-select: none;
}

.pro-commodity-top-p2 {
  color: #936846;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 25vw;
}

.pro-commodity-top-p3 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 25vw;
}
.pro-commodity-top-p4 {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: auto;
  margin-left: 0vw;
}


.Commodity-title-container {
  //padding: 5rem;
  //margin-top: 0rem;
  text-align: center;
  //position: relative;
  //padding-bottom: 5rem;
}

.Commodity-title {
  color: #000;
  text-align: center;
  font-family: "Proxima Nova", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 5rem;
  //justify-items: center;
  //justify-content: center;
  //margin-top: 5rem;
  //padding-top: 5rem;
  //padding-bottom: -2rem;
  //position: static;
}

.Pro-Commodity-VideoContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

.Commodity-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Commodity-pic {
  align-content: center;
  align-items: center;
  padding: 5rem 12rem 1rem;
}

.pro-commodity-pic {
  width: 1260px;
  height: 708px;
  flex-shrink: 0;
}

.pro-section-commodity-title {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 3vw;
  width: 31rem;
}

.pro-section-commodity-title-mobile {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 6vw;
  width: 31rem;
}

.pro-section-commodity-title-button {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  //margin: 3vw;
  max-width: 31rem;
}

.CommoditySectionTitle2 {
  margin-left: 12rem;
  //padding-bottom: 5rem;
}


@media only screen and (max-width: 900px) {
  .pro-commodity-carousel-outer-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 0!important;
    width: 100vw!important;
  }
  .pro-commodity-top-p1 {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80vw;
    margin-bottom: 1vh;
    user-select: none;
  }
  .pro-commodity-top-p2 {
    color: #936846;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80vw;
  }
  .pro-commodity-top-p3 {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80vw;
  }
  .Commodity-title-container {
    text-align: center;
    position: relative;
    padding-bottom: 5rem;
    margin-top: 30vh;
  }
  .commodity-text {
        margin-bottom: 1rem;
  }
  .pro-commodity-top-p4 {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.5rem;
  }
}