.Portal-Upgrade-Sub {
  margin-left: 7rem;
  margin-top: 3rem;
  height: 550rem;
}
.Portal-Upgrade-Sub-Title {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  //opacity: 0.5;
}
.Portal-Upgrade-Button {
  width: 252px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #0E487A;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5rem;
  margin-top: 1.5rem;
}
.Portal-Upgrade-Para {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.custom-proaccount-table {
  width: 70vw;
  height: auto;
  flex-shrink: 0;
  overflow-y: auto;
  margin-left: 0.3rem;
}

.row-selected {
  background: #898989;
}
