.side-cart-txt{
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.for-home-hd {
  width: 100vw;
  height: 30rem;
  background: transparent;
  border-top: 1px #E1E1E1 solid;
}

.for-bs-hd {
  width: 100vw;
  height: 30rem;
  background: transparent;
  border-top: 1px #E1E1E1 solid;
}

.for-art-hd {
  width: 100vw;
  height: 30rem;
  background: transparent;
  border-top: 1px #E1E1E1 solid;
}

.for-login-hd {
  width: 20rem;
  height: 30rem;
  //margin-left: 80vw;
  display: flex;
  align-items: start;
  justify-content: start;
  background-color: transparent;
  position: absolute;
  top: 2rem; /* Adjust as necessary */
  right: 2rem;
}

.for-home-p {
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  //line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

//@media only screen and (max-width: 1450px) {
//  .hide-1450{
//    display: none;
//  }
//}
//
//@media only screen and (max-width: 1300px) {
//  .hide-1300{
//    display: none;
//  }
//}

.home-sp-modal-for-scan {

  margin-top: 4rem;
  .ant-modal-header {
    padding: 0;
    margin-top: 1rem;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    padding: 0;
  }

  .ant-modal-content {
    padding: 2.6rem !important;
    width: 100%;
    border-radius: 2rem;
  }
  .home-sp-modal-for-scan-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home-sp-modal-for-scan-content-p{
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 2vh 0;
  }
}
