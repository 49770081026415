.m-z-h1 {
  width: 100vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 8vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 2vh 0 0 0;
}
.m-sub-opt-j-small-txt{
  width: 20vw;
  color: #6D6767;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  margin: 0 0 -3vh 0;
}
.m-z-txt {
  width: 90vw;
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 2vh auto;
  text-align: center;
}

.m-sub-title {
  width: 59.95313rem;
  //height: 4.96713rem;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10rem;
}

.m-sub-txt {
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  flex-shrink: 0;
  margin-top: -2rem;
}

.m-sub-head-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.m-v2-shop-package-col{
  width: 30vw!important;
  height: auto;
  flex-shrink: 0;
  border: 0px solid #C6C6C6;
  background: #FFF;
}





.z-h1 {
  width: 657.467px;
  flex-shrink: 0;
  color: #000;
  text-align: right;
  font-family: Proxima Nova, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 4rem 0 0;
}
.sub-opt-j-small-txt{
  width: 20vw;
  color: #6D6767;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  margin: 0 0 -3vh 0;
}
.z-txt {
  width: 657.467px;
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 -4rem 20px 0;
}

.sub-title {
  width: 59.95313rem;
  //height: 4.96713rem;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10rem;
}

.sub-txt {
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  flex-shrink: 0;
  margin-top: -2rem;
}

.sub-head-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.v2-shop-package-col{
  width: 30vw!important;
  height: auto;
  flex-shrink: 0;
  border: 0px solid #C6C6C6;
  background: #FFF;
}

.sub-option {
  border: 0px solid #C6C6C6;
  background: #FFF;
  width: 17vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  padding: 2rem 0 2rem 0;
  .shop-divide-line{
    width: 16vw;
    height: 1px;
    margin: auto;
    background: #C6C6C6;
  }
  .shop-divide-line-top{
    width: 16vw;
    height: 1px;
    margin: 0 auto 0 auto;
    background: #C6C6C6;
  }

  .sub-opt-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.6vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .sub-opt-subtitle {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 0 0;
  }

  .sub-opt-per-year {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .sub-opt-btn {
    border-radius: 0.9375rem;
    width: 21.5vw;
    height: 8.52119vh;
    flex-shrink: 0;
    color: #0E477A;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 2px solid var(--Vieunite-Pro-Blue, #0E477A);
    background: rgba(14, 72, 122, 0.00);
    cursor: default;
  }

  .sub-opt-small-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.125vw;
    font-style: normal;
    font-weight: 700;
    margin: 5% 0 4% -40%;
    //line-height: 141%; /* 1.58625rem */
  }
}
.m-sub-option {
  border: 0px solid #C6C6C6;
  background: #FFF;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  padding: 2rem 0 2rem 0;
  .m-shop-divide-line{
    width: 100vw;
    height: 1px;
    margin: auto;
    background: #C6C6C6;
  }
  .m-shop-divide-line-top{
    width: 100vw;
    height: 1px;
    margin: 0 auto 0 auto;
    background: #C6C6C6;
  }

  .m-sub-opt-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 8vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .m-sub-opt-subtitle {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 6vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 0 0;
  }

  .m-sub-opt-per-year {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .m-sub-opt-btn {
    border-radius: 0.9375rem;
    width: 21.5vw;
    height: 8.52119vh;
    flex-shrink: 0;
    color: #0E477A;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 2px solid var(--Vieunite-Pro-Blue, #0E477A);
    background: rgba(14, 72, 122, 0.00);
    cursor: default;
  }

  .m-sub-opt-small-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.125vw;
    font-style: normal;
    font-weight: 700;
    margin: 5% 0 4% -40%;
    //line-height: 141%; /* 1.58625rem */
  }
}
.sub-features-div {
  width: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8vw;

  .sub-features-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.9vh auto;
  }
}
.shop-divide-line-dt{
  width: 26vw;
  height: 1px;
  margin: auto;
  background: #C6C6C6;
}
.shop-divide-line-top-dt{
  width: 26vw;
  height: 1px;
  margin: 0 auto 0 auto;
  background: #C6C6C6;
}
.sub-dt-div {
  width: 26vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8vw;

  .sub-dt-inner {
    //margin: 0.6vh 0;
  }
}
.sub-most-popular {
  position: relative;
  width: 18vw;
  height: 5vh;
  flex-shrink: 0;
  background: #A06174;
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -5vh 0 0 0;
  flex-direction: column;
}
.sub-most-popular-h{
  width: 30vw;
  height: auto;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 700;
  margin: 3vh 0 0.8vh 0;
}
.sub-most-popular-text {
  position: relative;
  z-index: 2; /* Ensure the text is on top of the triangle */
  color: #FFFFFF; /* Set text color */
  font-size: 0.675vw;
  font-family: Proxima Nova, sans-serif;
  width: 12vw;
}

.sub-most-popular::after {
  content: '';
  position: absolute;
  bottom: -7.9vh; /* Adjust this value to change where the triangle sits relative to the div */
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  border-left: 9vw solid transparent; /* Half the width of .sub-most-popular */
  border-right: 9vw solid transparent; /* Half the width of .sub-most-popular */
  border-top: 8vh solid #A06174; /* Height and color of the triangle */
  z-index: 1;
}

.learn-more-btn {
  width: 15.65931rem;
  height: 4.5475rem;
  flex-shrink: 0;
  border-radius: 0.9375rem;
  //border: 5px solid var(--Yellow, #FFF);
  background: #A06174;
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 4rem 0 0;
}

.sub-text-under {
  width: 93.6985rem;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 128%; /* 1.92rem */
}

.qa-title {
  width: 43.18044rem;
  height: 5.51338rem;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.m-shop-sub-opt-btn{
  border-radius: 5px;
  background: #0E487A;
  width: 40vw;
  height: 4vh;
  flex-shrink: 0;
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Proxima Nova bold, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 700;
  margin: 2.8vh auto 0 auto;
}

.m-sub-features-div {
  width: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4vw;

  .m-sub-features-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.9vh auto;
  }
}
.m-shop-divide-line-dt{
  width: 26vw;
  height: 1px;
  margin: auto;
  background: #C6C6C6;
}
.m-shop-divide-line-top-dt{
  width: 26vw;
  height: 1px;
  margin: 0 auto 0 auto;
  background: #C6C6C6;
}
.m-sub-dt-div {
  width: 26vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8vw;

  .m-sub-dt-inner {
    //margin: 0.6vh 0;
  }
}
.m-sub-most-popular {
  position: relative;
  width: 18vw;
  height: 5vh;
  flex-shrink: 0;
  background: #A06174;
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -5vh 0 0 0;
  flex-direction: column;
}
.m-sub-most-popular-h{
  width: 30vw;
  height: auto;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 700;
  margin: 3vh 0 0.8vh 0;
}
.m-sub-most-popular-text {
  position: relative;
  z-index: 2; /* Ensure the text is on top of the triangle */
  color: #FFFFFF; /* Set text color */
  font-size: 0.675vw;
  font-family: Proxima Nova, sans-serif;
  width: 12vw;
}

.m-sub-most-popular::after {
  content: '';
  position: absolute;
  bottom: -7.9vh; /* Adjust this value to change where the triangle sits relative to the div */
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  border-left: 9vw solid transparent; /* Half the width of .sub-most-popular */
  border-right: 9vw solid transparent; /* Half the width of .sub-most-popular */
  border-top: 8vh solid #A06174; /* Height and color of the triangle */
  z-index: 1;
}

.m-learn-more-btn {
  width: 15.65931rem;
  height: 4.5475rem;
  flex-shrink: 0;
  border-radius: 0.9375rem;
  //border: 5px solid var(--Yellow, #FFF);
  background: #A06174;
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 4rem 0 0;
}
.m-qa-col{
  .ant-collapse-header-text{
    display: flex;
    width: 64vw;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 4.2vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.m-sub-text-under {
  width: 86vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 128%; /* 1.92rem */
}

.m-qa-title {
  width: 80vw;
  height: 5.51338rem;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 8vw;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}