.vi__container {
  width: 20vw;
}

@media only screen and (max-width: 600px) {
  .vi__container {
    width: 90vw;
  }
}

.rq-table td {
  border: 1px solid black;
}


.charge-modal {
  margin-top: 4rem;
  .ant-modal-header {
    padding: 0;
    margin-top: 1rem;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    padding: 0;
  }

  .ant-modal-content {
    padding: 2.6rem !important;
    width: 100%;
    border-radius: 2rem;
  }
}
