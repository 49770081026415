.image-display-image-preview-landscape {
  .ant-modal-content {
    width: 50vw;
    height: auto;
    flex-shrink: 0;
    border-radius: 6px;
    background: black;
    margin: auto;
    padding: 16px;
    margin-left: -10vw;
  }

  .ant-modal-title,.ant-modal-footer,.ant-modal-header {
    display: none;
  }
}
.mb-no-saved-img-p{
  width: 36vw;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  opacity: 0.5;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: auto;
  margin-top: 8vh;
  margin-left: 20vw;
}

.image-display-image-preview-portrait {
  .ant-modal-content {
    width: auto;
    height: auto;
    flex-shrink: 0;
    border-radius: 6px;
    background: black;
    margin: auto;
    margin-top: -11vh;
    padding: 16px;
  }

  .ant-modal-title,.ant-modal-footer,.ant-modal-header {
    display: none;
  }
}


.mb-spec-number-of-img {
  color: #000;
  text-align: right;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  margin: 0 0 0 12vw;
}

.mb-dsp-title {
  width: 38vw;
  height: 49px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  //font-size: 24px;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 6vh 0 auto 1vw;
  opacity: 0.8;
  display: flex;
  align-items: center!important;
  justify-content: start;
}

.mb-img-ds-btn {
  width: 121px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mb-jsb-cate-btn {
  width: 6vw;
  height: 3.6vh;
  flex-shrink: 0;
  border-radius: 6px;
  background: #D9D9D9;
  margin: 3.8rem 0 0 0.6vw;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:not(:disabled):hover {
    /*background-color: #582859 !important;*/
    /*color: #dd4472 !important;*/
    background: #0E487A !important;
    color: #FFF !important;
    border-color: #0E487A !important;
  }

  &:focus {
    background: #0E487A !important;
    color: #FFF !important;
    border-color: #0E487A !important;
  }
}

.mb-spec-img-div {
  width: 275px;
  height: 341px;
  flex-shrink: 0;
  border-radius: 14px;
  border: 0px solid #DFDFDF;
  background: var(--Yellow, #FFF);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  margin: 4rem 4rem 1rem 4rem;

  .mb-spec-img-div-image {
    object-fit: contain;
  }

  .mb-spec-img-div-p {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0.6rem auto;
  }

  .mb-spec-img-div-btn {
    width: 189px;
    height: 34px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--V-Pro-Blue, #0E487A);
    color: var(--Yellow, #FFF);
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

.ant-dropdown-menu-title-content {
  display: flex;
  width: 189px;
  padding: 12px 46px 6px 16px;
  align-items: center;
  border-radius: 3px;
  //background: var(--White, #FFF);
  text-align: left;
  color: var(--Gray-5, #555);
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
}

.im-ml-new-album-modal {
  .ant-modal-content {
    width: 869px;
    height: 320px;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Yellow, #FFF);
    margin: 15% 0 0 -30%
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ml-new-album-modal-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px;
  }

  .ml-new-album-modal-txt {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 653px;
    flex-shrink: 0;
    text-align: center;
    margin: auto;
  }

  .ml-new-album-modal-sub-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-shrink: 0;
    margin: 2rem 0 0 0;
  }

  .ml-new-album-modal-input {
    width: 604px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 9px;
    background: #E2E2E2;
    margin: 1rem 0 0 12%;
  }

  .ml-new-album-modal-btn {
    border-radius: 6px;
    background: #0E487A;
    width: 260px;
    height: 56px;
    flex-shrink: 0;
    margin: 2rem 0 0 35%;
    color: var(--Yellow, #FFF);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 16px; // Adjust spacing as needed
    }
  }
}

.image-display-add-album-my-albums {
  width: 186px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin: 6vh 0 0 0;
}

.image-add-to-album-modal {
  .ant-modal-content {
    width: 30vw;
    height: 80vh;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Yellow, #FFF);
    margin: auto
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .image-add-to-album-modal-h {
    width: 28vw;
    height: 41px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: auto
  }

  .image-add-to-album-modal-final-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .image-add-to-album-modal-btn {
    border-radius: 6px;
    background: #0E487A;
    width: 10vw;
    height: 5vh;
    flex-shrink: 0;
    margin: auto;
    color: var(--Yellow, #FFF);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.1vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 16px; // Adjust spacing as needed
    }
  }

  .img-mb-album-card {
    width: 12vw;
    height: 26vh;
    flex-shrink: 0;
    border-radius: 14px;
    border: 0px solid #DFDFDF;
    background: rgba(255, 255, 255, 0.59);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img-mb-album-name {
    width: 8vw;
    flex-shrink: 0;
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.6vw;
    font-style: normal;
    font-weight: 700;
    margin: 0 0 -0.6vh 0;
  }

  .img-mb-album-number {
    width: 8vw;
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.6vw;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .image-display-add-album-file {
    flex-shrink: 0;
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.7vw;
    font-style: normal;
    font-weight: 700;
    margin: 1vh 0 -0.6vh 1vh;
  }

  .image-display-add-album-file-name {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.7vw;
    font-style: normal;
    font-weight: 500;
    margin: 0 0 0 1vh
  }
}

