.sim-line {
    border-right: 0.063rem solid #000000;
}

.m-only {
    display: none;
}

.google-map > div > div {
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
}

@media only screen and (max-width: 37.5rem) {
    .m-map-row {
        margin: 2rem 0 !important;
    }

    .sim-line {
        border-right: none;
    }

    .m-only {
        display: initial;
    }

    .m-p {
        max-width: 100% !important;
    }

    .m-top {
        margin-top: 4rem !important;
    }

    .m-font {
        font-size: 2.375rem !important;
    }
}

.ContactPictureText {
    width: 47.875rem;
    height: 8.813rem;
    flex-shrink: 0;
    color: #FFF;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    margin-bottom: -2rem;
}

.example-section-container-contact {
    overflow: hidden; /* Ensure the slider doesn't show images outside the visible area */
    width: 80vw; /* Full width of the container */
    position: relative;
}

.ContactAddress {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding-left: -5vw;
}

.ContactExampleContainer {
    position: relative;
    width: 90vw;
    overflow: hidden;
    background-color: black;
    overflow: clip;
    border-radius: 1.125rem;
    margin: 3vw 6vw 3vw 8.6vw;
    padding: 2vw 2vw 3vw 3vw;
}

.ContactEmailTemplate {
    /*width: 22vw;*/
    /*height: 22vh;*/
    width: 23vw;
    height: 100%;
    flex-shrink: 0;
    border-radius: 1.875rem;
    background: #FFF;
    /*margin-left: 2rem;*/
    margin-left: 8%;

}

.ContactEmailPic {
    width: 3.25rem;
    height: 3.25rem;
    flex-shrink: 0;
    padding-top: 2.5rem;
    padding-left: 2rem;
}

.ContactEmailTitle {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 97%; /* 23.28px */
    padding-left: 7rem;
    margin-top: -1rem;
}

.ContactEmailContext {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    padding-left: 7rem;
    padding-top: 0.5rem;
    width: 18vw;
}

.ContactEmailButton {
    display: flex;
    width: 19vw;
    height: 5vh;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: "Proxima Nova";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 0.5rem;
    padding-left: 25%;
    /*margin-left: 2rem;*/
    /*margin-top: 1.5rem;*/
    margin-left: 8%;
    margin-top: 10%;
    background-color: white; /* 确保默认背景色为白色 */
    border: 1px solid black; /* 设置边框颜色 */
}

.ContactCallButton {
    display: flex;
    /*width: 364px;*/
    /*height: 51px;*/
    width: 19vw;
    height: 5vh;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: "Proxima Nova";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 0.5rem;
    padding-left: 30%;
    /*margin-left: 2rem;*/
    /*margin-top: 1.5rem;*/
    margin-left: 8%;
    margin-top: 10%;
    background-color: white; /* 确保默认背景色为白色 */
    border: 1px solid black; /* 设置边框颜色 */
}

/* 添加 hover 样式 */
.ContactEmailButton:hover {
    background-color: black; /* 当 hover 时，背景变为黑色 */
    color: white; /* 文字变为白色，以确保对比度 */
    cursor: pointer; /* 鼠标变为 pointer，提升用户体验 */
}

.ContactCallButton:hover {
    background-color: black; /* 当 hover 时，背景变为黑色 */
    color: white; /* 文字变为白色，以确保对比度 */
    cursor: pointer; /* 鼠标变为 pointer，提升用户体验 */
}

.ContactMapTitle {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 97%; /* 23.28px */
    /*margin-left: -27%;*/
}

@media only screen and (max-width: 920px) {
    .ContactExampleContainer {
        position: relative;
        width: 100vw;
        height: 100%;
        flex-shrink: 0;
        overflow: hidden;
        background-color: black;
        overflow: clip;
        border-radius: 0;
        margin: auto;
        padding-top: 4rem;
        padding-bottom: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }


    .example-section-image-container-jack {
        flex: 0 0 100%; /* Each image takes up 1/3 of the viewport width */
        box-sizing: border-box;
        /*padding: 0.625rem;*/
        transition: transform .6s ease; /* 添加过渡效果 */
        /*transform: scale(1); !* 初始无缩放 *!*/
        margin: auto;
    }

    .example-section-image-container-jack:hover {
        transform: scale(1.02);
    }

    .example-section-image-jack {
        display: block;
        height: auto;
        border-radius: 23px;
    }

    .ContactPictureText {
        width: 24rem;
        height: 8.813rem;
        flex-shrink: 0;
        color: #FFF;
        font-family: "Proxima Nova";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
        margin-bottom: 8rem;
        /*margin-left: 1rem;*/
    }

    /*.homeSection1TitleText {*/
    /*    font-family: Proxima Nova Regular, sans-serif;*/
    /*    font-size: 1.9rem;*/
    /*    font-style: normal;*/
    /*    font-weight: 700;*/
    /*    width: 100vw;*/
    /*    margin: 0;*/
    /*}*/
    .homeSection1TitleText-jack {
        font-family: Proxima Nova Regular, sans-serif;
        font-size: 1.9rem;
        font-style: normal;
        font-weight: 700;
        width: 100%;
        margin: 0;
        margin-left: 1rem;
    }

    .openhours-app-new {
        color: #000;
        font-family: "Proxima Nova";
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 10%;
        padding-bottom: 3rem;
    }

    .title-app-new {
        font-family: 'Proxima Nova bold', sans-serif !important;
        color: #000;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 97%; /* 46.56px */
        margin-top: 5rem;
    }
}

.openhours-app-new {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 10%;
}

.mobile-CallAndEmail {
    background: #FFF;
    width: 100%;
    height: 25vh;
    padding-top: 5vh;
}

.mobile-CallAndEmail1 {
    background: #FFF;
    width: 100%;
    height: 25vh;
    margin-top: -4vh;
}

.mobile-ContactCall-Title {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 97%; /* 23.28px */
    padding-left: 2rem;
}

.mobile-ContactCall-Context {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    padding-left: 2rem;
}

.ContactMobileMap {
    background: #FFF;
    height: 55vh;
    margin-top: -2rem;
    padding-top: -1rem;
    margin-bottom: 4rem;
}

.ContactMobileMapTitle {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 97%; /* 23.28px */
    margin-left: 2rem;
    margin-top: -5rem;
}

.ContactMobileMapContext {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    margin-left: 2rem;
    padding-bottom: 2rem;
}

.title-app-new {
    font-family: 'Proxima Nova bold', sans-serif !important;
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 97%; /* 46.56px */
}

.context-app-new {
    color: #000;
    font-family: "Proxima Nova";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
    .logo-row {
        padding: 0 0 4rem 0 !important;
    }

    .dt-logos {
        display: none;
    }

    .m-logos {
        display: initial;
    }

    .text-content {
        font-size: 14px;
    }

    .title-app {
        margin-top: 10%;
    }

    .logo-img {
        width: 80%;
    }
}

.Mo-MapTitle {
    width: 90vw;
    height: 24px;
    flex-shrink: 0;
    color: #000;
    font-family: "Proxima Nova";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 1.5rem;
}

.Mo-MapAddress {
    width: 300px;
    height: 42px;
    flex-shrink: 0;
    color: #000;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1rem;
}

.Mo-MapCity {
    width: 300px;
    height: 42px;
    flex-shrink: 0;
    color: #000;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -1.5rem;
}

.Mo-MapPostcode {
    width: 300px;
    height: 42px;
    flex-shrink: 0;
    color: #000;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -1.5rem;
}

.Mo-MapButton {
    width: 100%;
}

.Mo-popMap {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: 2rem;
    margin-bottom: -1rem;
}

.Mo-MapButtonText {
    /*width: 300px;*/
    /*height: 42px;*/
    flex-shrink: 0;
    color: #000;
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}
