.imgTextComboText-gold{
  color: #936846;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 5vw;
}
.imgTextComboText{
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 5vw;
  text-align: left;
}