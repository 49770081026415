.next-btn{
  .ant-btn {
    border: 1px solid #105B63;
    border-radius: 8.69px;
    width: 60%;
    padding: 1rem;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

  .ant-btn-primary {
    background-color: #105B63;
    width: 80%;
    margin-bottom: 5%;

  }
 .ant-btn-primary:hover{
   background-color: gray;
 }
}