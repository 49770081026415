.ant-layout-sider-trigger {
  background: #0E487A;
}

.ant-menu-item-selected{
  background: #0E487A!important;
}
.ant-menu{
  background: #6D90AE !important;
}
.ant-layout-sider{
  background: #6D90AE !important;
}

.sticky-sider {
  position: sticky;
  top: 88px; // Starts sticking 200px below the top of the viewport
  height: calc(100vh - 100px); // Optional: Adjust height if you want the sider to fit in the window after sticking
  overflow-x: hidden;
  overflow-y: auto; // Allows sidebar to scroll independently if taller than the adjusted height
  z-index: 1000;
}


/* 在折叠状态下调整菜单项样式，确保内容居中，但不强制隐藏文本或设置尺寸为0 */
.ant-menu-inline-collapsed > .ant-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 调整图标样式，确保其在折叠状态下可见并垂直居中 */
.ant-menu-inline-collapsed .anticon {
  line-height: normal;
}

/* 针对折叠状态下的文本样式进行调整，避免使用 height: 0; 或 overflow: hidden; */
.ant-menu-inline-collapsed > .ant-menu-item .bp-nav-txt {
  display: none; /* 直接隐藏文本，而不是通过设置尺寸或溢出隐藏 */
}



