.proportalHome-icon-container {
  position: absolute;
  left: 3vw;
  top: 31vh;
  z-index: 10;
}
.proportalHome-first-row-icons, .proportalHome-second-row-icons {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.Discover-icon, .Library-icon, .Dashboard-icon, .Account-icon {
  display: block;
  width: 162px;
  height: 162px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--Yellow, #FFF);
  margin-right: 20px; /* 每个图标之间的间距 */
}

.proportalHome-icon-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 162px;
  height: 162px;
  border-radius: 16px;
  background: var(--Yellow, #FFF);
  position: relative;
  margin-right: 25px;
}
.proportalHome-icon-text {
  position: absolute;
  width: 131.789px;
  height: 35.781px;
  flex-shrink: 0;
  color: #979797;
  text-align: center;
  margin-top: 100px;
  margin-left: -10px;
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.proportalHome-icon-text-Dashboard {
  position: absolute;
  width: 131.789px;
  height: 35.781px;
  flex-shrink: 0;
  color: #979797;
  text-align: center;
  margin-top: 100px;
  margin-left: -16px;
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.slider-container {
  position: relative;
  z-index: 1;
}

.qr-icon-container {
  position: absolute;
  top: 10vh;
  left: 79vw;
  //width: 233px;
  //height: 613.367px;
  width: 8vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.QR-icon {
  //width: 233px;
  //height: 613.367px;
  width: 12vw;
  height: 60vh;
  border-radius: 16px;
  background: var(--Yellow, #FFF);
  flex-shrink: 0;
}

.qr-text {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 2.0vh;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  bottom: 50vh;
  transform: translateY(80%);
  white-space: pre-line;
  text-align: left;
  left: 0.10vw;
}

.icon-hover-effect:hover, .icon-hover-effect:active {
  /* 示例效果：改变背景颜色和文本颜色 */
  //background-color: #4e8cde; /* hover时的背景色 */
  //color: #333; /* hover时的文本颜色 */
  cursor: pointer; /* 显示手型光标表示可点击 */
  /* 可以添加其他效果，如边框、阴影等 */
}

// style.scss
.icon-hover-effect {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; // 添加平滑过渡效果

  &:hover {
    transform: scale(1.05); // 当鼠标悬停时稍微放大
    opacity: 1.0; // 并降低透明度以产生视觉上的变化   //0.7
  }
}
