.flexContainer {
  display: flex; /* 设置为flex布局 */
  justify-content: space-between; /* 在两个主要区域之间添加空间 */
}

.leftPanel, .rightPanel {
  flex: 1; /* 让两个panel平分可用空间 */
  margin: 20px; /* 添加一些间距 */
}
.PortalAssignDevices {
  width: 601.535px;
  height: 302.186px;
  flex-shrink: 0;
  border-radius: 11px;
  border: 0px solid #999;
  background: #0E477A;
  margin-top: 20vh;
  margin-left: 20vw;
}
.PortalAssignDevicesTitle1 {
  color: #FFF;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 1vw;
  padding-top: 2vh;
}
.PortalAssignDevicesLable1 {
  display: block; /* 使标签显示为块状元素，占满整行 */
  color: #FFF;
  font-family: "Proxima Nova";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1vw;
  padding-top: 2vh;
  padding-bottom: 1vh;
}
.PortalAssignDevicesInput1 {
  display: block; /* 使标签显示为块状元素，占满整行 */
  width: 207.438px;
  height: 30.717px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0px solid #FFF;
  background: #F4F4F4;
  margin-top: 10px;
}

.PortalAssignDevicesLable2 {
  display: block; /* 使标签显示为块状元素，占满整行 */
  color: #FFF;
  font-family: "Proxima Nova";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1vw;
  padding-top: 2vh;
}
.PortalAssignDevicesInput2 {
  display: block; /* 使标签显示为块状元素，占满整行 */
  width: 207.438px;
  height: 30.717px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0px solid #FFF;
  background: #F4F4F4;
  margin-top: 10px;
}
.PortalAssignDevicesTitle2 {
  color: #FFF;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 1vw;
  padding-top: 2vh;
}
.PortalAssignDevicesLable3 {
  display: block; /* 使标签显示为块状元素，占满整行 */
  color: #FFF;
  font-family: "Proxima Nova";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1vw;
  padding-top: 2vh;
  padding-bottom: 1vh;
}
.PortalAssignDevicesInput3 {
  display: block; /* 使标签显示为块状元素，占满整行 */
  width: 207.438px;
  height: 30.717px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0px solid #FFF;
  background: #F4F4F4;
  margin-top: 10px;
}
.PortalAssignDevicesLable4 {
  display: block; /* 使标签显示为块状元素，占满整行 */
  color: #FFF;
  font-family: "Proxima Nova";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1vw;
  padding-top: 2vh;
}
.PortalAssignDevicesInput4 {
  display: block; /* 使标签显示为块状元素，占满整行 */
  width: 207.438px;
  height: 30.717px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0px solid #FFF;
  background: #F4F4F4;
  margin-top: 10px;
}
.assignDevicesButton {
  width: 512.179px;
  height: 35.09px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #FFF;
  background: rgba(244, 244, 244, 0.00);
  color: #FFF;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 2vw;
}
.divider {
  width: 1px; /* 分割线的宽度 */
  background-color: #FFF; /* 分割线的颜色，这里用白色 */
  margin: 40px 20px 20px ; /* 在分割线两边添加一些水平间距 */
  align-self: stretch; /* 让分割线垂直拉伸以填充整个父容器的高度 */
  height: 20vh;
}
