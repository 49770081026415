.whole_div{
    width: 50%;
    margin: 0 auto;
    margin-top: 4.375rem;
    padding: 0 1.25rem;

}
@media only screen and (max-width: 600px){
    .whole_div{
        width: 90%;
    }
}

.title_logo {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-bottom: 5.625rem;
}

.sub_title{
    font-family: 'Proxima Nova', sans-serif !important;
    font-size: 1rem;
    font-weight: 700;
    color: #333;
    line-height: 1.5;
    margin: 0 0 1.875rem 0;
    margin-bottom: 0.5rem !important;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
}

.sub_sub_title{
    font-family: 'Proxima Nova', sans-serif !important;
    font-size: 0.75rem;
    font-weight: 700;
    color: #333;
    line-height: 1.5;
    margin: 0 0 1.875rem 0;
    margin-bottom: 0.5rem !important;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
}

.content{
    font-family: 'Proxima Nova', sans-serif !important;
    font-size: 0.75rem;
    font-weight: 300;
    color: #777;
    line-height: 1.5;
    margin: 0 0 1.875rem 0;
    margin-bottom: 0.5rem !important;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
}

.table_li{
    font-family: 'Proxima Nova', sans-serif !important;
    font-size: 0.75rem;
    font-weight: 300;
    color: #6495ED;
    line-height: 1.5;
    margin: 0 0 1.875rem 0;
    margin-bottom: 0.5rem !important;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    cursor: pointer;
}

.announcement{
    font-family: 'Proxima Nova', sans-serif !important;
    font-size: 1rem;
    font-weight: 300;
    color: black;
    text-align: center;
    line-height: 1.5;
    margin: 0 0 1.875rem 0;
    margin-bottom: 0.5rem !important;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
}

