.background-container {
  background-image: url('./imgs/Background Image.webp');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.icon-container {
  position: absolute;
  left: 5vw;
  top: 8vw;
  flex-shrink: 0;
}

.first-row-icons, .second-row-icons, .third-row-icons {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

.Subscription-icon, .Orders-icon, .Settings-icon {
  display: flex;
  width: auto;
  height: 10rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.icon-text {
  position: absolute; /* 绝对定位文字 */
  left: 9%; /* 根据需要调整这个值，使文字靠左 */
  top: 80%;
  transform: translateY(-50%); /* 垂直居中 */
  width: 131.789px;
  height: 35.781px;
  flex-shrink: 0;
  color: #979797;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.hello-admin {
  position: absolute;
  left: 5.6vw;
  top: 2vw;
  z-index:2;
  width: 632px;
  height: 99px;
  flex-shrink: 0;
  color: var(--Yellow, #FFF);
  font-family: Proxima Nova, sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.terms-container {
  position: absolute;
  right: 150px; /* Adjust the value as needed */
  bottom: 150px; /* Adjust the value as needed */
  z-index: 10; /* Ensure it's above other content */
}

.terms-text:hover {
  color: #d3af1f;
}

.terms-text {
  display: block; /* Stack them vertically */
  color: var(--Yellow, #FFF);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-bottom: 4px; /* Space between the links */
}
.Pro-copyright {
  position: absolute;
  right: 150px;
  bottom: 60px;
  z-index: 10;
}
.Pro-copyright-text {
  text-align: right;
  color: var(--Yellow, #FFF);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: none;
}

/* 基础样式 */
.icon-container div {
  cursor: pointer; /* 将鼠标指针变为手形，表示可点击 */
  //transition: background-color 0.3s ease; /* 添加过渡效果使背景色变化更平滑 */
}

///* 悬停效果 */
//.first-row-icons:hover, .second-row-icons:hover, .third-row-icons:hover {
//  background-color: #f0f0f0; /* 悬停时的背景色 */
//}

///* 你也可以为每个图标单独设置不同的悬停效果 */
//.first-row-icons:hover {
//  background-color: #e0e0e0; /* 第一个图标悬停时的背景色 */
//}
//
//.second-row-icons:hover {
//  background-color: #d0d0d0; /* 第二个图标悬停时的背景色 */
//}
//
//.third-row-icons:hover {
//  background-color: #c0c0c0; /* 第三个图标悬停时的背景色 */
//}

.icon-hover-effect {
  transition: opacity 0.3s ease; // 平滑过渡效果
  cursor: pointer; // 鼠标悬停时显示为指针，明确表示这是一个可点击的元素

  &:hover {
    opacity: 1.0; // 鼠标悬停时降低图片的透明度
  }
}
