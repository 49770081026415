.home-logo-icons {
  width: 15vw;
  margin: 0 -2vw -4vh -2vw;
  //cursor: pointer;
}
.home-logo-row{
  margin: 0 auto;
  width: 60%;
}


@media only screen and (max-width: 600px) {
  .home-logo-icons {
    width: 25.5vw;
    margin: 0vh 0 -129px 0;
  }
  .home-logo-row {
    margin: -10vh auto 0 auto;
    width: 100%;
  }
  .home-next-logo-row{
    margin-top: 12vh!important;
  }
}

.app-button {
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  width: 60%;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border: 2px solid black;
  border-radius: 10px;
  font-family: 'Proxima Nova', sans-serif;
  box-shadow: inset 0 0 0 0 #2f1717;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  margin-top: 1em;
  font-weight: 800;
  padding: 1em 0 1em 0;
  color: black;
  background-color: white;
}

.app-button:hover {
  //box-shadow: inset 1080px 0 0 0 #ffffff;
  color: white;
  background-color: black;
}


.prize-btn {
  position: absolute;
  top: 16%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #70c4c4;
  color: #ffffff !important;
  font-size: 1.25rem;
  padding: 0.7rem 1.7rem;
  border: 0.1rem solid transparent;
  cursor: pointer;
  text-align: center;
  border-radius: 0.5rem;
}

.prize-btn:hover {
  color: #fff;
  background-color: #4ba09d;
  border-color: #4ba09d;
}

.desktopImage {

}

.mobileImage {
  display: none;
}

@media only screen and (max-width: 600px) {
  .mobileImage {
    display: initial;
  }

  .desktopImage {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .prize-btn {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #70c4c4;
    color: #ffffff !important;
    font-size: 0.9rem;
    padding: 0.3rem 0.3rem;
    border: 0.1rem solid transparent;
    cursor: pointer;
    text-align: center;
    border-radius: 0.5rem;
  }

  .title {
    font-size: 30px;
  }
}

.title {
  font-family: 'Proxima Nova bold', sans-serif !important;
  font-size: 36px;
  font-weight: 700;
  color: #333;
  line-height: 1.5;
  margin: 0 0 30px 0;
  margin-bottom: 0.5rem !important;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  text-align: center !important;
}

.title-logo {
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  letter-spacing: 0.1em;
  color: #000;
}

.title-app {
  font-family: 'Proxima Nova Regular', sans-serif !important;
  font-size: 32px;
  font-weight: 700;
  color: black;
  line-height: 1.5;
}


.text-content {
  display: block;
  margin-top: 10px;
  font-weight: 300;
  color: #777;
  font-size: 1.25rem;
  text-align: center !important;
  font-family: 'Proxima Nova', sans-serif !important;
  line-height: 1.5;
}

.logo-img {
  width: 50%;
  filter: grayscale(100%); /* Make the SVG grayscale */
  transition: filter 0.3s ease; /* Add a transition effect for smooth hover */
  opacity: .7;
}

.m-logos {
  display: none;
}

.logo-img:hover {
  filter: none; /* Remove the grayscale filter on hover */
  cursor: pointer;
  opacity: 100%;
}


@media only screen and (max-width: 600px) {
  .logo-row {
    padding: 0 0 4rem 0 !important;
  }
  .dt-logos {
    display: none;
  }
  .m-logos {
    display: initial;
  }
  .text-content {
    font-size: 14px;
  }
  .title-app {
    margin-top: 10%;
  }
  .logo-img {
    width: 80%;
  }
}

.text-content-app {
  display: block;
  margin-top: 10px;
  font-weight: 300;
  color: black;
  font-size: 1rem;
  text-align: left;
  font-family: 'Proxima Nova Regular', sans-serif;
  line-height: 1.5;
  margin-bottom: 3px;
}

.watch-full-video {
  color: #000;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 25px;
  text-decoration-line: underline;
}

.top-cover {
  transform: translate(-38%, -210%);
  cursor: pointer;
}

.video-container {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  text-align: center;
}

.text-container-center-home {
  padding-top: 200px;
}

@media only screen and (max-width: 900px) {
  .text-container-center-home {
    padding-top: 10px;
  }
  .top-cover {
    transform: translate(-28%, -210%);
    cursor: pointer;
  }
  .m-small-txt {
    font-size: 15px !important;
  }
}

.cover-btn {
  position: absolute;
  width: 152px;
  transform: translate(-210%, -180%);
  -ms-transform: translate(-200%, -180%);
  background-color: #ffffff;
  color: #170e0e;
  font-size: 16px;
  padding: 8px 24px;
  border: none;
  cursor: pointer;
  border-radius: 18px;
  text-align: center;
  font-weight: bold;
}

.about-vieunite-row {
  margin: 2rem 0 5rem
}

.phone-row {
  margin: 2rem 0 4rem;
}

@media only screen and (max-width: 600px) {
  .about-vieunite-row {
    margin: 1rem 3rem 3rem;
  }

  .phone-row {
    margin: 2rem 3rem 4rem;
  }
}

@media only screen and (max-width: 600px) {
  .cover-btn {
    position: absolute;
    top: -16px;
    left: 220%;
    width: 140px;
    height: 30px;
    transform: translate(-25%, -200%);
    -ms-transform: translate(-20%, -200%);
    background-color: #ffffff;
    color: #170e0e;
    font-size: 13px;
    padding: 0 3px;
    border: none;
    cursor: pointer;
    border-radius: 18px;
    text-align: center;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1750px) {
  .cover-btn {
    transform: translate(-180%, -150%);
    -ms-transform: translate(-160%, -150%);
  }
}

@media only screen and (max-width: 1700px) {
  .cover-btn {
    transform: translate(-160%, -150%);
    -ms-transform: translate(-160%, -150%);
  }
}

@media only screen and (max-width: 1400px) {
  .cover-btn {
    transform: translate(-130%, -150%);
    -ms-transform: translate(-130%, -150%);
  }
}

@media only screen and (max-width: 1200px) {
  .cover-btn {
    transform: translate(-110%, -130%);
    -ms-transform: translate(-110%, -130%);
  }
}

.quote-text {
  color: #777;
  font-size: 1.55rem;
  font-weight: bold;
  margin-top: 10px;
  font-style: italic;
}

.quote-container > .ant-image {
  height: inherit !important;
}

/*.ant-card-meta-title{*/
/*  font-size: 20px;*/
/*}*/
.ant-card-meta-description {
  font-family: 'Proxima Nova', sans-serif;
}

.ant-card-meta-title {
  text-align: center;
  font-size: 20px;
  white-space: initial;
}

.ant-card-meta-description {
  text-align: center;
}

.mobile-download-text {
  color: #343434;
  display: block;
  font-family: Proxima Nova, sans-serif !important;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 10px;
}

.dt-download-app {

}

.m-download-app {
  display: none;
}

@media only screen and (max-width: 920px) {
  .dt-download-app {
    display: none;
  }
  .m-download-app {
    display: initial;
  }
  .m-img-text {
    flex-direction: column-reverse;
    align-content: center;
  }
  .mtop {

  }
  .m-at {
    flex-direction: column;
  }
  .mobile-left {
    text-align: left !important;
  }
  .no-left-margin {
    margin-left: 0 !important;
    margin-top: 2rem;
  }
  .mtop {
    margin-top: -1%;
  }
  .m-none {
    display: none;
  }
  .m-padding-bottom {
    padding-top: 2rem !important;
    padding-bottom: 3rem !important;
  }
  .m-padding {
    padding: 2rem 0 0 0 !important;
  }
  .m-phone {
    top: -20% !important;
    right: 0 !important;
  }
  .m-col {
    padding: 13rem 0 3rem 0;
  }
  .m-tt {
    margin-top: 14% !important;
  }
  .m-100 {
    width: 100% !important;
  }
}