.pro-footer-div {
  width: 100vw;
  height: 19.25rem;
  margin-bottom: -10rem !important;
  //height: auto;
  flex-shrink: 0;
  background: #0E487A;
  display: flex;
  justify-content: space-between;
}
.m-pro-footer-div {
  width: 100vw;
  height: 312px;
  //height: auto;
  margin-bottom: -10rem !important;
  background: #0E487A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pro-footer-nav {
  width: 15.6875rem;
  flex-shrink: 0;
  color: #FFF;
  text-align: right;
  font-family: Proxima Nova, sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 65%;
  cursor: pointer;
}
.m-pro-footer-nav {
  width: 60vw;
  flex-shrink: 0;
  color: #FFF;
  text-align: left;
  font-family: Proxima Nova, sans-serif;
  font-size: 4.6vw;
  font-style: normal;
  font-weight: 500;
  line-height: 65%;
  cursor: pointer;
  margin: 1vh 0 1vh 10vw;
}

.pro-footer-small-txt {
  width: 20.375rem;
  height: 5.6875rem;
  flex-shrink: 0;
  color: #FFF;
  font-family: Proxima Nova, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0;
  margin: -4.8rem 0 0 7rem;
}
.m-pro-footer-divider{
  width: 90vw;
  height: 1px;
  background: #FFF;
  margin: 2vh 0 1vh 6vw;
  text-align: center;
}
.m-pro-footer-small-txt {
  width: 100vw;
  //height: 5.6875rem;
  flex-shrink: 0;
  color: #FFF;
  font-family: Proxima Nova, sans-serif;
  font-size: 3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 0;
  margin: 1vh 0 1vh 10vw;
}

.pro-footer-left-col {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
}