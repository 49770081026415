.my-orders-pro {
  margin-left: 7rem;
  margin-top: 3rem;
}
.my-orders-pro-title {
  color: #000;
  font-family: Proxima Nova,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.5;
  margin-left: -0.5rem;
}
.my-orders-table {
  width: 1500px;
  height: 800px;
  margin-top: 1.5rem;
}
.BackButtonIcon {
  margin-left: -1.5rem;
}