.ProPortalQA{
  margin-top: 2rem;
  height: 120rem;
  margin-left: 5rem;
}
.ProPortalQA-title {
  color: var(--Vieunite-Pro-Blue, #0E477A);
  font-family: Proxima Nova, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ProQAPanel {
  width: 80vw;
  //height: 59px;
  flex-shrink: 0;
  border-radius: 12px !important;
  background: var(--Yellow, #FFF);
  margin-bottom: 16px; /* 你可以根据需要调整这个值 */
}
.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .5rem;
  vertical-align: middle;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
  fill: #D0D0D0;
}
.arrow.up {
  border-width: 0 5px 5px;
  border-color: transparent transparent #999;
  // This sets the arrow to point up when expanded
}
.custom-collapse {
  background: transparent;
  border: none;
}
.ProQAPanel .ant-collapse-header {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.faq-answer {
  white-space: pre-line;
}
.faq-answer a {
  text-decoration: underline;
}




