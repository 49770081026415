.charge-modal {
  .charge-md-title{
    font-family: "Proxima Nova bold", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0%;
  }
  .ant-modal-content{
    padding: 4rem;
    border-radius: 2rem;
  }
  .ant-modal-header {
    border: none;
  }

  .ant-modal-title {
    font-family: Proxima Nova bold, sans-serif;
    font-weight: 700;
    font-size: 3em;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    gap: 2rem;
    //padding-bottom: 4rem;
  }
}
@media only screen and (max-width: 600px){
  .charge-modal{
    .ant-modal-content{
      padding: 1rem;
      width: 100%;
    }
    .charge-md-title{
      font-size: 1.4rem;
    }
  }
}