.ds-table-col-hover:hover{
  color: #0E487A;
  font-weight: 600;
}

.ds-ml-schedule-modal-p {
  width: 20vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 10px auto -20px auto;
}

.hd-control-div {
  width: 10.8vw;
  height: 3.9vw;
  flex-shrink: 0;
  border-radius: 48px;
  background: #D9D9D9;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.hd-control-p {
  width: 3.6vw;
  //height: 43px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.625vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ds-empty-device-div {
  width: 80vw;
  background: white;
  height: 30vh;
  margin: auto;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.ds-empty-device-p {
  width: 723px;
  height: 65px;
  flex-shrink: 0;
  color: var(--Black, #000);
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.bp-nav {
  width: 331px;
  height: 110vh;
  flex-shrink: 0;
  opacity: 0.4;
  background: #0E487A;
}

.bp-nav-txt {
  margin-left: 10px;
  margin-top: 15px;
  width: 199.594px;
  //height: 28.477px;
  color: var(--Yellow, #FFF);

  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  //line-height: 24px; /* 150% */
}

.bp-ds-profile-txt {
  width: 167.047px;
  flex-shrink: 0;
  color: #727272;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 5px; /* 150% */
}

.bp-one-nav {
  display: flex;
  justify-content: center;
  //align-items: center;
  margin: 1rem 0 0 4rem;
  cursor: pointer;
}

.bp-ds-status-div {
  width: 515px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #BBB;
  background: var(--Yellow, #FFF);

  .bp-ds-status-2 {
    width: 503px;
    //height: 57px;
    margin: auto;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .nb {
      width: 149.817px;
      //height: 39px;
      flex-shrink: 0;
      color: #000;
      text-align: center;
      font-family: Proxima Nova, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:not(:disabled):hover {
        color: #A06174 !important;
      }
    }

    .vertical-line {
      width: 2px;
      height: 54.553px;
      background: #D9D9D9;
    }
  }

  .bp-ds-status-1 {
    width: 503px;
    height: 57px;
    flex-shrink: 0;
    border-radius: 2px 2px 0px 0px;
    background: #D9D9D9;
    margin: auto;
    margin-top: 5px;
    display: flex;
    //align-items: center;
    justify-content: space-evenly;

    .bp-ds-status-sm {
      width: 92.115px;
      height: 46.156px;
      flex-shrink: 0;

      .bp-ds-status-title {
        width: 92.115px;
        flex-shrink: 0;
        color: #000;
        text-align: center;
        font-family: Proxima Nova, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 4px;
      }

      .bp-ds-status-txt {
        width: 92.115px;
        flex-shrink: 0;
        color: #000;
        font-family: Proxima Nova, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-top: -1rem;
        text-align: center;
      }
    }

  }
}

.bp-search-btn {
  width: 56px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #0E487A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bp-ds-ctrl-div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 35%;
  margin: 2rem 0 -4rem 1rem;
}

.bp-ds-input {
  display: flex;
  width: 16vw;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  //gap: 10px;
  margin-left: -1vw;
  flex-shrink: 0;
  border-radius: 6px;
  border: 2px solid #BBB;
  background: var(--White, #FFF);
}

.ds-dropdown {
  .ant-dropdown-menu {
    width: 188px;
    height: auto;
    flex-shrink: 0;
    border-radius: 8px;
    border: none;
    //background: #FFF;
  }

  width: 188px;
  height: 204px;
  flex-shrink: 0;
  border-radius: 8px;
  //border: 1.5px solid #A4A4A4;
  //background: #FFF;

  .ant-dropdown-menu-title-content {
    display: flex;
    width: 177px;
    height: 48px;
    padding: 12px 16px;
    justify-content: start;
    text-align: left;
    align-items: flex-start;
    gap: 79px;
    flex-shrink: 0;
    border-radius: 8px;
    //background: #FFFEFE;
    //color: var(--Gray-5, #555);
    /* Table body */
    font-family: Proxima Nova, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    :hover {
      //background: #F5F5F5;
    }
  }
}

.ds-ml-new-schedule-txt {
  width: 449px;
  height: 38px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 127.273% */
  margin: -40px auto 16px auto;
}

.bp-ds-table {
  // Target the ant table rows for hover state
  .ant-table-tbody > tr:hover > td {
    cursor: pointer;
  }

}

.ds-ml-remind-modal-final-ok {
  width: 8vw;
  height: 5vh;
  border-radius: 6px;
  background: #0E487A;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 6vh 0 0 9.5vw
}

.bp-ds-ctrl-btn {
  width: 6.5vw;
  height: 40px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #6E91AF;
  color: white;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ant-switch-checked {
  background: #0E487A;
}

.ds-ml-control-modal {
  .ds-ml-schedule-modal-h {
    width: 509px;
    //height: 54.344px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 0rem 0;
  }

  .hard-week-final-btn-mul {
    width: 200px;
    height: 46px;
    border-radius: 6px;
    background: #0E487A;
    color: #FFF;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ant-modal-content {
    width: 561px;
    height: 300px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFF;
    margin: 20vh 0 0 0;
  }
}

.jsb-angle-span {
  font-size: 0.6vw;
  margin-left: -1.2vw;
  font-weight: 300;
}

.jsb-angle {
  display: flex;
  flex-direction: column;
}

.ds-ml-settings-modal {

  .jsb-txt {
    margin-top: 10px;
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }


  .jsb-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hd-settings-txt-2 {
    width: 50%;
    //height: 19px;
    flex-shrink: 0;
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hd-settings-ip-2 {
    .ant-input-number {
      display: flex;
      width: 10vw;
      height: 40px;
      padding: 8px 16px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //gap: 10px;
      //flex-shrink: 0;
      border-radius: 6px;
      border: 2px solid #BBB;
      background: var(--White, #FFF);
      color: #000;
      font-family: Proxima Nova Regular, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 0.5vw;
    }
  }

  .ds-ml-schedule-modal-h {
    width: 509px;
    //height: 54.344px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 0rem 0;
  }

  .hard-week-final-btn-mul {
    width: 200px;
    height: 46px;
    border-radius: 6px;
    background: #0E487A;
    color: #FFF;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ant-modal-content {
    width: 561px;
    height: 56vh;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFF;
    margin: 20vh 0 0 0;
  }
  .ant-modal-close-x{
    display: none;
  }
}

.ds-ml-remind-modal {
  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ant-modal-content {
    width: 30vw;
    height: auto;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFF;
    margin: 20vh 0 0 0;
  }

  .ds-ml-remind-modal-h {
    width: 20vw;
    height: auto;
    color: #000;
    text-align: center;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    font-family: Proxima Nova Regular, sans-serif;
    margin: auto
  }

  .ds-ml-remind-modal-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ds-ml-schedule-modal {
  .ds-ml-schedule-modal-h {
    width: 509px;
    height: 69.344px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .hard-week-row-mul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0.2rem 0 4rem 0;
    width: 100%;

    .ant-picker {
      width: 180px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 14px;
      border: 2px solid #727272;
      background: #FFF;
    }
  }

  .hard-week-final-btn-mul {
    width: 200px;
    height: 46.524px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #0E487A;
    color: #FFF;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -20px;
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ant-modal-content {
    width: 561px;
    height: 667px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFF;
  }
}

.ds-ml-new-album-modal {
  .ant-modal-content {
    width: 1006px;
    height: 685px;
    flex-shrink: 0;
    border-radius: 12px;
    opacity: 0.97;
    background: #2E2E2E;
    margin: 15% 0 0 -30%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .ds-preview-h1 {
    color: #FFF;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 60% */
  }

  .ds-preview-h2 {
    color: #FFF;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
  }

  .ds-preview-img {
    width: auto;
    height: 485px;
    //margin: 0 30%;
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ml-new-album-modal-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px;
  }

  .ml-new-album-modal-txt {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 653px;
    flex-shrink: 0;
    text-align: center;
    margin: auto;
  }

  .ml-new-album-modal-sub-title {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-shrink: 0;
    margin: 2rem 0 0 0;
  }

  .ml-new-album-modal-input {
    width: 604px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 9px;
    background: #E2E2E2;
    margin: 1rem 0 0 12%;
  }

  .ml-new-album-modal-btn {
    border-radius: 6px;
    background: #0E487A;
    width: 260px;
    height: 56px;
    flex-shrink: 0;
    margin: 2rem 0 0 35%;
    color: var(--Yellow, #FFF);
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 16px; // Adjust spacing as needed
    }
  }
}