.m-explore-p-h{
  width: 90vw;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4.6vw;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 75% */
  margin: 4vh auto;
}
.m-explore-p-txt{
  text-align: center;
  width: 90vw;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 3.6vw;
  font-style: normal;
  font-weight: 400;
}
.m-explore-p-end{
  text-align: center;
  width: 90vw;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.m-exp-btn{

}
.exp-title {
  margin-top: 0.1vh;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.88vw;
  font-style: normal;
  font-weight: 400;
}
.exp-txt {
  width: 30vw;
  height: 16vh;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  //margin-top: 2vh;
  margin-bottom: 5vh;
}

.exp-btn {
  width: 26vw;
  height: 7vh;
  flex-shrink: 0;
  border-radius: 0.75rem;
  border: 0px solid #FFF;
  background: #A06174;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1vh auto;
}
.exp-div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}