.portal-group-remind-modal {

  .ant-modal-content {
    width: 22vw;
    height: 20vh;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Yellow, #FFF);
    margin: auto;
    margin-top: 20vh;
  }
  .ant-modal-close-x{
    display: none;
  }
  .j-repeat-s-btn{
    width: 6vw;
    height: 4.2vh;
    flex-shrink: 0;
    border-radius: 6px;
    background: #0E487A;
    color: #FFF;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: auto;
  }
  .j-repeat-s-p{
    width: 20vw;
    //height: 94px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 1vh auto 3vh auto;
  }

  .ant-modal-title {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 16px; // Adjust spacing as needed
    }
  }
}


.ProportalGroup {
  margin-top: 1rem;
  margin-left: 7rem;
  //height: 50rem;
  padding-Bottom: 10rem;
}

.portal-group-final-button {
  width: 166px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #0E487A;
  margin: 0 0 0 0;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.GroupNameSearch {
  width: 50%;
  margin: 4vh 0 0 0;

  .ant-input {
    display: flex;
    width: 16vw !important;
    padding: 8px 16px;
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid #BBB;
    background: var(--White, #FFF);
  }
}

.portal-group-ds {
  margin: 4vh 0 0 0;
  width: 80vw;
  height: auto;
  flex-shrink: 0;
  border-radius: 6px;
  background: #FFF;

  .portal-group-ds-title {
    width: 14vw;
    margin: 4vh 0 4vh 2vw;
    height: 28px;
    flex-shrink: 0;
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.GroupNameButton {
  margin-top: 20px;
}

.GroupNameTree {
  margin-top: 20px;
}

.highlight {
  background-color: #198df5
}

.portal-group-btn {
  border-radius: 5px;
  background: #0E487A;
  width: 6vw;
  height: 34px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.portal-group-btn-canvas {
  border-radius: 5px;
  background: #0E487A;
  width: 11vw;
  height: 34px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.portal-group-p {
  display: flex;
  width: 554px;
  height: 75px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.75;
  margin: 0 0 0 0;
}
.header3 {
  display: flex;
  align-items: center; /* 垂直对齐 */
  margin-top: 0.1rem;
}
.BackButtonIcon4 {
  margin-left: -6.5rem;
  margin-top: 0.1rem;
  cursor: pointer;
}