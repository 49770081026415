.PaymentAgreement-terms-conditions-container {
  margin-left: 5rem;
  margin-right: 5rem;
}
.PaymentAgreementButton {
  width: 196.531px;
  height: 49px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #A06174;
  color: rgba(255, 255, 255, 0.95);
  text-align: center;
  font-family: Proxima Nova,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 50rem;
  margin-bottom: 5rem;
  margin-top: 5rem;
}