.artists-seeall-searchbar-title {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 24px;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
  width: 11vw;
}

.artists-name-in-bottom-list {
  width: 100%;
  cursor: pointer;
  font-weight: 700;
  margin-right: 30px;
  /* margin-bottom: 30px; */
}

.artists-name-in-bottom-list-center {
  text-align: center;
}

.artists-name-in-bottom-list:hover {
  color: var(--Vieunite-Pro-Blue, #0E477A);
  text-decoration: underline;
}

.discover-search-button {
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--Vieunite-Pro-Blue, #0E477A);
  /* color: #FFF; */
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}