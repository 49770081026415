.ProTutorial {
  margin-top: 2rem;
  margin-left: 5rem;
  height: 65rem;
}

.ProTutorial-title {
  color: var(--Vieunite-Pro-Blue, #0E477A);
  font-family: Proxima Nova, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.video-and-controls {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.video-section {
  margin-right: 20px;
}

.ProTutVideo {
  display: block;
  margin-bottom: 20px;
}

.video-description {
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
}

.video-controls-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  margin-left: 2rem;
}

.PortalTut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 237.31px;
  height: 156.188px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  background: #FFF;
  cursor: pointer;
  transition: transform 0.2s;
}

.PortalTut:hover {
  transform: scale(1.05);
}

.PortalTut span {
  display: block;
  margin-bottom: 0.5rem;
}

.ProtalTut-Sub {
  opacity: 0.75;
  color: #000;
  font-family: "Proxima Nova";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PortalTut::before {
  content: '▶';
  font-size: 1.5rem;
  display: block;
  margin-bottom: 0.5rem;
}
.TutSubT {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.75;
}
.TutSubT1 {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.TutSubT2 {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.TutSubT4 {
  color: #FFF;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  width: 193.324px;
  height: 39.922px;
  flex-shrink: 0;
}