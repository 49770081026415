.ProAccountDetails {
  margin-top: 1.5rem;
  height: 50rem;
  margin-left: 5rem;
}

.ProAccountDetails-title {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.PortalAccountDetails-title {
  color: #000;
  //text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.75;
  margin-top: 0.5rem;
  margin-left: 1rem;
}



.MD-form-group-input {
  width: 322.384px;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 2px solid #BBB;
  background: var(--White, #FFF);
  //margin-left: 1px;
}

.MD-form-group-select {
  width: 322.384px;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 2px solid #BBB;
  background: var(--White, #FFF);
  //margin-left: 30px;
  color: var(--Grey-01, #AAA);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.74;
}

.MD-form-group-text {
  color: #727272;
  font-family: Proxima Nova, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 9rem;
  margin-top: 10px;
}

.PortalAccountDetails-content {
  color: #000;
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 40px;
  margin-left: 1rem;

}

.profile-picture-button {
  width: 213px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #D9D9D9;
  color: #000;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 12rem;
  margin-top: 30px;
}

.MD-Save-button {
  width: 189px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--V-Pro-Blue, #0E487A);
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 36rem;
  margin-top: 10rem;
}

.profile-picture {
  display: flex;
  width: 348px;
  height: 381px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 3px solid rgba(114, 114, 114, 0.10);
  background: rgba(255, 255, 255, 0.00);
  margin-top: 70px;
  margin-left: 120px;
}

.Camera-icon {
  width: 100%; /* 使图片宽度充满容器 */
  height: 100%; /* 使图片高度充满容器 */
  object-fit: cover; /* 保证图片比例不变且完全覆盖容器区域，也可以使用其他值如 contain 等 */
}

.default-icon {
  display: flex;
  width: 150px;
  height: 150px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 80px;
  margin-top: 100px;
}

.subscriptionLink {
  color: #0E487A; /* 您可以设置为任何颜色 */
}
.header3 {
  display: flex;
  align-items: center; /* 垂直对齐 */
  margin-top: 1rem;
}

.BackButtonIcon8 {
  margin-left: -5rem;
  margin-top: -1.5rem;
  cursor: pointer;
}







.MD-form-group1 {
  margin-top: 60px;
  padding-top: 10px;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.MD-form-group2 {
  margin-top: 10px;
  padding-top: 10px;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.MD-form-group3 {
  margin-top: 10px;
  padding-top: 10px;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.MD-form-group4 {
  margin-top: 10px;
  padding-top: 10px;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

.MD-form-group-label {
  color: var(--Black, #000);
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
}
.account-details-content {
  display: flex;
  align-items: center; /* Aligns items vertically centered */
  justify-content: space-between; /* Space between the name and the button */
  width: 100%; /* Ensures the container takes full width */
}

.AccountDetailsName {
  flex-grow: 1; /* Allows the name to take up remaining space */
  margin-right: 10px;
  opacity: 0.75;
}

.inner-content {
  display: flex;
  align-items: center;
  gap: 28rem; /* 控制元素之间的间距 */
}
.inner-content-subscription {
  display: flex;
  align-items: center;
  gap: 26rem; /* 控制元素之间的间距 */
}
.inner-content-password {
  display: flex;
  align-items: center;
  gap: 26rem; /* 控制元素之间的间距 */
}
.ChangeAccountDetailsButton {
  color: #000;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 200.107px;
  height: 36.169px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #999;
  background: #F4F4F4;
}
hr, .horizontal-line {
  width: 697.599px;
  height: 1px;
  background: #959595;
  margin: 10px 0; /* 上下边距 */
}

.account-pt-profile-div-new{
  width: 47.082px;
  height: 47.082px;
  flex-shrink: 0;
  border-radius: 50%;
}
.account-pt-profile-div-img-new{
  width: 96.086px;
  height: 96.086px;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: pointer;
}
.account-pt-profile-div-avatar-out-new{
  width: 47.082px;
  height: 47.082px;
  border-radius: 50%;
  background: white;
  border: #0E487A 4px solid;
}
.account-pt-profile-div-avatar-new{
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0E477A;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Proxima Nova bold, sans-serif;
  color: white;
  font-size: 1.2vw;
  cursor: pointer;
  margin: auto;
  margin-top: 2.0px;
}
.account-profile-container {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  gap: 5rem; /* 设置元素之间的间隙 */
}

.account-pt-profile-div-new {
  /* 如果需要，这里可以添加样式以调整布局 */
}

.profile-avatar-button {
  margin-top: 2rem;
  padding: 4px 16px;
  cursor: pointer; /* 确保按钮有指针光标 */
  color: #000;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 136.14px;
  height: 36.169px;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.assign-devices-container {
  margin-top: 20px; /* Add some spacing from the previous elements */
  width: 316.958px;
  height: 169px;
  flex-shrink: 0;
  border-radius: 11px;
  border: 0px solid #999;
  background: #0E477A;
}
.assign-devices {
  padding-left: 3.5rem;
  margin-top: -5px;
}
.assign-devices-title {
  color: #FFF;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 171.223px;
  height: 36.175px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  padding-top: 0.1rem;
  padding-left: 1.5rem;
}
.assign-devices-text {
  color: #FFF;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 197.591px;
  height: 31.528px;
  flex-shrink: 0;
  padding-top: 0.1rem;
}
.assignlogo {
  width: 57px;
  height: 57px;
  flex-shrink: 0;
  margin-left: 8rem;
  margin-top: 0.5rem;
}
.Assign-devices-button {
  width: 297.698px;
  height: 23.582px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #FFF;
  background: rgba(244, 244, 244, 0.00);
  color: #FFF;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: -3rem;
  margin-top: 5px;
}