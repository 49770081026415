.pro-plans-title{
    font-family: 'Proxima Nova Regular', sans-serif;
}
.home-pro-section-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 3vw;
    width: 33rem;
    /*background: red;*/
}

.home-pro-section-title-mobile {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    width: 100%;
    margin: 0;
    padding: 0 9vw;
    /*background-color: green;*/
    /*margin: 0 3vw;*/
}

.custom-rounded-modal .ant-modal-content {
    border-radius: 1vw !important;
    overflow: hidden;
}

.ProSubFAQ {
    color: #000;
    text-align: center;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.subscription-container-mobile{
    display: none;
}

@media only screen and (max-width: 900px) {
    .subscription-container-mobile {
        display: initial;
        width: 100%;
    }
    .subscription-container {
        display: none;
    }
}
.pro-subscription-FAQ {
    padding-top: 2rem;
    margin-bottom: 5rem;
}

/* Change the outer circle border color when the radio button is selected (checked) */
.ant-radio-wrapper .ant-radio-checked .ant-radio-outer {
    border-color: #000 !important; /* Outer circle border color (black) */
}

/* Change the inner circle color when selected */
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner:after {
    background-color: #06467a !important; /* Inner circle color (blue) */
}

/* Change the outer circle border color when the radio button is focused */
.ant-radio-input:focus + .ant-radio-inner {
    border-color: #000 !important; /* Outer circle border color on focus (black) */
}

/* Optional: Change the border color of the outer circle when the radio button is hovered */
.ant-radio-wrapper:hover .ant-radio-outer {
    border-color: #000 !important; /* Ensure outer circle border is black on hover */
}









