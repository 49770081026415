.pro-curated-head-p{
  width: 40vw;
  flex-shrink: 0;
  color: #FFF;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Blue-homeMainButton {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #06467A;
  border: 0.1rem solid white;
  border-radius: 0.313rem;
  cursor: pointer;
  text-align: center;
  margin: .5rem 0 0 0;
  padding: 0.75rem 2.25rem;
  box-shadow: inset 0 0 0 0 white;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.Blue-homeMainButton:hover {
  box-shadow: inset 62.5rem 0 0 0 white;
  color: black;
}
.Blue-homeMainButton:hover .homeMainButtonLnk{
  color: black;
}

.to-Blue-homeMainButton {
  font-size: 1rem;
  font-weight: bold;
  color: black;
  background-color: white;
  border: 0.1rem solid white;
  border-radius: 0.313rem;
  cursor: pointer;
  text-align: center;
  margin: .5rem 0 0 0;
  padding: 0.75rem 2.25rem;
  box-shadow: inset 0 0 0 0 #06467A;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.to-Blue-homeMainButton:hover {
  box-shadow: inset 62.5rem 0 0 0 #06467A;
  color: black;
}
.to-Blue-homeMainButton:hover .homeMainButtonLnk{
  color: white;
}

.home-pro-section-5-text-container {
  margin-right: 4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Proxima Nova Regular, sans-serif;
  cursor: default;

  .collapsible {
    height: 120px; /* initial height */
    overflow: hidden;
    transition: height .4s ease, opacity .4s ease;
    opacity: 1;
    //margin-left: 1.4rem;
  }

  .collapsible.collapsed {
    height: 0;
    opacity: 0;
  }

  .title1 {
    color: #000;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: default;
  }
  .body {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .title2 {
    color: #AF8462;
    margin: 1vw 0;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .line {
    height: 1px;
    width: 100%;
    background: #A9A9A9;
    margin: 0;
  }
  .body2 {
    margin: 1vw 0;
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
}

.home-pro-section-6-text-container {
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Proxima Nova Regular, sans-serif;
  cursor:default;

  .collapsible {
    height: 120px; /* initial height */
    overflow: hidden;
    transition: height .4s ease, opacity .4s ease;
    opacity: 1;
    //margin-left: 1.4rem;
  }

  .collapsible.collapsed {
    height: 0;
    opacity: 0;
  }

  .title1 {
    color: #000;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: default;
  }
  .body {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .title2 {
    color: #AF8462;
    margin: 1vw 0;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .line {
    height: 1px;
    width: 100%;
    background: #A9A9A9;
    margin: 0;
  }
  .body2 {
    margin: 1vw 0;
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
}

.proCurated-pic-title {
    //margin-left: 2rem;
  padding-bottom: 3rem;
}
.pro-curated-bottom {
  background-color: #F0F0F0;
  margin-bottom: 10rem;
}