.primary-font-footer{
    font-family: Proxima Nova Regular, sans-serif;
}
.blk{
    color: black!important;
}
.v-footer{
    background-color: #f4f4f4;
    position: relative;
    border-top: 25px solid white;
    color: #777777;
    font-family: Proxima Nova Regular, sans-serif;
}
.footer-text-container{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
}
.footer-spacer-class {
    flex-grow: 1;
    height: 10px;
}

.footer-text{
    font-family: Proxima Nova Regular, sans-serif;
}

.footer-container-mobile{
    display: none;
}


.footer-icon-text{
    display: flex;
    column-gap: 3vw;
    justify-content: flex-end;
}

.footer-contact{

}
@media only screen and (max-width: 900px) {
    .footer-container-mobile {
        display: initial;
        width: 100%;
    }

    .footer-container {
        display: none;
    }
    .footer-text-container{
        margin-left: 1.5rem;
        margin-bottom: 2rem;
    }
    .footer-contact{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -1rem;
    }
    .footer-icon-text{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
    }

}


