

@media only screen and (max-width: 600px){
  .jing-header-h {
    display: none!important;
  }
}

.pro-menu {
  display: flex;
  align-items: center;
  list-style-type: none; // Remove default marker
}

.pro-header {
  width: 99vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8vh;
  //margin-left: 1rem;
}

.top-blue-bar {
  margin-top: -10px;
  background-color: #0E487A; // Example blue color
  height: 4vh; // Adjust to your preference
  width: 120%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 20px; // Adjust as needed for spacing from the left edge
}

.tab {
  color: white; // Text color for the tabs
  margin-right: 20px; // Spacing between tabs
  text-decoration: none;
  padding: 10px;
  height: 3vh;
  flex-shrink: 0;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: 138.5%; /* 27.7px */
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: -10px; // Pull the tab down to align with the bottom of the blue bar
}
.tab:hover{
  color: white;
}

.tab-active {
  background-color: white; // Background color for the active tab
  color: #0E487A; // Text color for the active tab
  border-radius: 5px 5px 0 0; // Rounded corners at the top only
  margin-bottom: -10px; // Pull the tab down to align with the bottom of the blue bar
}
.tab-active:hover{
  color: #0E487A;
}

.tab-h {
  color: white; // Text color for the tabs
  margin-right: 20px; // Spacing between tabs
  text-decoration: none;
  padding: 10px;
  height: 31.881px;
  flex-shrink: 0;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: 138.5%; /* 27.7px */
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: -10px; // Pull the tab down to align with the bottom of the blue bar
}
.tab-h:hover{
  color: white;
}

.tab-active-h {
  background-color: white; // Background color for the active tab
  color: #016E6E; // Text color for the active tab
  border-radius: 5px 5px 0 0; // Rounded corners at the top only
  margin-bottom: -10px; // Pull the tab down to align with the bottom of the blue bar
}
.tab-active-h:hover{
  color: #016E6E; // Text color for the active tab
}