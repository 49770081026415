.researchS1Title {
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

.researchS1Body {
  color: #000;
  font-size: 1.0rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  width: 800px;
  max-width: 90vw;
}

.researchS2Body {
  color: #000;
  font-size: 1.0rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.researchS2Ongoing {
  color: #000;
  font-size: 1.0rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0 0.5rem;
  padding: 0;
}

.researchMarquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
  //background-color: #F0F0F0;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.researchMarqueeItem {
  display: inline-block;
  padding: 0 10px;
}


.researchHighlightContainer{
  margin: 2.5rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center
}

.Research-AI-ArtContainer{
  display: flex;
  flex-direction: row;
  margin: 0 8.6vw
}

.Research-AI-Art{
  display: flex;
  width: 50%;
  align-items: center;
}

.research-artist-avatar{
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease
}

.research-artist-avatar:hover {
  transform: scale(1.05);
}

.Research-AI-ArtContainer-Text{
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 4rem;
}

.researchS2Title {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.researchS3 {
  width: 100%;
  padding: 6rem 7vw;
  background-color: #D9D9D9;
  display: flex;
  flex-direction: row;
}

.researchS3-Container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 2vw;
}

.researchS4-Container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 2vw;
}

.researchbutton {
  width: 20rem;
}

@media only screen and (max-width: 1000px) {
  .researchHighlightContainer{
    margin: 7rem 0 2rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center
  }
  .Research-AI-ArtContainer{
    display: block;
    flex-direction: row;
    margin: 0 3.6vw
  }
  .Research-AI-Art{
    display: block;
    width: 100%;
    flex-direction: row;
    margin: 0
  }
  .research-artist-avatar{
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
    object-fit: cover;
  }
  .Research-AI-ArtContainer-Text{
    display: block;
    flex-direction: row;
    width: 100%;
    margin-left: 0;
  }
  .researchS2Title {
    color: #000;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    padding-top: 1rem;
  }
  .researchS3 {
    width: 100%;
    padding: 3rem 3vw 0 1rem;
    background-color: #D9D9D9;
    display: block;
    flex-direction: row;
  }
  .researchS3-Container {
    display: block;
    flex-direction: row;
    width: 100%;
    padding: 0 2vw;
  }
  .researchS4-Container {
    display: block;
    flex-direction: row;
    width: 100%;
    padding: 5rem 2vw;
  }

  .researchbutton {
    width: 100%;
  }
}


