.home-pro-t-layout-card {
    width: 25%;
    padding: 0.5%;
    direction: ltr;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
}

.home-pro-t-layout-card-style2 {
    width: 33.3%;
    padding: 0.5%;
    direction: ltr;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
}

.home-pro-t-layout-card-control {
    display: flex;
    flex-direction: row;
    padding: 20px;
    visibility: hidden;
}


.home-pro-t-card-title {
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 5rem;
}

.home-pro-t-card-body {
    /*color: red;*/
    color: #000;
    font-family: Proxima Nova Regular, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 1033px) {
    .home-pro-t-layout-card {
        width: 33.3%;
    }

    .home-pro-t-layout-card-style2 {
        width: 50%;
    }

    .home-pro-t-layout-card-control {
        visibility: visible;
    }
}

@media only screen and (max-width: 720px) {
    .home-pro-t-layout-card {
        width: 50%;
    }

    .home-pro-t-layout-card-style2 {
        width: 50%;
    }

    .home-pro-t-layout-card-control {
        visibility: visible;
    }
}