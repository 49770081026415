.standard-special-header-row{

}
.standard-special-header-div{
  width: 40vw;
  height: 10vh;
  flex-shrink: 0;
  border-radius: 5px;
  background: #dfdcdc;
  margin: 6vh 0 0vh 7.6vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.standard-special-header-div-p1{
  width: 26vw;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  margin: auto;
}
.standard-special-header-div-p2{
  width: 26vw;
  opacity: 0.75;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  margin: auto;
}
.standard-special-header-div-2{
  //width: ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.standard-special-header-div-btn{
  width: 8vw;
  height: 5vh;
  border-radius: 2px;
  background: #0E487A;
  color: #FFF;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: auto;
}

.album-collection-title {
  width: 28vw;
  height: 49px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  //opacity: 0.75;
}

.ds-dropdown-al-hw {
  .ant-dropdown-menu {
    width: 228px;
    height: auto;
    flex-shrink: 0;
    border-radius: 3px;
    border: 1.5px solid #A4A4A4;
    background: #FFF;
  }

  //width: 188px;
  //height: 204px;
  //flex-shrink: 0;
  //border-radius: 8px;
  //border: 1.5px solid #A4A4A4;
  //background: #FFF;

  .ant-dropdown-menu-title-content {
    display: flex;
    //width: 177px;
    height: 48px;
    padding: 12px 16px;
    justify-content: start;
    text-align: left;
    align-items: flex-start;
    gap: 79px;
    flex-shrink: 0;
    border-radius: 8px;
    //background: #FFFEFE;
    //color: var(--Gray-5, #555);
    /* Table body */
    font-family: Proxima Nova, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    :hover {
      //background: #F5F5F5;
    }
  }
}

.ds-dropdown-al-hos {
  .ant-dropdown-menu {
    width: 188px;
    height: auto;
    flex-shrink: 0;
    border-radius: 3px;
    border: 1.5px solid #A4A4A4;
    background: #FFF;
  }

  width: 188px;
  height: 204px;
  flex-shrink: 0;
  border-radius: 8px;
  //border: 1.5px solid #A4A4A4;
  //background: #FFF;

  .ant-dropdown-menu-title-content {
    display: flex;
    width: 177px;
    height: 48px;
    padding: 12px 16px;
    justify-content: start;
    text-align: left;
    align-items: flex-start;
    gap: 79px;
    flex-shrink: 0;
    border-radius: 8px;
    //background: #FFFEFE;
    //color: var(--Gray-5, #555);
    /* Table body */
    font-family: Proxima Nova, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    :hover {
      //background: #F5F5F5;
    }
  }
}

.album-filter-btn {
  width: 137px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #D9D9D9;
  color: #000;
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 6vw;
}

.al-ma-tabs-container {
  display: flex;
  width: 560px;
  height: 77px;
  flex-shrink: 0;
  border-radius: 61px;
  background: #D9D9D9;
  margin-top: 40px;
  margin-left: 0;
  //align-items: center;
  justify-content: center;
  padding: 6px 5px;
  gap: 10px
}

.al-ma-active-tab {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2px;
  width: 280px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 48px;
  background: var(--Vieunite-Pro-Blue, #0E477A);
  color: var(--Yellow, #FFF);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 18px 20px 20px;

  &:focus {
    background: var(--Vieunite-Pro-Blue, #0E477A) !important;
    color: #FFF !important;
    border-color: var(--Vieunite-Pro-Blue, #0E477A) !important;
  }

  &:hover {
    background: var(--Vieunite-Pro-Blue, #0E477A) !important;
    color: #FFF !important;
    border-color: var(--Vieunite-Pro-Blue, #0E477A) !important;
  }
}

.al-ma-inactive-tab {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2px;
  width: 280px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 48px;
  background: transparent; /* 使非活跃按钮透明，以显示容器的灰色背景 */
  color: var(--Vieunite-Pro-Blue, #0E477A);
  text-align: center;
  font-family: Proxima Nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 18px 20px 20px;

  &:focus {
    background: var(--Vieunite-Pro-Blue, #0E477A) !important;
    color: #FFF !important;
    border-color: var(--Vieunite-Pro-Blue, #0E477A) !important;
  }

  &:hover {
    background: var(--Vieunite-Pro-Blue, #0E477A) !important;
    color: #FFF !important;
    border-color: var(--Vieunite-Pro-Blue, #0E477A) !important;
  }
}

.al-ma-tab.active {
  border-bottom: none;
}

.album-ct-btn {
  width: auto;
  height: 42px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--Yellow, #FFF);
  color: #0E487A;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}
.album-ct-btn-dropdown{
  width: auto;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.al-text-single {
  width: 150px;
  height: 41px;
  flex-shrink: 0;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: auto;
  text-align: center;
  margin-top: -15px;
}

.al-discovery-card-title {
  color: #000;
  text-align: center;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.al-card-main {
  border-radius: 14px;
  width: 240px;
  height: 335px;
  margin: 10px;
}

.al-card-single {
  background: #0E487A;
  color: white;
}

.album-color-picker-div {
  width: 543px;
  height: 165px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1.5px solid #A4A4A4;
  background: var(--Yellow, #FFF);
}

.custom-color-picker {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto;
  justify-items: center;
  width: 24vw;
  height: 165px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1.5px solid #A4A4A4;
  background: var(--Yellow, #FFF);
}

.color-swatch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.color-swatch {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 2px;
  border: 1px solid #DEDEDE;
}

.color-label {
  text-align: center;
  color: #000;
  font-family: Proxima Nova Regular, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 45px;
  height: 14px;
  flex-shrink: 0;
}

.second-row {
  grid-row-start: 2;
}
